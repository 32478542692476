<template>
  <div class="settings-investor-tip content-block">
    <p class="settings-investor-tip-title potok-text-body-1-bold">
      вы можете инвестировать, как физ.лицо или добавить свое ИП или компании,
      в которых вы являетесь директором
    </p>
  </div>
</template>
<script setup>
</script>
<style lang="scss" scoped>
.settings-investor-tip.content-block {
  width: 100%;
  padding: 20px;
  background: $dividers;
  @media (max-width: $size_767) {
    margin-left: 0;
    margin-right: 0;
  }
}
.settings-investor-tip-title {
  text-transform: initial;
  margin: 0;
}
</style>