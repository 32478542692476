<template>
  <div class="qualification-document">
    <div class="qualification-document-icon-wrapper">
      <div class="qualification-document-icon"></div>
    </div>
    <div class="qualification-document-filename-wrapper">
      <div class="qualification-document-filename">
        {{ props.item.file.name }}
      </div>
      <div class="qualification-document-ext">
        {{ getExt(props.item.file.name) }}
      </div>
    </div>
    <div
      class="qualification-file-remove"
      @click="removeDocument(props.item.file)"
    >
      <div class="qualification-files-remove-icon"></div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps(['item', 'indexItem']);
const emits = defineEmits(['removeDocument']);

const removeDocument = () => {
  emits('removeDocument', props.indexItem);
};
const getExt = (filename) => {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
};
</script>
<style lang="scss" scoped>
.qualification-document {
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  min-width: 220px;
  position: relative;
  margin: 0 20px 20px 0;
  cursor: pointer;
  &:hover {
    .qualification-files-remove-icon {
      display: block;
    }
  }
  @media (max-width: $size_767) {
    width: 100%;
    margin: 0 0 20px 0;
  }
}
.qualification-document-icon-wrapper {
  background: #f2f4f8;
  border-radius: 8px;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}
.qualification-document-icon {
  background-image: url('@img/icons/qualification/qualification-document-icon.svg');
  background-repeat: no-repeat;
  width: 13px;
  height: 19px;
  background-size: cover;
}
.qualification-document-filename-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px;
}
.qualification-document-filename {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #173b57;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.qualification-document-ext {
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #818e99;
}

.qualification-files-remove-icon {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  background: url('@img/icons/qualification/qualification-files-remove.svg')
    center center no-repeat;
  cursor: pointer;
  display: none;
  @media (max-width: $size_767) {
    display: block;
  }
}
</style>
