<template>
  <div class="">
    <swiper :slides-per-view="'auto'" style="margin-right: -15px">
      <swiper-slide>
        <div class="block_content">
          <InvestmentsBalanceInvested
            :stat-amount="props.statAmount"
            :stat-count="props.statCount"
            :loading-company-stat="props.loadingCompanyStat"
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="block_content">
          <InvestmentsBalanceIncome
            :stat-income="props.statIncome"
            :promo-income="props.promoIncome"
            :loading-company-promo-income="props.loadingCompanyPromoIncome"
          />
        </div>
      </swiper-slide>
      <swiper-slide v-if="!store.isPotokFinanceBorrower">
        <div class="block_content">
          <InvestmentsBalanceExpectedIncome />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="block_content">
          <InvestmentsBalanceDefault />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup>
import { Swiper } from '@/libs/swiper/swiper.js';
import { SwiperSlide } from '@/libs/swiper/swiper-slide.js';
import '@/libs/swiper/swiper.min.css';

import InvestmentsBalanceInvested from '../InvestmentsBalanceInvested.vue';
import InvestmentsBalanceIncome from '../InvestmentsBalanceIncome.vue';
import InvestmentsBalanceExpectedIncome from '../InvestmentsBalanceExpectedIncome.vue';
import InvestmentsBalanceDefault from '../InvestmentsBalanceDefault.vue';
import { useStateStore } from "@/store/stateStore";

const props = defineProps([
  'statAmount',
  'statCount',
  'statIncome',
  'promoIncome',
  'loadingCompanyStat',
  'loadingCompanyPromoIncome',
]);

const store = useStateStore()
</script>
<style lang="scss" scoped>
.block_content {
  @media (max-width: $size_767) {
    height: 132px;
    margin-right: 16px;
    padding: 21px 18px;
  }
}
</style>
