<template>
  <section style="margin-top: 30px">
    <AnalyticsHistoryTotalDesktop
      v-if="!isMobile"
      :loading="loadingCompaniesAccountStatementTotal"
      :loading-email="loadingCompaniesAccountStatementExcel"
      :total="total"
      @send-email="sendEmail"
    />
    <AnalyticsHistoryTotalMobile
      v-if="isMobile"
      :loading="loadingCompaniesAccountStatementTotal"
      :loading-email="loadingCompaniesAccountStatementExcel"
      :total="total"
      @send-email="sendEmail"
    />
  </section>
</template>
<script setup>
import { ref, watch } from 'vue';

import tracker from '@/tracker';
import { useStateStore } from '@/store/stateStore';
import server from '@/server';
import AnalyticsHistoryTotalDesktop from './AnalyticsHistoryOperationTotal/AnalyticsHistoryOperationTotalContainers/AnalyticsHistoryOperationTotalDesktop.vue';
import AnalyticsHistoryTotalMobile from './AnalyticsHistoryOperationTotal/AnalyticsHistoryOperationTotalContainers/AnalyticsHistoryOperationTotalMobile.vue';
import detectDevice from '@/composables/detectDeviceComposable';
import { getDateSince, getDateTo } from '../../../utils/date/getDateToSince.js';

const { isMobile } = detectDevice();
const store = useStateStore();

const props = defineProps({
  dateSince: Object,
  dateTo: Object,
  type: String,
  investmentType: String,
  companyId: String,
  fetchData: Boolean,
});

const total = ref({
  income: 0,
  outcome: 0,
});

const loadingCompaniesAccountStatementTotal = ref(false);
const loadingCompaniesAccountStatementExcel = ref(false);

const getCompaniesAccountStatementTotalAsync = () => {
  const query = {
    dateSince: getDateSince(props.dateSince),
    dateTo: getDateTo(props.dateTo),
    companyId: props.companyId,
    accountType: 'investor',
    isVirtual: false,
    type: props.type,
    investmentType: props.investmentType,
  };

  loadingCompaniesAccountStatementTotal.value = true;
  return server.getCompaniesAccountStatementTotal
    .send(query)
    .pipe(onGetCompaniesAccountStatementTotalAsyncSuccess.bind(this))
    .exec();
};
const onGetCompaniesAccountStatementTotalAsyncSuccess = ({ data: _data }) => {
  loadingCompaniesAccountStatementTotal.value = false;
  total.value = _data;
};

const sendEmail = () => {
  tracker.queue(
    tracker.commands.SEND,
    'Investor_LK_Operations_History_Send_To_Email',
  );
  const query = {
    dateSince: getDateSince(props.dateSince),
    dateTo: getDateTo(props.dateTo),
    companyId: props.companyId,
    accountType: 'investor',
    type: props.type,
    investmentType: props.investmentType,
  };
  loadingCompaniesAccountStatementExcel.value = true;
  return server.getCompaniesAccountStatementExcel
    .send(query)
    .pipe(onGetCompaniesAccountStatementExcelAsyncSuccess.bind(this))
    .exec();
};
const onGetCompaniesAccountStatementExcelAsyncSuccess = () => {
  loadingCompaniesAccountStatementExcel.value = false;
  store.pushAlert({
    message: 'отчет был отправлен на ваш email',
  });
};

watch(
  () => {
    return props.fetchData;
  },
  (value) => {
    if (!value) {
      return;
    }
    getCompaniesAccountStatementTotalAsync();
  },
);
</script>
<style lang="scss" scoped>
:deep(.content_title) {
  margin-top: 20px;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $grey;
}

:deep(.content_value) {
  margin-top: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.01em;
}

:deep(.total_wrapper) {
  min-height: 126px;
  background: #ffffff;
  border-radius: 14px;
  padding: 20px;
}
</style>
