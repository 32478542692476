<template>
  <div v-if="isBannerEnabled" style="margin-top: 20px">
    <div class="row banner_newuser">
      <button type="button" class="modal-close" @click="close">
        <span>&times;</span>
      </button>
      <div class="block_left">
        <p class="text_subtitle" style="text-transform: none">
          Если вы хотите инвестировать в Потоке как ИП или юридическое лицо –
          пройдите процедуру регистрации физ.лица и внесите любую сумму –
          например 1 рубль на свой лицевой счет, чтобы мы могли идентифицировать
          вас и после этого воспользуйтесь функцией «Добавить инвестора»
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();
const isBannerEnabled = computed(() => {
  const showed = store.state.preferences?.banners?.newuser;
  if (showed) {
    return false;
  }
  return true;
});
const close = () => {
  store.merge({
    preferences: {
      banners: {
        newuser: true,
      },
    },
  });
};
</script>
<style lang="scss" scoped>
.banner_newuser {
  text-transform: none;
  background: rgba(23, 59, 87, 0.06);
  border-left: 3px solid #173b57;
  border-radius: 8px;
  position: relative;
  margin: 0 0 30px 0;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}
.block_left {
  flex: 2;
  display: inline-block;
  padding: 30px 20px;
  position: relative;

  @media (max-width: $size_767) {
    padding: 20px 12px;
  }
  .text_subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $deep_dive;
    @media (max-width: $size_767) {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
</style>
