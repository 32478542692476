<template>
  <swiper :slides-per-view="'auto'" style="margin-right: -15px">
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <div class="content_title">
            <router-link
              v-if="props.stats.funded.count > 0"
              :to="{
                name: 'investments',
                query: { status: 'funded' },
              }"
            >
              текущие
            </router-link>
            <span v-if="props.stats.funded.count === 0">текущие</span>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">число инвестиций</div>
            <div class="item_value">
              {{ formatCurrency(props.stats.funded.count, 0) }}
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">сумма инвестиций</div>
            <div class="item_value">
              <span> {{ formatCurrency(props.stats.funded.amount, 0) }} </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">остаток осн. долга</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.funded.balanceOfMainDebt, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">процентный доход</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.funded.income, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <div class="content_title">
            <router-link
              v-if="props.stats.restructured.count > 0"
              :to="{
                name: 'investments',
                query: { status: 'restructured' },
              }"
            >
              реструктурированные
            </router-link>
            <span v-if="props.stats.restructured.count === 0"
              >реструктурированные</span
            >
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">число инвестиций</div>
            <div class="item_value">
              {{ formatCurrency(props.stats.restructured.count, 0) }}
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">сумма инвестиций</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.restructured.amount, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">остаток осн. долга</div>
            <div class="item_value">
              <span>
                {{
                  formatCurrency(props.stats.restructured.balanceOfMainDebt, 0)
                }}
              </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">процентный доход</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.restructured.income, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <div class="content_title">
            <router-link
              v-if="props.stats.refunded.count > 0"
              :to="{
                name: 'investments',
                query: { status: 'refunded' },
              }"
            >
              возвращенные
            </router-link>
            <span v-if="props.stats.refunded.count === 0">возвращенные</span>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">число инвестиций</div>
            <div class="item_value">
              {{ formatCurrency(props.stats.refunded.count, 0) }}
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">сумма инвестиций</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.refunded.amount, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">остаток осн. долга</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.refunded.balanceOfMainDebt, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">процентный доход</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.refunded.income, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <div class="content_title">
            <router-link
              v-if="props.stats.softOverdue.count > 0"
              :to="{
                name: 'investments',
                query: { status: 'softOverdue' },
              }"
            >
              в просрочке
            </router-link>
            <span v-if="props.stats.softOverdue.count === 0">в просрочке</span>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">число инвестиций</div>
            <div class="item_value">
              {{ formatCurrency(props.stats.softOverdue.count, 0) }}
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">сумма инвестиций</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.softOverdue.amount, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">остаток осн. долга</div>
            <div class="item_value">
              <span>
                {{
                  formatCurrency(props.stats.softOverdue.balanceOfMainDebt, 0)
                }}
              </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">процентный доход</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.softOverdue.income, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <div class="content_title">
            <router-link
              v-if="props.stats.default.count > 0"
              :to="{
                name: 'investments',
                query: { status: 'default' },
              }"
            >
              в дефолте
            </router-link>
            <span v-if="props.stats.default.count === 0">в дефолте</span>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">число инвестиций</div>
            <div class="item_value">
              {{ formatCurrency(props.stats.default.count, 0) }}
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">сумма инвестиций</div>
            <div class="item_value">
              <span> {{ formatCurrency(props.stats.default.amount, 0) }} </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">остаток осн. долга</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.default.balanceOfMainDebt, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">процентный доход</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.default.income, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <div class="content_title">
            <router-link
              v-if="props.stats.sold.count > 0"
              :to="{
                name: 'investments',
                query: { status: 'default' },
              }"
            >
              выкупленные
            </router-link>
            <span v-if="props.stats.sold.count === 0">выкупленные</span>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">число инвестиций</div>
            <div class="item_value">
              {{ formatCurrency(props.stats.sold.count, 0) }}
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">сумма инвестиций</div>
            <div class="item_value">
              <span> {{ formatCurrency(props.stats.sold.amount, 0) }} </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">остаток осн. долга</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.sold.balanceOfMainDebt, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">процентный доход</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.sold.income, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <div class="content_title">
            <span>Итого</span>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">число инвестиций</div>
            <div class="item_value">
              {{ formatCurrency(props.stats.total.count, 0) }}
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical">
            <div class="item_title">сумма инвестиций</div>
            <div class="item_value">
              <span> {{ formatCurrency(props.stats.total.amount, 0) }} </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">остаток осн. долга</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.total.balanceOfMainDebt, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
          <div class="content_devider"></div>
          <div class="item_row_vertical" style="padding-top: 0px">
            <div class="item_title">процентный доход</div>
            <div class="item_value">
              <span>
                {{ formatCurrency(props.stats.total.income, 0) }}
              </span>
              &nbsp;₽
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script setup>
import { formatCurrency } from '@/utils/commonUtils/utils';
import { Swiper } from '@/libs/swiper/swiper';
import { SwiperSlide } from '@/libs/swiper/swiper-slide';
import '@/libs/swiper/swiper.min.css';

const props = defineProps(['stats']);
</script>
<style lang="scss" scoped>
.dashboard_block_content {
  .content_title {
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: $grey !important;
    padding-bottom: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    a {
      color: $grey !important;
    }
  }

  .item_row_vertical {
    margin: 10px 0 !important;
  }
}
.item_title {
  color: $grey !important;
}
.item_value {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: $deep_dive !important;
}
</style>
