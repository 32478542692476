<template>
  <div class="block-menu-desktop">
    <div class="block-menu">
      <ul class="block-tabs">
        <li v-if="isAdmin || store.isNewAnalyticsEnabled">
          <router-link
            :to="{
              name: 'analyticsAssets',
            }"
            active-class="tab-active"
          >
            мои активы
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'analyticsMyPortfolio',
            }"
            :class="{
              'tab-active': $route.name === 'analyticsMyPortfolio',
            }"
          >
            мой портфель
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'analyticsHistoryOperation',
            }"
            active-class="tab-active"
          >
            история операций
          </router-link>
        </li>
        <!-- <li>
          <router-link
            :to="{
              name: 'analyticsStatistic'
            }"
            active-class="tab-active"
          >
            статистика Потока
          </router-link>
        </li> -->
        <li>
          <router-link
            :to="{
              name: 'analyticsHistoryEvent',
            }"
            active-class="tab-active"
          >
            история событий
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();

const isAdmin = computed(() => store.isAdmin);
</script>
<style lang="scss" scoped>
.block-menu {
  width: 100%;
}
.block-tabs {
  width: 100%;
  margin-right: 0 !important;
  text-transform: none;
}
.block-menu-desktop {
  .block-menu {
    position: relative;
    margin-bottom: 0;
    overflow-x: hidden;

    @media (max-width: 767px) {
      display: none;
    }
    @media (max-width: 991px) {
      right: 0;
    }
    .block-tabs {
      li {
        display: flex;
        align-items: center;
        margin-right: 40px;
        margin-top: 2px;
        .v-popover {
          margin-top: 4px;
          margin-left: 4px;
        }
        &:last-child {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
