import { formatCurrency } from '@/utils/commonUtils/libUtils';

const horizontalDottedLine = (stacked = false) => {
  const drawLine = (chart) => {
    if (chart.legend.legendItems.find((el) => el.hidden)) return;

    const generalData = [];

    chart.config.data.datasets.forEach((datasetElement) => {
      const dataArray = datasetElement.data.map((dataElement, i) => {
        if (typeof dataElement === 'object' && !Array.isArray(dataElement)) {
          return {
            ...dataElement,
            xItemIndex: i,
            value: dataElement.indexValue,
          };
        }

        return {
          xItemIndex: i,
          value: dataElement,
        };
      });

      dataArray.forEach((el) => {
        generalData.push(el);
      });
    });

    let maxValueObject = {
      value: 0,
      xItemIndex: 0,
    };

    if (!stacked) {
      maxValueObject = {
        value: generalData[0].value,
        xItemIndex: generalData[0].xItemIndex,
      };

      generalData.forEach((el) => {
        if (el.value > maxValueObject.value) {
          maxValueObject.value = el.value;
          maxValueObject.xItemIndex = el.xItemIndex;
        }
      });
    }

    if (stacked) {
      const stackedGeneralData = [];
      for (let i = 0; i < generalData.length; i++) {
        const elIndex = generalData[i].xItemIndex;
        let totalValue = 0;

        generalData.forEach((el) => {
          if (el.xItemIndex === elIndex) {
            totalValue += el.value;
          }
        });

        if (!stackedGeneralData.find((el) => el.xItemIndex === elIndex)) {
          const stackedItem = {
            xItemIndex: elIndex,
            totalValue,
          };
          stackedGeneralData.push(stackedItem);
        }
      }

      maxValueObject = {
        value: stackedGeneralData[0].totalValue,
        xItemIndex: stackedGeneralData[0].xItemIndex,
      };

      stackedGeneralData.forEach((el) => {
        if (el.totalValue > maxValueObject.value) {
          maxValueObject.value = el.totalValue;
          maxValueObject.xItemIndex = el.xItemIndex;
        }
      });
    }

    const {
      ctx,
      chartArea: { left, width },
      scales: { y, x },
    } = chart;
    ctx.save();
    ctx.strokeStyle = 'rgba(4, 204, 217, 1)';
    ctx.setLineDash([2, 4]);

    ctx.strokeRect(left, y.getPixelForValue(maxValueObject.value), width, 0);
    ctx.restore();

    // рисуем текст к этой линии
    ctx.font = '8px SuisseIntl';
    ctx.fillStyle = 'rgb(4, 204, 217)';
    // Если колонка последняя, то количество пикселей отступа изменится, что бы текст влез
    let additionalPixelsForX =
      maxValueObject.xItemIndex === chart.config.data.datasets[0].length - 1
        ? 20
        : 30;
    // Если колонка почти достигла максимума по высоте графика, то отображаем текст не над пунктирной линией, а под ней, что бы текст не пересекался с лейблами графика
    const additionalPixelsForY =
      y.getPixelForValue(maxValueObject.value) < 30 ? -8 : 8;
    if (additionalPixelsForY === -8) {
      additionalPixelsForX += 15;
      // Если колонка первая, то текст будет сдвинут вправо, что бы он не пропал за экраном/блоком
      if (maxValueObject.xItemIndex === 0) {
        additionalPixelsForX -= 30;
      }
    }

    const text = formatCurrency(maxValueObject.value, 0);
    ctx.fillText(
      `${text} ₽`,
      x.getPixelForValue(maxValueObject.xItemIndex) - additionalPixelsForX,
      y.getPixelForValue(maxValueObject.value) - additionalPixelsForY,
    );
  };

  return {
    id: 'horizontalDottedLine',
    afterDatasetsDraw(chart) {
      drawLine(chart);
    },
  };
};

export default horizontalDottedLine;
