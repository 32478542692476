<template>
  <div
    ref="box"
    class="img-inputer"
    :class="[
      themeClass,
      sizeClass,
      nhe || noHoverEffect ? 'nhe' : '',
      { 'img-inputer--loading': uploading },
    ]"
  >
    <i v-if="ICON && !customIconClass" class="img-inputer__icon icon_upload" />
    <i
      v-if="customIconClass"
      class="img-inputer__icon"
      :class="customIconClass"
    />
    <slot v-if="!icon && !customIconClass" name="icon" />
    <p class="img-inputer__placeholder" v-html="placeholder"></p>

    <div v-if="imgSelected" class="img-inputer__preview-box">
      <img :src="dataUrl" class="img-inputer__preview-img" />
    </div>
    <div
      v-if="isFileNamePdfExtension"
      class="img-inputer__preview-box"
      style="background: #ffffff; padding: 10px 15px 15px 20px"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi bi-file-earmark-pdf"
        viewBox="0 0 16 16"
      >
        <path
          d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
        />
        <path
          d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"
        />
      </svg>
    </div>
    <label :for="readonly ? '' : inputId" class="img-inputer__label" />

    <transition name="vip-fade">
      <div v-if="uploading" class="img-inputer__loading">
        <div class="img-inputer__loading-indicator" />
        <div class="img-inputer__loading-process" :style="processStyle" />
      </div>
    </transition>

    <transition name="vip-zoom-in">
      <div v-if="autoUpload && uploaded" class="img-inputer__state success" />
    </transition>

    <transition name="vip-zoom-in">
      <div v-if="autoUpload && uploadFailed" class="img-inputer__state fail" />
    </transition>

    <!-- Mask -->
    <div v-if="imgSelected && !noMask" class="img-inputer__mask">
      <p class="img-inputer__file-name">{{ fileName }}</p>
      <p v-if="readonly" class="img-inputer__change">{{ readonlyTipText }}</p>
      <p v-else class="img-inputer__change">{{ bottomText }}</p>
    </div>

    <input
      v-if="capture"
      v-bind="$attrs"
      :id="inputId"
      ref="inputer"
      type="file"
      :name="name"
      :accept="accept"
      capture="video"
      class="img-inputer__inputer"
      @change="handleFileChange"
    />

    <!-- Alternative for no capture-->
    <input
      v-else
      v-bind="$attrs"
      :id="inputId"
      ref="inputer"
      type="file"
      :name="name"
      :accept="accept"
      class="img-inputer__inputer"
      @change="handleFileChange"
    />
    <transition name="vip-move-in">
      <div v-if="errText.length" class="img-inputer__err">{{ errText }}</div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable */
import ajax from './ajax';
const isZhCN = (_) =>
  (navigator.language || navigator.browserLanguage).includes('zh');
const lang = isZhCN ? 'zh' : 'en';
const TRANSLATIONS = {
  readonlyTipText: { zh: '不可更改', en: 'Readonly' },
  bottomText: {
    zh: '点击或拖拽图片以修改',
    en: 'Drop file here or click to change',
  },
  hoverText: {
    zh: '点击或拖拽图片以修改',
    en: 'Drop file here or click to change',
  },
  placeholder: {
    zh: '点击或拖拽选择图片',
    en: 'Drop file here or click',
  },
  noMultipleFileMsg: { zh: '不支持多文件', en: 'Not support multiple files' },
  exceedSizeMsg: {
    zh: '文件大小不能超过',
    en: 'The size of file should less than: ',
  },
  noActionUrlMsg: {
    zh: '上传地址未配置',
    en: "Action hasn't set up yet",
  },
};
export default {
  name: 'VueImgInputer',
  props: {
    type: {
      default: 'img',
      type: String,
    },
    accept: {
      default: 'image/*,video/*',
      type: String,
    },
    capture: {
      default: false,
      type: Boolean,
    },
    id: {
      default: '',
      type: String,
    },
    onChange: {
      default: null,
      type: Function,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    noMultipleText: {
      default: TRANSLATIONS.noMultipleFileMsg[lang],
      type: String,
    },
    exceedSizeText: {
      default: TRANSLATIONS.exceedSizeMsg[lang],
      type: String,
    },
    noActionText: {
      default: TRANSLATIONS.noActionUrlMsg[lang],
      type: String,
    },
    readonlyTipText: {
      default: TRANSLATIONS.readonlyTipText[lang],
      type: String,
    },
    bottomText: {
      default: TRANSLATIONS.bottomText[lang],
      type: String,
    },
    hoverText: {
      default: TRANSLATIONS.hoverText[lang],
      type: String,
    },
    placeholder: {
      default: TRANSLATIONS.placeholder[lang],
      type: String,
    },
    modelValue: {
      default: undefined,
    },
    icon: {
      default: '',
      type: String,
    },
    aliIcon: {
      default: '',
      type: String,
    },
    customIconClass: {
      default: '',
      type: String,
    },
    maxSize: {
      default: 5120,
      type: Number,
    },
    size: {
      default: '',
      type: String,
    },
    imgSrc: {
      default: '',
      type: String,
    },
    fileType: {
      default: '',
      type: String,
    },
    // ! Deprecated, use noHoverEffect instead
    nhe: {
      type: Boolean,
      default: false,
    },
    noHoverEffect: {
      type: Boolean,
      default: false,
    },
    noMask: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'file',
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: undefined,
    },
    uploadKey: {
      type: String,
      default: 'file',
    },
    extraData: {
      type: Object,
      default: () => {},
    },
    headers: {
      type: Object,
      default: () => {},
    },
    withCookie: {
      type: Boolean,
      default: false,
    },
    onStart: {
      default: () => {},
      type: Function,
    },
    onProgress: {
      default: () => {},
      type: Function,
    },
    onSuccess: {
      default: () => {},
      type: Function,
    },
    onError: {
      default: () => {},
      type: Function,
    },
  },
  emits: ['onExceed', 'onChange', 'update:modelValue'],
  // !------------------------ D a t a --------------------------------------------------------
  data() {
    return {
      inputId: '',
      file: null,
      dataUrl: '',
      fileName: '',
      errText: '',
      uploading: false,
      uploadPercent: 0,
      uploaded: false,
      uploadFailed: false,
    };
  },
  computed: {
    imgSelected() {
      if (this.dataUrl && this.fileName && !this.isFileNamePdfExtension) {
        return true;
      }
      if (
        this.dataUrl &&
        !this.fileName &&
        this.fileType &&
        this.fileType === 'image' &&
        !this.isFileNamePdfExtension
      ) {
        return true;
      }
      return false;
    },
    isFileNamePdfExtension() {
      if (this.fileName && this.fileName.endsWith('.pdf')) {
        return true;
      }
      if (this.dataUrl && this.dataUrl.startsWith('data:application/pdf')) {
        return true;
      }
      if (!this.fileName && this.fileType && this.fileType === 'pdf') {
        return true;
      }
      return false;
    },
    isImage() {
      if (!!this.dataUrl) {
        return true;
      }
      return false;
    },
    sizeHumanRead() {
      let rst = 0;
      if (this.maxSize < 1024) {
        rst = this.maxSize + 'K';
      } else {
        rst =
          (this.maxSize / 1024).toFixed(this.maxSize % 1024 > 0 ? 2 : 0) + 'M';
      }
      return rst;
    },
    sizeClass() {
      if (this.size) {
        return `img-inputer--${this.size}`;
      }
    },
    themeClass() {
      return `img-inputer--${this.theme}`;
    },
    ICON() {
      let rst = '';
      const { theme, icon, autoUpload } = this;
      if (icon) {
        rst = icon;
      } else {
        rst = theme === 'light' ? 'img-light' : autoUpload ? 'upload' : 'clip';
      }
      return rst;
    },
    processStyle() {
      const { uploadPercent } = this;
      return {
        transform: `translate3d(${uploadPercent - 100}%, 0, 0)`,
      };
    },
  },
  mounted() {
    this.inputId = this.id || this.gengerateID();
    if (this.imgSrc) {
      this.dataUrl = this.imgSrc;
    }
    ['dragleave', 'drop', 'dragenter', 'dragover'].forEach((e) => {
      this.preventDefaultEvent(e);
    });
    this.addDropSupport();
  },
  methods: {
    preventDefaultEvent(eventName) {
      document.addEventListener(
        eventName,
        function (e) {
          e.preventDefault();
        },
        false,
      );
    },
    addDropSupport() {
      let BOX = this.$refs.box;
      BOX.addEventListener('drop', (e) => {
        e.preventDefault();
        if (this.readonly) return false;
        this.errText = '';
        let fileList = e.dataTransfer.files;
        if (fileList.length === 0) {
          return false;
        }
        if (fileList.length > 1) {
          this.errText = this.noMultipleText;
          return false;
        }
        this.handleFileChange(fileList);
      });
    },
    gengerateID() {
      let nonstr = Math.random().toString(36).substring(3, 8);
      if (!document.getElementById(nonstr)) {
        return nonstr;
      } else {
        return this.gengerateID();
      }
    },
    handleFileChange(e) {
      if (typeof e.target === 'undefined') this.file = e[0];
      else this.file = e.target.files[0];
      this.errText = '';
      let size = Math.floor(this.file.size / 1024);
      if (size > this.maxSize) {
        this.errText = `${this.exceedSizeText}${this.sizeHumanRead}`;
        this.$emit('onExceed', this.file);
        return false;
      }

      this.$emit('update:modelValue', this.file);
      if (this.autoUpload) this.uploadFile();
      this.onChange && this.onChange(this.file, this.file.name);
      this.$emit('onChange', this.file, this.file.name);
      this.imgPreview(this.file);
      this.fileName = this.file.name;
      this.resetInput();
    },
    imgPreview(file) {
      let self = this;
      if (!file || !window.FileReader) return;
      if (/^image|pdf/.test(file.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          self.dataUrl = this.result;
        };
      }
    },
    resetInput() {
      let input = document.getElementById(this.inputId);
      let form = document.createElement('form');
      document.body.appendChild(form);
      let parentNode = input.parentNode;

      let isLastNode = parentNode.lastChild === input;
      let nextSibling;
      if (!isLastNode) {
        nextSibling = input.nextSibling;
      }
      form.appendChild(input);
      form.reset();
      isLastNode
        ? parentNode.appendChild(input)
        : parentNode.insertBefore(input, nextSibling);
      document.body.removeChild(form);
    },
    uploadFile() {
      const { onStart, file } = this;
      if (!this.action) {
        this.errText = this.noActionText;
        return;
      }
      onStart && onStart(file);
      this.post(file);
    },
    post(file) {
      const { headers, withCookie, extraData, uploadKey, action } = this;
      this.uploading = true;
      const options = {
        headers: headers,
        withCredentials: withCookie,
        file,
        data: extraData,
        filename: uploadKey,
        action,
        onProgress: (e) => {
          this.uploadPercent = ~~e.percent;
          this.onProgress(e, file);
        },
        onSuccess: (res) => {
          this.uploadPercent = 0;
          this.uploading = false;
          this.uploaded = true;
          this.onSuccess(res, file);
        },
        onError: (err) => {
          this.uploadPercent = 0;
          this.uploading = false;
          this.uploadFailed = true;
          this.onError(err, file);
        },
      };
      ajax(options);
    },
    reset() {
      this.file = null;
      this.dataUrl = '';
      this.errText = '';
      this.fileName = '';
      this.uploadPercent = 0;
      this.uploading = false;
      this.uploaded = false;
      this.uploadFailed = false;
    },
  },
  // !------------------------ W a t c h --------------------------------------------------------
  watch: {
    imgSrc(newval) {
      this.dataUrl = newval;
      if (!newval) {
        this.errText = '';
      }
    },
    modelValue(newval, oldval) {
      // reset
      if (oldval && !newval) {
        this.reset();
      }
    },
  },
};
</script>
