<template>
  <div>
    <div
      v-if="!isTeleportOpen"
      class="teleport-icon"
      @click="isTeleportOpen = true"
    ></div>
    <div v-if="isTeleportOpen" class="teleport-content">
      <i class="icon_close" @click="isTeleportOpen = false"> </i>
      <Switcher
        v-model="selectedMenu"
        :items="[
          {
            title: 'Текущие статусы сервера',
            value: 'currentStatuses',
          },
          {
            title: 'Статусы сервера',
            value: 'status',
          },
          {
            title: 'Список страниц',
            value: 'pages',
          },
        ]"
      />
      <div class="selected-content">
        <div v-if="selectedMenu === 'currentStatuses'">
          <div class="status-panel mt-6">
            <div v-for="(value, key, index) in currentStatuses" :key="index">
              {{ key }}: {{ currentStatuses[key] }}
            </div>
          </div>
        </div>
        <div v-if="selectedMenu === 'status'">
          <div class="status-wrapper">
            <div class="apply-panel">
              <PotokButton
                class="mt-3"
                :text="'Применить'"
                @click="onClickApply"
              ></PotokButton>
            </div>
          </div>
          <ul class="main-list">
            <li v-for="(cat, index) in statuses" :key="index" class="item-list">
              <div @click.stop="cat.isShow = !cat.isShow">{{ cat.title }}</div>
              <ul v-if="cat.isShow" class="sub-list">
                <li
                  v-for="(page, indexPage) in cat.statuses"
                  :key="indexPage"
                  class="subitem-list"
                >
                  <template v-if="page.description">
                    <div
                      class="text-desc"
                      href=""
                      @click.stop="onClickDescrpition(page.description)"
                    >
                      Описание задачи: {{ page.taskTitle }}
                    </div>
                  </template>
                  <label class="text-label">
                    <input v-model="page.selected" type="checkbox" />
                    <div v-html="page.title"></div>
                  </label>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-if="selectedMenu === 'pages'">
          <ul class="main-list">
            <li v-for="(page, index) in pages" :key="index" class="item-list">
              <a :href="page.url"><span v-html="page.title"></span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import server from '@/server/index';
import Switcher from '@/components/_generic/cselect/Switcher.vue';
import { PotokButton } from 'potok-uikit';

const isTeleportOpen = ref(false);
const loading = ref(false);
const statuses = ref([]);
const pages = ref([]);
const currentStatuses = ref([]);
const selectedMenu = ref('currentStatuses');

const getListPages = () => {
  loading.value = true;
  return server.getPages
    .send()
    .pipe(onGetPages, () => {
      loading.value = false;
    })
    .exec();
};

const onGetPages = ({ data }) => {
  loading.value = false;
  pages.value = data;
};

const getServerStatuses = () => {
  loading.value = true;
  return server.getServerStatuses
    .send()
    .pipe(onGetServerStatuses, () => {
      loading.value = false;
    })
    .exec();
};

const onGetServerStatuses = ({ data }) => {
  loading.value = false;
  statuses.value = data.statuses;
  currentStatuses.value = data.currentStatuses;
  statuses.value.forEach((status) => {
    status.isShow = false;
  });
};

const onClickDescrpition = (content) => {
  const tab = window.open('about:blank', '_blank');
  tab.document.write(content);
};

const onClickApply = () => {
  const orderStatuses = [];
  const companiesStatuses = [];
  const noticesStatuses = [];
  const featuresStatuses = [];
  const userStatuses = [];
  statuses.value.forEach((cat) => {
    cat.statuses.forEach((item) => {
      if (item.selected) {
        orderStatuses.push(...item.statuses.filter((i) => i.type === 'order'));
        companiesStatuses.push(
          ...item.statuses.filter((i) => i.type === 'companies'),
        );
        noticesStatuses.push(
          ...item.statuses.filter((i) => i.type === 'notices'),
        );
        featuresStatuses.push(
          ...item.statuses.filter((i) => i.type === 'features'),
        );
        userStatuses.push(...item.statuses.filter((i) => i.type === 'user'));
      }
    });
  });
  const query = {
    statuses: {
      order: orderStatuses,
      companies: companiesStatuses,
      notices: noticesStatuses,
      features: featuresStatuses,
      user: userStatuses,
    },
  };
  server.postSelectStatus
    .send(query)
    .pipe(() => {
      isTeleportOpen.value = false;
      window.location.reload();
    })
    .exec();
};
onMounted(() => {
  getListPages();
  getServerStatuses();
});
</script>
<style lang="scss" scoped>
.teleport-icon {
  position: fixed;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  z-index: 10000;
}
.teleport-content {
  position: absolute;
  width: 100%;
  background-color: #c4e8f4;
  border: grey;
  color: black;
  left: 0;
  top: 0;
  z-index: 1000;
  box-shadow: 5px 2px 12px 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
}
.icon_close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.main-list {
  padding: 20px 40px;
}
.item-list {
  cursor: pointer;
}
.sub-list {
  padding: 20px;
  list-style-type: none;
}
.subitem-list {
  cursor: pointer;
}
:deep(.small-text) {
  font-size: 10px;
}
:deep(.text-desc) {
  font-weight: 500;
  margin-top: 20px;
  display: block;
}
.text-label {
  display: flex;
  input {
    margin-right: 10px;
  }
}
.status-wrapper {
  display: flex;
  justify-content: space-between;
}
.apply-panel {
  max-width: 150px;
}
</style>
