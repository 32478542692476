<template>
  <div class="strategy-item-block">
    <div class="strategy-content-block">
      <div class="strategy-name-row">
        <div class="strategy-name-container">
          <div class="subtitle second-text no_mobile">стратегия</div>
          <div
            class="main-text no-text-transform"
            :class="{
              'alfa-name': isStrategyAlpha,
            }"
          >
            {{ props.strategy.name }}
          </div>
        </div>
      </div>
      <div
        class="strategy-description second-text"
        v-html="props.strategy.shortDescription"
      />
      <div
        v-if="
          !(
            props.strategy.serverName ===
              constants.strategiesServerNames.defaultBorrowerDistribution ||
            props.strategy.serverName ===
              constants.strategiesServerNames.personalBorrowerDistribution
          )
        "
        class="strategy-devider only_mobile"
      />
    </div>

    <div class="strategy-rates-block">
      <div
        v-if="props.strategy.currentRate?.rate"
        class="strategy-rate-container"
      >
        <span class="subtitle second-text">ставка на остаток</span>
        <span class="main-text">
          {{ createRateText(props.strategy.currentRate?.rate) }}
          <span
            v-if="nextFreeStrategyRateTitle(props.strategy)"
            class="rate-star second-text"
          >
            *
          </span>
        </span>
      </div>
      <div
        v-if="
          props.strategy.currentRate?.rate &&
          props.strategy.serverName ===
            constants.strategiesServerNames.withoutRisk
        "
        class="strategy-rate-container second-rate"
      >
        <span class="subtitle second-text no_mobile"
          >фиксированная доходность</span
        >
        <span class="subtitle second-text only_mobile">доходность</span>
        <span class="main-text">
          {{ createRateText(props.strategy.currentRate?.rate) }}
        </span>
      </div>
      <div
        v-if="isShowExpectedMinReturnRate"
        class="strategy-rate-container second-rate"
      >
        <div class="future-rate-container">
          <span class="subtitle second-text no_mobile">
            ожидаемая доходность
          </span>
          <i
            class="future-rate-trigger no_mobile"
            @click="onClickShowFutureRateDesc"
          />
        </div>
        <span class="subtitle second-text only_mobile">доходность</span>
        <span class="main-text">
          {{
            createRateText(props.strategy.currentRate?.expectedMinReturnRate)
          }}
        </span>
      </div>
    </div>

    <div
      class="strategy-conditions-block"
      :class="{
        'strategy-conditions-block-no-risk-future':
          props.strategy.serverName ===
            constants.strategiesServerNames.withoutRisk &&
          nextFreeStrategyRateTitle,
      }"
    >
      <div class="next-free-strategy-container">
        <DiversificationButton
          v-if="isShowDiversificationButton"
          :is-enhanced-diversification="
            props.selectedStrategy.isEnhancedDiversification
          "
          @click="
            props.diversificationOptions.showModalDiversification({
              selectedStrategy: props.strategy,
              isEnhancedDiversification:
                props.selectedStrategy.isEnhancedDiversification,
              isConnectButton: false,
            })
          "
        />

        <div
          v-if="nextFreeStrategyRateTitle"
          class="next-free-strategy-rate second-text"
        >
          {{ nextFreeStrategyRateTitle(props.strategy) }}
        </div>
        <BonusFromBalance
          v-if="isBonusFromBalanceActiveEnabled"
          :loading="props.loading"
          class="auto-invest-bonus-from-balance"
        />
      </div>

      <div
        class="strategy-buttons-block"
        :class="{
          'strategy-buttons-block-autoinvest':
            props.strategy.serverName ===
            constants.strategiesServerNames.personalBorrowerDistribution,
          'strategy-buttons-block-connected':
            !(
              props.strategy.serverName ===
              constants.strategiesServerNames.personalBorrowerDistribution
            ) && strategy.serverName === props.selectedStrategy.strategy,
        }"
      >
        <PotokButton
          :class="{
            'strategy-button-connect-grey':
              props.strategy.serverName ===
              constants.strategiesServerNames.personalBorrowerDistribution,
            'strategy-button-connect-connected':
              strategy.serverName === props.selectedStrategy.strategy,
          }"
          :text="
            props.strategy.serverName === props.selectedStrategy.strategy
              ? 'подключена ' +
                formatDate(props.selectedStrategy.selectedStrategyDate)
              : 'подключить'
          "
          half-width-mobile
          :disabled="
            props.strategy.serverName === props.selectedStrategy.strategy
          "
          @click="onClickConnectStrategy(props.strategy)"
        >
          <template #icon>
            <i
              v-if="
                props.strategy.serverName === props.selectedStrategy.strategy
              "
              class="strategy-button-connect-icon no_mobile"
            />
          </template>
        </PotokButton>

        <div
          v-if="isSelectedStrategyPersonal"
          class="strategy-buttons-autoinvest-container"
        >
          <button
            class="button button-pc-condition strategy-button-autoinvest first-button-autoinvest"
            @click="onClickConnectStrategy(props.strategy)"
          >
            <i class="strategy-autoinvest-button-change-icon" />
            изменить
          </button>

          <button
            class="button button-pc-condition strategy-button-autoinvest"
            @click.prevent="
              downloadSignedDocumentsAsync({
                id: props.selectedStrategy.selectedStrategyDocumentId,
                type: 'ENABLE_PERSONAL_AUTO_INVEST_SETTINGS',
              })
            "
          >
            <i class="strategy-autoinvest-button-options-icon" />
            настройки
          </button>
        </div>

        <PotokButton
          v-if="
            props.strategy.serverName !==
            constants.strategiesServerNames.personalBorrowerDistribution
          "
          class="button-direct-condition"
          text="условия"
          theme="tertiary"
          half-width-mobile
          @click="
            onClickShowCondition(
              props.strategy.fullDescription,
              props.strategy.fullDescriptionTitle,
            )
          "
        />

        <i
          v-if="
            props.strategy.serverName !==
            constants.strategiesServerNames.personalBorrowerDistribution
          "
          class="button-direct-condition-icon"
          @click="
            onClickShowCondition(
              props.strategy.fullDescription,
              props.strategy.fullDescriptionTitle,
            )
          "
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { format, parseISO } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { computed } from 'vue';
import { formatCurrency, formatDate } from '@/utils/commonUtils/utils';
import BonusFromBalance from '@/components/_generic/BonusFromBalance.vue';
import DiversificationButton from '@/components/AutoInvest/DiversificationButton.vue';
import documentUtils from '@/composables/documentsComposable';
import constants from '@/constants';
import { useStateStore } from '@/store/stateStore';
import { PotokButton } from 'potok-uikit';

const store = useStateStore();
const { downloadSignedDocumentsAsync } = documentUtils();

const props = defineProps({
  strategy: {
    type: Object,
    required: true,
  },
  selectedStrategy: {
    type: Object,
    default: () => {
      return {};
    },
  },
  loading: {
    type: Boolean,
    required: true,
  },
  diversificationOptions: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const emit = defineEmits([
  'connectStrategy',
  'showCondition',
  'showFutureRateDesc',
]);

const isShowDiversificationButton = computed(() => {
  return (
    props.strategy.serverName === props.selectedStrategy.strategy &&
    props.strategy.isSecondaryMarketStrategy
  );
});

const isShowExpectedMinReturnRate = computed(() => {
  return (
    props.strategy.currentRate?.expectedMinReturnRate &&
    (props.strategy.serverName ===
      constants.strategiesServerNames.conservative ||
      props.strategy.serverName ===
        constants.strategiesServerNames.alfaConservative ||
      props.strategy.serverName ===
        constants.strategiesServerNames.aggressive ||
      props.strategy.serverName ===
        constants.strategiesServerNames.fixedFairRate ||
      props.strategy.serverName ===
        constants.strategiesServerNames.alfaBasic)
  );
});

const isBonusFromBalanceActiveEnabled = computed(() => {
  return (
    store.state.features.general.remainingBalanceInterestPromo.enabled &&
    isStrategyIncludedBonus(props.strategy.serverName) &&
    bonusFromBalanceData.value.isShowIcon
  );
});

const isStrategyAlpha = computed(() => {
  return (
    props.strategy.serverName ===
      constants.strategiesServerNames.alfaConservative ||
    props.strategy.serverName === constants.strategiesServerNames.alfaLiquid
  );
});

const isSelectedStrategyPersonal = computed(() => {
  return (
    props.strategy.serverName ===
      constants.strategiesServerNames.personalBorrowerDistribution &&
    props.strategy.serverName === props.selectedStrategy.strategy
  );
});

const bonusFromBalanceData = computed(() => {
  return store.state.features.remainingBalanceInterestPromo;
});

const isStrategyIncludedBonus = (strategy) => {
  return bonusFromBalanceData.value?.eligibleStrategies.includes(strategy);
};

const nextFreeStrategyRateTitle = (strategy) => {
  if (!strategy?.futureRate?.rate || !strategy?.futureRate?.startAt) {
    return null;
  }

  const computedRate = `*${createRateText(strategy?.futureRate?.rate)}`;

  return `${computedRate} с ${format(parseISO(strategy?.futureRate?.startAt), 'dd.MM.yyyy', { locale: ru })}`;
};

const createRateText = (rate) => {
  const finalRate = rate * 100;
  let digits = Number.isInteger(finalRate) ? 0 : 1;
  // Если finalRate при умножении получило не корректное значение типа "28.0000000000000004", проверяем это и фиксим digits
  if (digits === 1) {
    const fixedRate = Number(finalRate.toFixed(1));
    if (Number.isInteger(fixedRate)) {
      digits = 0;
    }
  }
  return `${formatCurrency(finalRate, digits)}%`;
};

const onClickConnectStrategy = (strategy) => {
  emit('connectStrategy', strategy);
};

const onClickShowCondition = (condition, name) => {
  emit('showCondition', {
    condition,
    name,
  });
};

const onClickShowFutureRateDesc = () => {
  emit('showFutureRateDesc');
};
</script>
<style lang="scss" scoped>
.strategy-item-block {
  position: relative;
  display: flex;
  border-radius: 14px;
  background: #fff;
  padding: 30px;

  @media (max-width: $size_767) {
    margin-bottom: 20px;
    flex-direction: column;
    padding: 20px 14px 14px 14px;
  }
}

.strategy-content-block {
  width: 280px;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  @media (max-width: $size_991) {
    width: 200px;
  }

  @media (max-width: $size_767) {
    margin-bottom: 0;
    width: auto;
  }
}

.strategy-name-row {
  display: flex;

  @media (max-width: $size_767) {
    max-width: 170px;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 6px;
  }
}

.second-text {
  line-height: 22px;
  color: $grey;

  @media (max-width: $size_767) {
    font-size: 14px;
    line-height: 22px;
  }
}

.subtitle {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @media (max-width: $size_767) {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0.01px;
    margin-bottom: 6px;
  }
}

.strategy-condition-mobile-row {
  width: 100%;
  display: none;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $size_767) {
    display: flex;
  }
}

.strategy-conditions-alfa-icon {
  display: block;
  height: 14px;
  width: 68px;
  margin-left: auto;
  background-image: url('@img/alfa-full.svg');
}

.next-free-strategy-container {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.next-free-strategy-rate {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.01em;
  margin-right: 20px;

  @media (max-width: $size_991) {
    bottom: 25px;
  }

  @media (max-width: $size_767) {
    bottom: 66px;
    right: initial;
  }
}

.alfa-name {
  display: flex;
  align-items: center;
  color: $red !important;
}

.rate-star {
  position: relative;
  right: 3px;
}

.strategy-description {
  position: absolute;
  bottom: 25px;
  line-height: 22px;
  font-weight: 400;
  font-size: 16px;

  @media (max-width: $size_767) {
    font-size: 14px;
    position: static;
  }
}

.strategy-rates-block {
  display: flex;
  margin-left: 30px;

  @media (max-width: $size_767) {
    margin-left: 0;
  }
}

.strategy-rate-container {
  display: flex;
  flex-direction: column;
}

.second-rate {
  margin-left: 30px;

  @media (max-width: $size_767) {
    margin-left: 14px;
  }
}

.strategy-conditions-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;

  @media (max-width: $size_767) {
    margin-top: 30px;
    margin-bottom: 0;
    margin-left: 0;
    flex-direction: column;
    align-items: start;
  }
}

.strategy-conditions-block-no-risk-future {
  @media (max-width: $size_767) {
    margin-top: 50px;
  }
}

.button-pc-condition {
  height: 44px;
  border: 1px solid $input;
  background: none;
  color: $breakwater;

  &:hover {
    background: $background;
    border: 1px solid $background;
  }

  @media (max-width: $size_767) {
    width: 125px;
  }
}

.future-rate-trigger {
  position: relative;
  bottom: 2px;
  margin-left: 6px;
  display: block;
  height: 14px;
  width: 14px;
  cursor: pointer;
  background-image: url('@img/attention.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.future-rate-container {
  display: flex;
}

.strategy-buttons-block {
  display: flex;
  align-items: center;

  @media (max-width: $size_767) {
    width: 100%;
  }
}

.strategy-button-connect-grey {
  border: 1px solid $breakwater;
  background: none;
  color: $breakwater;

  &:hover {
    background: $breakwater;
    color: #fff;
  }
}

.strategy-button-connect-connected {
  border: 1px solid $input;
  background: none;
  color: $grey;
  cursor: default;

  &:hover {
    background: none;
    color: $grey;
  }

  @media (max-width: $size_767) {
    width: 200px;
    min-height: 20px;
    justify-content: flex-start;
    height: 20px;
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    border: none;
    color: $deep_dive;
  }
}

.strategy-button-connect-icon {
  position: relative;
  top: 1px;
  margin-right: 4px;
  display: block;
  height: 16px;
  width: 16px;
  background-image: url('@img/tick-circle.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.strategy-autoinvest-button-change-icon {
  position: relative;
  top: 1px;
  margin-right: 4px;
  display: block;
  height: 16px;
  width: 16px;
  background-image: url('@img/strategies-options.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.strategy-autoinvest-button-options-icon {
  position: relative;
  top: 1px;
  margin-right: 4px;
  display: block;
  height: 16px;
  width: 16px;
  background-image: url('@img/strategies-arrow-down.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.strategy-button-autoinvest {
  border: 1px solid rgba(129, 142, 153, 0.2);

  &:hover {
    background: #dadfe5;
    border: 1px solid #dadfe5;
  }

  @media (max-width: $size_767) {
    padding: 0 11px;
    letter-spacing: -0.3px;
  }
}

.first-button-autoinvest {
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: $size_767) {
    margin-left: 0;
  }
}

.strategy-devider {
  margin: 20px 0;
  border-top: 1px solid $dividers;
  width: 100%;
}

.strategy-buttons-autoinvest-container {
  display: flex;

  @media (max-width: $size_767) {
    margin-top: 20px;
  }
}

.strategy-buttons-block-autoinvest {
  @media (max-width: $size_767) {
    flex-direction: column;
    align-items: initial;
    justify-content: center;
  }
}

.button-direct-condition {
  margin-left: 10px;
}

.button-direct-condition-icon {
  display: none;
}

.strategy-buttons-block-connected {
  @media (max-width: $size_767) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .button-direct-condition {
    @media (max-width: $size_767) {
      display: none;
    }
  }

  .button-direct-condition-icon {
    @media (max-width: $size_767) {
      display: block;
      height: 24px;
      width: 24px;
      background-image: url('@img/attention.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
</style>
