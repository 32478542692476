<template>
  <section class="page settings_profile">
    <SettingsDesktop v-if="!isTablet" />
    <SettingsMobile v-if="isTablet" />
  </section>
</template>
<script setup>
import detectDevice from '@/composables/detectDeviceComposable';
import SettingsDesktop from './Settings/SettingsContainers/SettingsDesktop.vue';
import SettingsMobile from './Settings/SettingsContainers/SettingsMobile.vue';

const { isTablet } = detectDevice();
</script>
<style lang="scss" scoped>
.settings_profile {
  @media (max-width: $size_991) {
    margin-top: 20px;
    padding-top: 0;
  }
}

:deep(.content-block) {
  margin-left: 0;
  margin-right: 0;
  padding: 20px 22px;
  @media (max-width: $size_991) {
    padding: 12px;
  }
}

:deep(.item_row) {
  width: 100%;
  margin: 0;
  .item_name {
    padding: 6px 0;
    @media (max-width: $size_991) {
      padding: 3px 0;
    }
  }
  .item_value {
    padding: 6px 20px 6px 0;
    @media (max-width: $size_991) {
      font-size: 14px;
      line-height: 20px;
      padding: 2px 0;
    }
  }
}
</style>
