<template>
  <div class="investment_card__panel">
    <FormFieldTitle :devider="false" :title="'Состояние расчетов'" />
    <table class="investment_card__table">
      <thead>
        <th></th>
        <th>
          <span v-if="props.item.status !== 'cession'">возвращено факт</span>
          <span v-if="props.item.status === 'cession'"
            >возвращено заемщиком</span
          >
        </th>
        <th>
          <span v-if="props.item.status !== 'cession'">остаток план</span>
          <span v-if="props.item.status === 'cession'"
            >возвращено по цессии — Поток</span
          >
        </th>
        <th>
          <span v-if="props.item.status !== 'cession'">всего план</span>
          <span v-if="props.item.status === 'cession'">всего факт</span>
        </th>
      </thead>
      <tbody>
        <tr>
          <td>основной долг</td>
          <td>
            <template v-if="props.item.status === 'cession'">
              <span>{{
                formatCurrency(
                  props.item.stateOfCalculations.refundedByBorrower.mainDebt,
                  0,
                )
              }}</span>
            </template>
            <template v-else>
              <span v-if="props.item.stateOfCalculations.refunded">{{
                formatCurrency(
                  props.item.stateOfCalculations.refunded.mainDebt,
                  0,
                )
              }}</span>
            </template>

            ₽
          </td>
          <td>
            <template v-if="props.item.status === 'cession'">
              <span>{{
                formatCurrency(
                  props.item.stateOfCalculations.refundedByPlatform.mainDebt,
                  0,
                )
              }}</span>
            </template>
            <template v-else>
              <span v-if="props.item.stateOfCalculations.expecting">{{
                formatCurrency(
                  props.item.stateOfCalculations.expecting.mainDebt,
                  0,
                )
              }}</span>
            </template>
            ₽
          </td>
          <td>
            <span>{{
              formatCurrency(props.item.stateOfCalculations.total.mainDebt, 0)
            }}</span>
            ₽
          </td>
        </tr>
        <tr>
          <td>проценты и пени</td>
          <td>
            <template v-if="props.item.status === 'cession'">
              <span>{{
                formatCurrency(
                  props.item.stateOfCalculations.refundedByBorrower.income,
                  0,
                )
              }}</span>
            </template>
            <template v-else>
              <span v-if="props.item.stateOfCalculations.refunded">{{
                formatCurrency(
                  props.item.stateOfCalculations.refunded.income,
                  0,
                )
              }}</span>
            </template>
            ₽
          </td>
          <td>
            <template v-if="props.item.status === 'cession'">
              <span>{{
                formatCurrency(
                  props.item.stateOfCalculations.refundedByPlatform.income,
                  0,
                )
              }}</span>
            </template>
            <template v-else>
              <span v-if="props.item.stateOfCalculations.expecting">{{
                formatCurrency(
                  props.item.stateOfCalculations.expecting.income,
                  0,
                )
              }}</span>
            </template>
            ₽
          </td>
          <td>
            <span>{{
              formatCurrency(props.item.stateOfCalculations.total.income, 0)
            }}</span>
            ₽
          </td>
        </tr>
        <tr>
          <td>общий возврат</td>
          <td>
            <template v-if="props.item.status === 'cession'">
              <span>{{
                formatCurrency(
                  props.item.stateOfCalculations.refundedByBorrower.totalAmount,
                  0,
                )
              }}</span>
            </template>
            <template v-else>
              <span v-if="props.item.stateOfCalculations.refunded">{{
                formatCurrency(
                  props.item.stateOfCalculations.refunded.totalAmount,
                  0,
                )
              }}</span>
            </template>
            ₽
          </td>
          <td>
            <template v-if="props.item.status === 'cession'">
              <span>{{
                formatCurrency(
                  props.item.stateOfCalculations.refundedByPlatform.totalAmount,
                  0,
                )
              }}</span>
            </template>
            <template v-else>
              <span v-if="props.item.stateOfCalculations.expecting">{{
                formatCurrency(
                  props.item.stateOfCalculations.expecting.totalAmount,
                  0,
                )
              }}</span>
            </template>
            ₽
          </td>
          <td>
            <span>{{
              formatCurrency(
                props.item.stateOfCalculations.total.totalAmount,
                0,
              )
            }}</span>
            ₽
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { formatCurrency } from '@/utils/commonUtils/utils';
import FormFieldTitle from '@/components/_generic/forms/FormFieldTitle.vue';

const props = defineProps(['item']);
</script>
<style lang="scss" scoped>
.investment_card__table {
  width: 100%;
  margin: 0px;
  text-transform: none;
  th {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #313132;
    padding: 13px 0;
    text-align: left;
  }
  td {
    border-top: 1px solid #ebedf4;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #313132;
    padding: 10px 0;
  }
}
</style>
