<template>
  <div>
    <div>
      <ul>
        <template v-for="(step, i) in visiblePassedSteps" :key="step.id">
          <li
            class="prevstep"
            :class="{
              cursor_active: isCurrentStepHasPrevStep(step.id),
            }"
            @click="onClickPrevStep(step.id)"
          >
            <div class="number">
              {{ step?.titleId ? step?.titleId : i + 1 }}
            </div>
          </li>
          <li
            v-if="
              currentStep?.visiblePassedStepsIds ? true : currentStep?.visible
            "
            class="border active-border"
          />
        </template>
        <li
          v-if="
            currentStep?.visiblePassedStepsIds ? true : currentStep?.visible
          "
          class="active"
        >
          <div
            class="number"
            :class="{
              number_last: !currentStep.nextStepId,
            }"
          >
            <span>{{
              currentStep?.titleId
                ? currentStep.titleId
                : visiblePassedSteps.length + 1
            }}</span>
          </div>
        </li>
        <li v-if="visibleNextSteps.length > 0" class="border"></li>
        <template v-for="(step, index) in visibleNextSteps" :key="step.id">
          <li :class="{ active: currentStep === step.value }">
            <div
              class="number"
              :class="{
                success: currentStep !== step.value,
                number_last: !step.nextStepId,
              }"
            >
              <span>{{
                step?.titleId
                  ? step?.titleId
                  : calculateTitleIdForNextSteps(index)
              }}</span>
            </div>
          </li>
          <li v-if="index < visibleNextSteps?.length - 1" class="border"></li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import ParentComponent from './WizardParent.vue';

export default {
  extends: ParentComponent,
};
</script>
<style lang="scss" scoped>
ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 16px 0;
  }
}
.active,
.prevstep {
  color: $breakwater;
  .number {
    color: $breakwater;
    border: 1px solid $breakwater;
  }
}
.border {
  min-height: 1px;
  min-width: 10px;
  flex: 1;
  margin: 0 8px;
  &::after {
    content: '';
    height: 1px;
    width: 100%;
    border-top: 1px solid $grey;
  }
}
.active-border {
  &::after {
    border-top: 1px solid $breakwater;
  }
}
.number {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #93a1b0;
  color: #93a1b0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.number_last {
  &::after {
    border: none;
    border-right: none !important;
  }
}

.cursor_active {
  cursor: pointer;
}
</style>
