<template>
  <div class="refill-spb">
    <div class="block-left block_content">
      <div class="col-md-12 wrapper-step2">
        <div class="text-desc potok-text-h2">
          <div v-html="props.data?.text"></div>
        </div>
        <div class="qr-wrapper">
          <div class="qr-code-panel">
            <img :src="'data:image/png;base64,' + props.qrCode" />
          </div>
          <div class="qr-code-wrapper">
            <div>
              <div class="qr-amount">
                к оплате: {{ formatCurrency(props.amountValue, 0) }}&nbsp;₽
              </div>
              <button
                type="button"
                class="button button-text qr-back"
                @click="onClickBack"
              >
                <i class="icon_arrow_back"></i>изменить сумму
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RefillBalanceSPBRightPanel />
  </div>
</template>
<script setup>
import { formatCurrency } from '@/utils/commonUtils/utils';
import RefillBalanceSPBRightPanel from './RefillBalanceSPBRightPanel.vue';

const emit = defineEmits(['prevStep']);
const props = defineProps({
  amountValue: {
    type: Number,
  },
  qrCode: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: false,
  },
});

const onClickBack = () => {
  emit('prevStep');
};
</script>
<style lang="scss" scoped>
.refill-spb {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.block-left {
  min-width: 750px;
  @media (max-width: $size_767) {
    min-width: initial;
  }
}
.qr-code-wrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 20px;
}
.text-desc {
  max-width: 522px;
}
.step-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $breakwater;
}
.qr-code-panel {
  img {
    width: 315px;
    height: 315px;
  }
}
.qr-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: $deep_dive;
}
.qr-back {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $tiffany;
  cursor: pointer;
  margin-top: 8px;
  &:hover {
    opacity: 0.5;
  }
}
.icon_arrow_back {
  margin-right: 6px;
}
.qr-wrapper {
  display: flex;
}
</style>
