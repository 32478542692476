import { createApp } from 'vue';
import { createPinia } from 'pinia';

import '@yzfe/svgicon/lib/svgicon.css';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import Maska from 'maska';

import VTooltip from 'v-tooltip';
import 'v-tooltip/dist/v-tooltip.css';
import PotokImg from '@/components/_generic/PotokImg.vue';
import PotokChip from '@/components/_generic/PotokChip.vue';
import { PotokButton } from "potok-uikit";

import App from '@/components/App.vue';
import router from '@/router/index';

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);

app.component('VSelect', vSelect);
app.component('VueSlider', VueSlider);
app.component('PotokImg', PotokImg);
app.component('PotokButton', PotokButton);
app.component('PotokChip', PotokChip);
app.use(Maska);
app.use(VTooltip);

app.mount('#app');
export default app;
