<template>
  <div class="container container_wrapper">
    <div class="login_title">
      <div>восстановление пароля</div>
    </div>
    <div class="login_subtitle login_subtitle_restricted">
      придумайте новый пароль для номера
      <span>{{ decorateFormatPhone(props.rawPhone) }}</span>
    </div>
    <div>
      <div class="login_actions">
        <div class="login_input_wrapper">
          <FormFieldPasspord
            id="password"
            v-model="localRawPassword"
            placeholder="новый пароль"
            @update:model-value="onClickPressKeyPassword"
            @keyup.enter="onClickEnterPassword()"
          />
          <p
            v-if="!errorMessagePassword"
            class="potok-text-body-1 color-deepdive text_tips"
          >
            минимум 6 символов
          </p>
          <div
            v-if="errorMessagePassword"
            class="potok-text-body-1 color-deepdive text_tips form-error error_message"
          >
            {{ errorMessagePassword }}
          </div>
        </div>
        <button
          type="button"
          class="button button-breakwater button-password"
          @click="onClickEnterPassword()"
        >
          продолжить
        </button>

        <PotokButton
          theme="link"
          size="link"
          text="назад"
          class="mt-7 login-recover-back"
          @click="onClickBack"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, nextTick, onMounted } from 'vue';
import { focusDomElementById } from '@/utils/commonUtils/utils';
import {
  convertRawPhoneToLogin,
  decorateFormatPhone,
} from '@/utils/phone/formatPhone';
import FormFieldPasspord from '@/components/_generic/forms/FormFieldPasspord.vue';
import server from '@/server';
import validators from '@/validators';
import { PotokButton } from 'potok-uikit';

const props = defineProps(['rawPhone', 'rawPassword']);
const emit = defineEmits(['changeStep', 'changeMode', 'changePassword']);

const loading = ref(false);
const localRawPassword = ref(null);
const errorMessagePassword = ref(null);

const isProduction = process.env.VUE_APP_NODE_ENV === 'production';

watch(
  () => {
    return props.rawPassword;
  },
  () => {
    localRawPassword.value = props.rawPassword;
  },
);

const isValidPassword = () => {
  if (!localRawPassword.value) {
    errorMessagePassword.value = 'Обязательное поле';
    return false;
  }
  if (localRawPassword.value.length < 6) {
    errorMessagePassword.value = 'Минимальная длина пароля 6 символов';
    return false;
  }
  if (localRawPassword.value.length > 32) {
    errorMessagePassword.value = 'Максимальная длина пароля 32 символов';
    return false;
  }
  if (!validators.passwordAlpha.$validator(localRawPassword.value)) {
    errorMessagePassword.value =
      'Пароль должен состоять из строчных и/или заглавных букв латинского алфавита (A—Z), цифр и знаков препинания.';
    return false;
  }
  return true;
};

const onClickPressKeyPassword = () => {
  errorMessagePassword.value = null;
  emit('changePassword', localRawPassword.value);
};

const onClickEnterPassword = () => {
  if (!isValidPassword()) {
    return;
  }

  if (isProduction) {
    window.smartCaptcha.execute();
    return;
  }

  emit('changePassword', localRawPassword.value);
  requestRecoveringAsync();
};

const onClickBack = () => {
  emit('changeMode', {
    component: 'inputPassword',
  });
};

const smartcaptchaRender = () => {
  if (!window.smartCaptcha) {
    return;
  }

  window.smartCaptcha.render('captcha-container', {
    sitekey: `${process.env.VUE_APP_SMART_CAPTCHA_KEY}`,
    invisible: true, // Сделать капчу невидимой
    hideShield: true,
    callback: smartCaptchaCallback,
  });
};

function smartCaptchaCallback(captchaToken) {
  emit('changePassword', localRawPassword.value);
  requestRecoveringAsync(captchaToken);
}

const requestRecoveringAsync = (captchaToken) => {
  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
  };
  if (isProduction) {
    query['smart-token'] = captchaToken;
  }
  loading.value = true;
  return server.postUsersRecoverPassRequest
    .send(query)
    .pipe(onRequestRecoveringAsyncSuccess.bind(this, query), () => {
      loading.value = false;
    })
    .exec();
};

const onRequestRecoveringAsyncSuccess = () => {
  if (isProduction) {
    window.smartCaptcha.reset();
  }
  loading.value = true;
  emit('changeStep', 'inputSMSCode');
};

onMounted(() => {
  localRawPassword.value = props.rawPassword;
  nextTick(() => {
    focusDomElementById('password');
  });
  if (isProduction) {
    smartcaptchaRender();
  }
});
</script>
<style lang="scss" scoped>
.login_subtitle_restricted {
  max-width: 306px;
  margin: 0 auto;
  margin-top: 12px;
}
.button-password {
  margin-top: 12px;
}
.login-recover-back {
  @media (max-width: $size_767) {
    margin-top: 20px;
  }
}
</style>
