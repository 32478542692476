<template>
  <div class="container container_wrapper">
    <div class="login_title">
      <div>регистрация</div>
    </div>
    <div class="login_subtitle">
      <div class="login_subtitle_restricted">
        номера <span>{{ decorateFormatPhone(props.rawPhone) }}</span> нет в
        базе, придумайте пароль, чтобы зарегистрироваться
      </div>
    </div>
    <div class="login_actions">
      <div class="login_input_wrapper">
        <FormFieldPasspord
          id="password"
          v-model="localRawPassword"
          placeholder="новый пароль"
          @update:model-value="onClickPressKeyPassword"
          @keyup.enter="onClickEnterPassword()"
        />
        <p
          v-if="!errorMessagePassword"
          class="potok-text-body-1 color-deepdive text_tips"
        >
          минимум 6 символов
        </p>
        <div
          v-if="errorMessagePassword"
          class="potok-text-body-1 color-deepdive text_tips form-error error_message"
        >
          {{ errorMessagePassword }}
        </div>
      </div>
      <PotokButton
        text="продолжить"
        full-width-desktop
        size="large"
        class="mt-6"
        @click="onClickEnterPassword"
      />

      <PotokButton
        theme="link"
        class="mt-3"
        full-width-desktop
        size="link"
        text="назад"
        @click="onClickBack"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { decorateFormatPhone } from '@/utils/phone/formatPhone';
import FormFieldPasspord from '@/components/_generic/forms/FormFieldPasspord.vue';
import validators from '@/validators';
import tracker from '@/tracker';

const props = defineProps(['rawPhone', 'rawPassword', 'loadingRegister']);
const emit = defineEmits(['changeStep', 'changePassword']);

const localRawPassword = ref(null);
const errorMessagePassword = ref(null);

const isPasswordValid = () => {
  if (!localRawPassword.value || localRawPassword.value.length < 6) {
    errorMessagePassword.value = 'Минимальная длина пароля 6 символов';
    return false;
  }
  if (localRawPassword.value.length > 32) {
    errorMessagePassword.value = 'Максимальная длина пароля 32 символов';
    return false;
  }
  if (!validators.passwordAlpha.$validator(localRawPassword.value)) {
    errorMessagePassword.value =
      'Пароль должен состоять из строчных и/или заглавных букв латинского алфавита (A—Z), цифр и знаков препинания.';
    return false;
  }
  return true;
};

const onClickPressKeyPassword = () => {
  errorMessagePassword.value = null;
  emit('changePassword', localRawPassword.value);
};

const onClickEnterPassword = () => {
  if (!isPasswordValid()) {
    return;
  }
  tracker.queue(
    tracker.commands.SEND,
    'Adg_Investory_Shag3_Registracija_sozdal_parol',
  );
  emit('changeStep', 'inputEmail');
};
const onClickBack = () => {
  emit('changeStep', 'inputPhone');
};
watch(
  () => {
    return props.rawPhone;
  },
  () => {
    localRawPassword.value = props.rawPhone;
  },
);
</script>
<style lang="scss" scoped>
.login_subtitle_restricted {
  max-width: 265px;
}
.button-back {
  margin-top: 30px;
}
</style>
