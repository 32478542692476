<template>
  <section class="new-investments page">
    <template
      v-if="
        store.state.user.name &&
        store.state.user.surname &&
        !isInvestmentsLoading
      "
    >
      <template
        v-if="
          store.state.user.passportProcessingStatus ===
          constants.passportStatus.PENDING
        "
      >
        <section class="balances">
          <div class="row">
            <div class="col-md-8 col-xs-12">
              <h2 class="block-title">Проверка паспортных данных</h2>
            </div>
          </div>
          <div class="content-block balances-row background-processing-status">
            <div class="row">
              <div class="balances-item col-md-8 col-xs-8">
                <div class="property">
                  В настоящий момент происходит проверка ваших паспортных
                  данных. Этот процесс занимает не более 1-2 часов (в рабочее
                  время), после чего средства начнут автоматически
                  распределяться в займы.”
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
    </template>

    <template v-if="isUserLoaded && store.selectedCompany">
      <div class="new-investments-menu-container">
        <NewInvestmentsMenu />

        <div class="new-investments-action-links potok-text-body-1">
          <router-link
            :to="{ name: 'analyticsHistoryOperation' }"
            class="new-investments-action new-investments-action-history"
          >
            <PotokImg :src="imageSrc.clock" class="mr-1" />
            {{ isTablet ? 'история' : 'история операций' }}
          </router-link>

          <PotokButton
            v-if="!store.isSecondaryMarketOperator"
            :text="isTablet ? 'продать' : 'продать инвестиции'"
            theme="linkIcon"
            size="link"
            class="new-investments-action-sell-investments"
            @click="toInvestmentsSellList"
          >
            <template #icon>
              <PotokImg
                v-if="!isInvestmentsLoading"
                :src="imageSrc.sellInvestmentsIcon"
                class="mr-1"
              />
              <PotokImg
                v-if="isInvestmentsLoading"
                loading-icon
                loading-height="16"
                loading-width="16"
                loading-border="3"
                loading-theme="dark"
                class="mr-1"
              />
            </template>
          </PotokButton>
        </div>
      </div>

      <router-view />
    </template>
    <template v-else>
      <Loader />
    </template>

    <ModalFull
      v-if="isShowActiveRequestModal"
      class="investments-active-request-modal"
      @close="isShowActiveRequestModal = false"
    >
      <template #content>
        <div class="text-center potok-text-h2">
          у вас уже есть активная заявка на продажу
        </div>
        <div
          class="investments-active-request-modal-text text-center potok-text-body-1"
        >
          <router-link
            class="investments-active-request-modal-link"
            :to="{ name: 'sellListItem', params: { id: activeRequest.id } }"
          >
            №{{ activeRequest.number }}
          </router-link>
          по ЛС №{{ selectedCompany.investorAccountNumber }} — дождитесь
          результатов обработки или отмените ее
        </div>
      </template>
      <template #actions>
        <div class="button-center">
          <button
            type="button"
            class="button button-secondary button_ok"
            @click.stop="isShowActiveRequestModal = false"
          >
            понятно
          </button>
        </div>
      </template>
    </ModalFull>
  </section>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { useStateStore } from '@/store/stateStore';
import constants from '@/constants';
import imageSrc from '@/constants/imageConstants';
import detectDevice from '@/composables/detectDeviceComposable';

import NewInvestmentsMenu from '@/components/Investments/NewInvestmentsMenu/NewInvestmentsMenu.vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import server from '@/server';
import { PotokButton, Loader } from 'potok-uikit';

const store = useStateStore();
const router = useRouter();
const { isTablet } = detectDevice();

const isInvestmentsLoading = ref(false);

const hasActiveRequest = ref(false);
const activeRequest = ref(null);
const isShowActiveRequestModal = ref(false);

const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});

const toInvestmentsSellList = () => {
  getInvestmentsForSale();
};

const getRequestsList = () => {
  const query = {
    sort: 'number',
    order: 'DESC',
    limit: 20,
    offset: 0,
    status: 'confirmed',
  };

  query.companyId = store.selectedCompanyId;

  return server.getInvestmentsSellListNotAdmin
    .send(query)
    .pipe(onFetchInvestmentsSellListDesktopSuccess, () => {})
    .exec();
};
const onFetchInvestmentsSellListDesktopSuccess = (obj) => {
  hasActiveRequest.value = !!obj.data.count;
  if (obj.data?.rows.length) {
    activeRequest.value = obj.data.rows[0];
  }
};

const getInvestmentsForSale = () => {
  isInvestmentsLoading.value = true;

  const query = {
    companyId: store.selectedCompanyId,
  };

  return server.getInvestmentsForSale
    .send(query)
    .pipe(onFetchInvestmentsForSaleSuccess, () => {
      isInvestmentsLoading.value = false;
    })
    .exec();
};

const onFetchInvestmentsForSaleSuccess = () => {
  isInvestmentsLoading.value = false;

  if (hasActiveRequest.value) {
    isShowActiveRequestModal.value = true;
    return;
  }
  router.push({ name: 'newSellRequest' });
};

const init = () => {
  if (store.selectedCompany) {
    getRequestsList();
  }
};
init();

watch(
  () => {
    return store.selectedCompany;
  },
  () => {
    if (store.selectedCompany) {
      getRequestsList();
    }
  },
);
</script>

<style lang="scss" scoped>
.new-investments {
  padding-top: 30px;
  @media (max-width: $size_991) {
    padding-top: 15px;
  }
}
.new-investments-menu-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 35px;

  @media (max-width: $size_991) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
  }
}
.new-investments-action-links {
  display: flex;
  align-items: center;
  @media (max-width: $size_767) {
    margin-top: 25px;
  }
  @media (max-width: $size_991) {
    margin-top: 15px;
  }
}
.new-investments-action {
  height: 24px;
  display: flex;
  align-items: center;
  text-decoration: none;

  img {
    position: relative;
    top: 1px;
  }
}
.new-investments-action-history {
  color: $grey;
  @media (max-width: $size_767) {
    margin-right: 5px;
  }
  @media (max-width: $size_991) {
    margin-right: 5px;
  }

  &:hover {
    color: $deep_dive;
  }
}
.new-investments-action-sell-investments {
  margin-left: 20px;
  @media (max-width: $size_991) {
    margin-left: 0;
  }
}
.background-processing-status {
  background: #ffecec;
}
.text-center {
  text-align: center;
}
.investments-active-request-modal-text {
  max-width: 315px;
  margin: 20px auto 0 auto;
}
.investments-active-request-modal-link {
  color: $tiffany;
}
</style>
