<template>
  <ModalFull
    title="изменения паспорта"
    close-click-outside
    title-center
    width-auto
    @close="close"
  >
    <template #content>
      <div class="upload-passport-text potok-body-2">
        загрузите сканы или фотографии разворота первой страницы паспорта
        и страницу с пропиской — мы проверим и обновим данные в течение 2 рабочих дней
      </div>

      <div class="images-container mt-8">
        <ImageUpload
          :file-id="user.passportPageMainDocumentId"
          :placeholder="'Разворот первой страницы'"
          :title="'разворот <br>первой страницы'"
          class="first-image"
          @selected-file="selectedMainDocumentId"
        />

        <ImageUpload
          :file-id="user.passportPageRegistrationDocumentId"
          :placeholder="'страница <br>с пропиской'"
          title="Страница с пропиской"
          @selected-file="selectedRegistrationDocumentId"
        />
      </div>
      <div
        v-if="
          (filenamePassportUserIdentity &&
            !filenamePassportUserRegistration) ||
          (!filenamePassportUserIdentity &&
            filenamePassportUserRegistration)
        "
        class="color-red mt-3"
      >
        необходимо загрузчить две страницы паспорта
      </div>
    </template>

    <template #actions>
      <PotokButton
        :loading="loading"
        full-width-desktop
        :disabled="
          !filenamePassportUserIdentity ||
          !filenamePassportUserRegistration
        "
        size="large"
        text="отправить на проверку"
        @click="onClickCheck"
      />
    </template>
  </ModalFull>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStateStore } from '@/store/stateStore';
import server from '@/server/index';
import ImageUpload from '@/components/_generic/ImageUpload.vue';
import ModalFull from "@/components/_generic/modals/ModalFull";

const store = useStateStore();
const emit = defineEmits(['close']);

const filenamePassportUserIdentity = ref(null);
const filenamePassportUserRegistration = ref(null);
const loading = ref(false);
const user = computed(() => {
  return store.state.user;
});

const close = (obj) => {
  emit('close', obj);
};
const selectedMainDocumentId = (file) => {
  filenamePassportUserIdentity.value = file;
};
const selectedRegistrationDocumentId = (file) => {
  filenamePassportUserRegistration.value = file;
};
const onClickCheck = () => {
  const queryWithFiles = {
    filenamePassportUserIdentity: filenamePassportUserIdentity.value,
    filenamePassportUserRegistration: filenamePassportUserRegistration.value,
  };
  loading.value = true;
  return server.putUsersMePassport
    .send(queryWithFiles)
    .pipe(
      onPutUsersMePassportsAsyncSuccess.bind(this),
      onPutUsersMePassportsAsyncError.bind(this),
    )
    .exec();
};
const onPutUsersMePassportsAsyncSuccess = () => {
  getUsersMeAsync();
};
const onPutUsersMePassportsAsyncError = () => {
  loading.value = false;
  close();
};
const getUsersMeAsync = () => {
  return server.getUsersMe
    .send()
    .pipe(
      onGetUsersMeAsyncSuccess.bind(this),
      onGetUsersMeAsyncError.bind(this),
    )
    .exec();
};
const onGetUsersMeAsyncSuccess = ({ data: user }) => {
  store.merge({ user });
  loading.value = false;
  close({ reinitImages: true });
};
const onGetUsersMeAsyncError = () => {
  loading.value = false;
  close();
};
</script>

<style lang="scss" scoped>
.images-container {
  display: flex;
  padding: 20px;
  background: $background;
  border-radius: 14px;
  width: max-content;
  align-items: center;
  @media (max-width: $size_767) {
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }
}
.upload-passport-text {
  text-align: center;
  max-width: 400px;
  @media (max-width: $size_767) {
    text-align: left;
  }
}
.first-image {
  margin-right: 20px;
  @media (max-width: $size_767) {
    margin-right: initial;
    margin-bottom: 20px;
  }
}
.img-inputer-wrapper-founders .img-__label {
  height: auto;
  width: auto;
  margin: 0;
}
</style>
