<template>
  <div>
    <div class="upload-passports-container mt-8">
      <div class="img-inputer-wrapper-body">
        <div class="img-inputer-item">
          <img-inputer
            v-model="filenamePassportUserIdentity"
            :accept="mimeType"
            :img-src="urlPassportUserIdentity"
            :placeholder="'разворот<br> первой страницы'"
            bottom-text="Нажмите чтобы изменить"
            :max-size="17168"
            exceed-size-text="Размер превышает: "
            theme="light"
          />
          <template v-if="!v$.filenamePassportUserIdentity.image">
            <span
              class="img-inputer-error-text"
              style="margin-top: -15px; margin-bottom: 10px"
            >
              Разрешены файлы с расширением jpg, jpeg, png, gif или pdf не
              больше 7 Мб</span
            >
          </template>
        </div>
        <div class="img-inputer-item">
          <img-inputer
            v-model="filenamePassportUserRegistration"
            :accept="mimeType"
            :img-src="urlPassportUserRegistration"
            :placeholder="'страница<br> с пропиской'"
            bottom-text="Нажмите чтобы изменить"
            :max-size="17168"
            exceed-size-text="Размер превышает: "
            theme="light"
          />
          <template v-if="!v$.filenamePassportUserRegistration.image">
            <span
              class="img-inputer-error-text"
              style="margin-top: -15px; margin-bottom: 10px"
            >
              Разрешены файлы с расширением jpg, jpeg, png, gif или pdf не
              больше 7 Мб</span
            >
          </template>
        </div>
      </div>
    </div>
    <div class="button-center">
      <PotokButton
        text="отправить на проверку"
        :loading="loadingPutUsersMePassport"
        :disabled="v$.$invalid || isPendingPassports"
        size="large"
        full-width-desktop
        class="mt-5"
        @click="putUsersMePassportsAsync"
      ></PotokButton>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';

import { useStateStore } from '@/store/stateStore';
import ImgInputer from '@/libs/vue-img-inputer';
import validators from '../../../../validators';
import { isNotNull, filter } from '@/utils/commonUtils/utils';
import mixins from '../../../../mixins';
import { PotokButton } from 'potok-uikit';

export default {
  name: 'UploadPassports',
  components: {
    ImgInputer,
    PotokButton,
  },
  mixins: [mixins.common],
  data() {
    return {
      v$: useVuelidate(),
      loadingPutUsersMePassport: false,
      mimeType: 'image/png, image/jpeg, image/heic,.pdf',
      filenamePassportUserIdentity: null,
      filenamePassportUserRegistration: null,
      urlPassportUserIdentity: null,
      urlPassportUserRegistration: null,
    };
  },
  computed: {
    isPendingPassports() {
      return this.loadingPutUsersMePassport;
    },
    passportPageMainDocumentId() {
      return useStateStore().state.user.passportPageMainDocumentId;
    },
    passportPageRegistrationDocumentId() {
      return useStateStore().state.user.passportPageRegistrationDocumentId;
    },
  },
  validations: {
    filenamePassportUserIdentity: {
      required: validators.required,
      image: validators.image,
    },
    filenamePassportUserRegistration: {
      required: validators.required,
      image: validators.image,
    },
  },
  created() {
    if (this.passportPageMainDocumentId) {
      this.downloadIdentityDocumentAsync(this.passportPageMainDocumentId);
    }
    if (this.passportPageRegistrationDocumentId) {
      this.downloadRegistrationDocumentAsync(
        this.passportPageRegistrationDocumentId,
      );
    }
  },
  methods: {
    selectedMainDocumentId(file) {
      this.filenamePassportUserIdentity = file;
    },
    selectedRegistrationDocumentId(file) {
      this.filenamePassportUserRegistration = file;
    },
    putUsersMePassportsAsync() {
      const { filenamePassportUserIdentity, filenamePassportUserRegistration } =
        this;
      const queryWithFiles = {
        filenamePassportUserIdentity,
        filenamePassportUserRegistration,
      };
      // Our server cannot handle "null" as strings so we should filter them out
      const cleanedQuery = filter(queryWithFiles, isNotNull);
      this.loadingPutUsersMePassport = true;
      return this.server.putUsersMePassport
        .send(cleanedQuery)
        .pipe(this.onPutUsersMePassportsAsyncSuccess)
        .exec();
    },
    onPutUsersMePassportsAsyncSuccess() {
      this.loadingPutUsersMePassport = false;
      this.getUsersMeAsync();
    },
    getUsersMeAsync() {
      return this.server.getUsersMe
        .send()
        .pipe(this.onGetUsersMeAsyncSuccess.bind(this))
        .exec();
    },
    onGetUsersMeAsyncSuccess({ data: user }) {
      useStateStore().merge({ user });

      this.$emit('success');
    },
    downloadIdentityDocumentAsync(id) {
      const params = {
        id,
      };
      return this.server.getDocument
        .send(null, { params })
        .pipe(this.onDownloadIdentityDocumentAsyncSuccess)
        .exec();
    },
    onDownloadIdentityDocumentAsyncSuccess({ data }) {
      this.urlPassportUserIdentity = URL.createObjectURL(data);
    },
    downloadRegistrationDocumentAsync(id) {
      const params = {
        id,
      };
      return this.server.getDocument
        .send(null, { params })
        .pipe(this.onDownloadRegistrationDocumentAsyncSuccess)
        .exec();
    },
    onDownloadRegistrationDocumentAsyncSuccess({ data }) {
      this.urlPassportUserRegistration = URL.createObjectURL(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.img-inputer-wrapper-body {
  display: flex;
  @media (max-width: $size_767) {
    flex-direction: column;
    align-items: center;
  }
}
.img-inputer-item {
  &:first-child {
    margin-right: 20px;
  }
  @media (max-width: $size_767) {
    width: 100%;
    &:first-child {
      margin-right: 0;
    }
    &:last-child {
      margin-top: 20px;
    }
    margin-top: 0;
  }
}
.button-big {
  width: 209px;
  height: 50px;
  padding: 0 20px;
  font-size: 12px;
  line-height: 50px;
  display: block;
  margin: 40px auto 0;
}
.check-passport-title {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 16px;
}
.upload-passports-container {
  padding: 20px;
  background-color: $background;
  border-radius: 14px;
  @media (max-width: $size_767) {
    width: 100%;
    margin: 20px auto 0 auto;
  }
}
:deep(.img-inputer) {
  @media (max-width: $size_767) {
    height: 280px;
    width: 100%;
  }
}
</style>
