<template>
  <div class="profit block_content">
    <div class="item-header">доходность</div>
    <div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-grey"><i class="icon-bag"></i></div>
        <div>
          <div class="item-name">
            {{ data?.data?.portfolioWoDefaultAvg?.description }}
          </div>
          <div class="item-value">
            {{ formatCurrency(data?.data?.portfolioWoDefaultAvg?.value, 0) }} ₽
          </div>
        </div>
      </div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-grey"><i class="icon-cart"></i></div>
        <div>
          <div class="item-name">
            {{ data?.data?.assetsWoDefaultAvg?.description }}
          </div>
          <div class="item-value">
            {{ formatCurrency(data?.data?.assetsWoDefaultAvg?.value, 0) }} ₽
          </div>
        </div>
      </div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-grey"><i class="icon-bag"></i></div>
        <div>
          <div class="item-name">
            {{ data?.data?.profitability?.description }}
          </div>
          <div class="item-value">
            {{ formatCurrency(data?.data?.profitability?.value, 0) }} % годовых
          </div>
        </div>
      </div>
      <div class="item-row">
        <div class="tag-icon tag-icon-grey"><i class="icon-cart"></i></div>
        <div>
          <div class="item-name">
            {{ data?.data?.assetsProfitability?.description }}
          </div>
          <div class="item-value">
            {{ formatCurrency(data?.data?.assetsProfitability?.value, 0) }} %
            годовых
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { toRefs } from 'vue';
import { formatCurrency } from '@/utils/commonUtils/utils';

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});

const { data } = toRefs(props);
</script>
<style lang="scss" scoped>
.profit {
  @media (max-width: $size_767) {
    min-height: 460px;
  }
}
</style>
