<template>
  <div>
    <AnalyticsHistoryOperationFilter
      :date-since="filters.dateSince"
      :date-to="filters.dateTo"
      :type="filters.type"
      :investment-type="filters.investmentType"
      :loading="loadingItems"
      @change-date-range="changeDateRange"
      @change-type="changeType"
      @change-investment-type="changeInvestmentType"
      @get-account-statement="getAccountStatement"
      @reset-filters="runResetFilters"
    />

    <AnalyticsHistoryOperationTotal
      :date-since="filters.dateSince"
      :date-to="filters.dateTo"
      :fetch-data="fetchTotalData"
      :company-id="selectedCompany.id"
      :type="filters.type"
      :investment-type="filters.investmentType"
    />
    <div style="margin-top: 30px; text-transform: none">
      <div>
        <AnalyticsHistoryOperationDesktop
          v-if="!isMobile"
          :items="items"
          :loading-items="loadingItems"
          @getreceipt="getReceiptIdAsync"
        />
        <AnalyticsHistoryOperationMobile
          v-if="isMobile"
          :items="items"
          :loading-items="loadingItems"
          @getreceipt="getReceiptIdAsync"
        />

        <VueEternalLoading
          v-if="isEnableloadCompaniesAccountStatement"
          v-model:is-initial="isInitialAccountStatement"
          :load="loadCompaniesAccountStatementAsync"
        >
          <template #loading>
            <div class="block_content">
              <div class="project-item">
                <Loader />
              </div>
            </div>
          </template>

          <template #no-more>
            <div></div>
          </template>

          <template #no-results>
            <div class="projects-wrapper projects-row">
              <div></div>
            </div>
          </template>

          <template #error>
            <div class="projects-wrapper projects-row">
              <div class="project-item" style="padding-left: 50px">
                Oops. We've got an error.
              </div>
            </div>
          </template>
        </VueEternalLoading>
      </div>
    </div>
  </div>
</template>
<script setup>
/**
 *
 * На этой страницы у нас даты в запросе уходят в UTC, а в таблице отображаются в MSK
 *
 */
import { ref, computed, reactive, watch, nextTick } from 'vue';

import detectDevice from '@/composables/detectDeviceComposable';
import server from '@/server';

import { VueEternalLoading } from '@ts-pro/vue-eternal-loading';

import { useStateStore } from '@/store/stateStore';

import tracker from '@/tracker';

import Pagination from '@/utils/classes/Pagination';

import { Loader } from 'potok-uikit';
import AnalyticsHistoryOperationDesktop from '@/pages/Analytics/AnalyticsHistoryOperationContainers/AnalyticsHistoryOperationDesktop.vue';
import AnalyticsHistoryOperationMobile from '@/pages/Analytics/AnalyticsHistoryOperationContainers/AnalyticsHistoryOperationMobile.vue';
import AnalyticsHistoryOperationTotal from '@/components/Analytics/AnalyticsHistoryOperation/AnalyticsHistoryOperationTotal.vue';
import AnalyticsHistoryOperationFilter from '@/components/Analytics/AnalyticsHistoryOperation/AnalyticsHistoryOperationFilter.vue';

import { getDateSince, getDateTo } from '@/utils/date/getDateToSince';
import { add, differenceInYears, isBefore, sub } from 'date-fns';

const { isMobile } = detectDevice();
const store = useStateStore();

const loadingItems = ref(false);

const fetchTotalData = ref(false);
const items = ref([]);

const isInitialAccountStatement = ref(false);
const pagination = new Pagination(40);

// Filter

const watchInPause = ref(false);

const filters = reactive({
  dateSince: new Date(),
  dateTo: new Date(),
  type: 'all',
  investmentType: 'all',
});

watch(
  () => filters.type,
  (val) => {
    if (!val) {
      filters.type = {
        id: 'all',
        text: 'Все операции',
      };
    }
  },
);

watch(
  () => filters.investmentType,
  (val) => {
    if (!val) {
      filters.investmentType = {
        id: 'all',
        text: 'Все инвестиции',
      };
    }
  },
);

watch(
  () => filters.dateSince,
  () => {
    if (watchInPause.value) {
      return;
    }
    const dateSince = filters.dateSince;
    const dateTo = filters.dateTo;
    const compareDateSince = add(dateSince, { days: 1 });

    if ((differenceInYears(compareDateSince, dateTo) < 0) && !store.isAdmin) {
      store.pushAlert({
        message:
          'Период автоматически изменён. Вывод отчёта ограничен одним годом.',
        title: 'Внимание!',
      });
      watchInPause.value = true;
      filters.dateTo = sub(add(dateSince, { years: 1 }), { days: 1 });
      nextTick(() => {
        watchInPause.value = false;
      }, 200);
    }
    if (isBefore(dateTo, dateSince)) {
      watchInPause.value = true;
      filters.dateTo = dateSince;
      nextTick(() => {
        watchInPause.value = false;
      }, 200);
    }
  },
);

watch(
  () => filters.dateTo,
  () => {
    if (watchInPause.value) {
      return;
    }
    const dateSince = filters.dateSince;
    const dateTo = filters.dateTo;
    if ((differenceInYears(dateSince, dateTo) < 0) && !store.isAdmin) {
      store.pushAlert({
        message:
          'Период автоматически изменён. Вывод отчёта ограничен одним годом.',
        title: 'Внимание!',
      });
      watchInPause.value = true;
      filters.dateSince = sub(dateTo, { years: 1 });
      nextTick(() => {
        watchInPause.value = false;
      }, 200);
    }
    if (isBefore(dateTo, dateSince)) {
      watchInPause.value = true;
      filters.dateSince = dateTo;
      nextTick(() => {
        watchInPause.value = false;
      }, 200);
    }
  },
);

const changeDateRange = (dateRange) => {
  filters.dateSince = dateRange.dateSince;
  filters.dateTo = dateRange.dateTo;
};
const changeType = (type) => {
  filters.type = type;
};
const changeInvestmentType = (investmentType) => {
  filters.investmentType = investmentType;
};
const resetFilters = () => {
  filters.dateSince = new Date();
  filters.dateTo = new Date();
  filters.type = 'all';
  filters.investmentType = 'all';
};

// End filter

const selectedCompany = computed(() => {
  return store.selectedCompany;
});
const isEnableloadCompaniesAccountStatement = computed(() => {
  if (selectedCompany.value) {
    return true;
  }
  return false;
});

const getCompaniesAccountStatementAsync = (action) => {
  if (!action) {
    return;
  }

  fetchTotalData.value = true;

  let loaded = action.loaded;

  const { chunk: limit, offset } = pagination;

  const query = {
    dateSince: getDateSince(filters.dateSince),
    dateTo: getDateTo(filters.dateTo),
    accountType: 'investor',
    isVirtual: false,
    limit,
    offset,
    type: filters.type,
    investmentType: filters.investmentType,
    companyId: selectedCompany.value.id,
  };
  loadingItems.value = true;
  return server.getCompaniesAccountStatement
    .send(query)
    .pipe(
      onGetCompaniesAccountStatementAsyncSuccess.bind(this, {
        loaded,
        limit,
      }),
      () => {
        loadingItems.value = false;
        fetchTotalData.value = false;
      },
    )
    .exec();
};

const onGetCompaniesAccountStatementAsyncSuccess = (
  { loaded, limit },
  { data: _data },
) => {
  fetchTotalData.value = false;
  loadingItems.value = false;
  /**
   * фиксируем limit
   * раньше было this.pagination.offset += data.length
   */
  pagination.offset += limit;

  items.value.push(
    ..._data.map((item) => {
      item.isReceiptPrint = true;
      return item;
    }),
  );

  if (loaded) {
    /**
     * Входим в бесконечный цикл запросов пока бек не вернет пусто
     * из-за проблем с АПИ бека
     */
    if (_data.length > 0) {
      loaded();
    } else {
      loaded(_data.length, limit);
    }
  }
};
const loadCompaniesAccountStatementAsync = (action) => {
  getCompaniesAccountStatementAsync(action);
};
const reset = () => {
  tracker.queue(
    tracker.commands.SEND,
    'Investor_LK_Operations_History_Show_Results',
  );

  pagination.reset();
  items.value = [];
  isInitialAccountStatement.value = true;
};

const getReceiptIdAsync = (item) => {
  const query = {
    investmentId: item.investmentId,
    projectMessageId: item.projectMessageId,
  };

  return server.getReceiptByInvestmentId
    .send(query)
    .pipe(
      onGetReceiptIdAsyncSuccess.bind(this, item),
      onGetReceiptIdAsyncError.bind(this, item),
    )
    .exec();
};
const onGetReceiptIdAsyncSuccess = (item, { data: _data }) => {
  if (_data.status !== 'ACCEPT') {
    Object.assign(item, {
      isReceiptPrint: false,
    });
    return;
  }

  openNewWindow(`https://cheques-lk.orangedata.ru/9701046627/${_data.id}`);
};
const openNewWindow = (url) => {
  window.open(url, '_blank');
};
const onGetReceiptIdAsyncError = (item) => {
  Object.assign(item, {
    isReceiptPrint: false,
  });
};
const getAccountStatement = () => {
  reset();
  getCompaniesAccountStatementAsync();
};
const runResetFilters = () => {
  resetFilters();
  getAccountStatement();
};
</script>
<style lang="scss" scoped>
:deep(.v3dp__input_wrapper) {
  input {
    width: 100%;
    height: 44px;
    padding: 5px 10px;
    color: #313132;
    background: transparent;
    border: 1px solid #dedee1;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: inset 0 1px 1px #00000017;
  }
}
.filter-item {
  @media (max-width: $size_767) {
    margin: 14px 0;
  }
  @media (max-width: $size_991) {
    margin: 14px 0;
  }
}
.content_title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  margin-bottom: 10px;
}
.content_value {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: -0.02em;
}
.button-email {
  @media (max-width: $size_767) {
    margin-top: 30px;
    width: 100%;
  }
}
.block_send_to_email {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3px;
}
.button {
  @media (max-width: $size_767) {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
</style>
