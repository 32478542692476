<template>
  <div class="wrapper__content wrapper__content__has_background">
    <div class="wrapper__header">
      <div class="wrapper__header__left"></div>
      <header class="header header__container">
        <component :is="headerComponentName"></component>
      </header>
      <div class="wrapper__header__right"></div>
    </div>
    <main>
      <router-view name="content"></router-view>
    </main>
    <Footer />

    <tips-handler></tips-handler>

    <DebugTeleport v-if="isRunTeleport" />

    <UiNotification
      class="app-notification"
      :timer="notificationData.timer"
      :title="notificationData.title"
      :message="notificationData.message"
      :is-error="notificationData.isError"
    />
    <ModalRefferalMessage v-if="isShowModalRefferal" />
    <TelegramDialog
      v-if="
        !store.state.loaders.isUserLoading && store.state.user.isShowTgBanner
      "
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

import TipsHandler from '@/components/_generic/modals/TipsHandler.vue';
import ModalRefferalMessage from '@/components/_generic/modals/ModalRefferalMessage.vue';
import UiNotification from '@/components/_generic/notification/UiNotification.vue';

import Footer from '@/components/Footer/Footer.vue';
import HeaderUnauth from '@/components/Header/HeaderUnauth.vue';
import Header from '@/components/Header/Header.vue';
import DebugTeleport from '@/components/Teleport/Teleport.vue';

import { useStateStore } from '@/store/stateStore';
import TelegramDialog from '@/components/dialogs/TelegramDialog.vue';
import server from "@/server";

const store = useStateStore();
const isRunTeleport = ref(false);

const headerComponentName = computed(() => {
  return store.isUnauthenticated ? HeaderUnauth : Header;
});
const isShowModalRefferal = computed(() => {
  return store.state.modals.isShowModalRefferal;
});
const notificationData = computed(() => {
  return store.state.notification;
});

if (process.env.VUE_APP_NODE_ENV === 'mock') {
  isRunTeleport.value = true;
}

const getFeaturesAsync = () => {
  return server.getFeatures
    .send()
    .pipe(onGetFeaturesAsyncSuccess, () => { store.state.loaders.isFeaturesLoading = false; })
    .exec();
}

const onGetFeaturesAsyncSuccess = ({ data }) => {
  store.state.features.general = data
  store.state.loaders.isFeaturesLoading = false;
}

getFeaturesAsync();
</script>

<style lang="scss"></style>
