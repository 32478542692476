<template>
  <div class="notice block-container">
    <div class="text">
      {{ text }}
    </div>

    <div class="divider" />
    <div class="support-text color-grey">
      чаще всего деньги доходят в течение одного рабочего дня
    </div>
  </div>
</template>

<script setup>

import { computed } from "vue";
import { useStateStore } from '@/store/stateStore';
import constants from "@/constants";

const store = useStateStore();

const text = computed(() => {
  if (isItPersonCompany.value) {
    return 'вывести деньги можно только на свой личный счет в любом банке'
  } else {
    return `вывести деньги можно только на счет ${store.selectedCompany.shortName} в любом банке`
  }
})

const isItPersonCompany = computed(() => {
  return store.selectedCompany.typeId === constants.COMPANY_TYPES_ENTREPRENEUR;
})
</script>

<style scoped lang="scss">
.notice {
  height: fit-content;
}
.text {
  margin-bottom: 60px;
  text-transform: initial;

  @media (max-width: $size_767) {
    margin-bottom: 30px;
  }
}
.divider {
  width: 100%;
  height: 1px;
  background: $dividers;
  margin: 20px 0;
}
</style>