<template>
  <div class="refill-spb">
    <div class="block-left block_content">
      <div class="col-md-12">
        <div class="text-desc potok-text-h2">
          <div v-html="props.data?.text"></div>
        </div>
        <div class="form-wrapper">
          <div class="amount-wrapper">сумма пополнения</div>
          <div class="step-input-amount">
            <FormInputMoney
              v-model="amountValue"
              placeholder="0"
              :vmaska="masks.digits(7)"
              @input="props.onInput"
            />
            <div
              v-if="props.data?.min"
              class="potok-text-body-3 color-grey mt-4"
            >
              минимальная сумма пополнения:
              {{ formatCurrency(props.data?.min, 0) }}&nbsp;₽
            </div>
            <div v-if="props.data?.max" class="potok-text-body-3 color-grey">
              максимальная сумма пополнения:
              {{ formatCurrency(props.data?.max, 0) }}&nbsp;₽
            </div>
          </div>
          <div v-if="props.errorMessage" class="form-error">
            {{ props.errorMessage }}
          </div>
          <div>
            <button
              class="button step-balance-sbp-pay button-secondary-outline button-apply"
              type="button"
              :disabled="props.loading"
              @click="nextStep"
            >
              пополнить счет
            </button>
          </div>
        </div>
      </div>
    </div>
    <RefillBalanceSPBRightPanel />
  </div>
</template>
<script setup>
import { ref } from 'vue';

import masks from '@/utils/masks.js';
import FormInputMoney from '@/components/_generic/forms/FormInputMoney.vue';
import RefillBalanceSPBRightPanel from './RefillBalanceSPBRightPanel.vue';
import { formatCurrency } from '@/utils/commonUtils/libUtils';

const emit = defineEmits(['update:amountValue', 'nextStep']);
const props = defineProps({
  errorMessage: {
    type: String,
  },
  amountValue: {
    type: [Number, String],
  },
  onInput: {
    type: Function,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    required: false,
  },
});

const amountValue = ref(props.amountValue);

const nextStep = () => {
  emit('update:amountValue', parseInt(amountValue.value));
  emit('nextStep');
};
</script>
<style lang="scss" scoped>
.refill-spb {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.block-left {
  min-width: 750px;
  @media (max-width: $size_767) {
    min-width: initial;
  }
}
.block-right {
  width: 360px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: $size_767) {
    display: none;
  }
  @media (max-width: $size_1200) {
    margin-top: 20px;
  }
}

.step-balance-sbp-pay {
  height: 60px;
}
.text-desc {
  max-width: 522px;
}
.form-wrapper {
  max-width: 380px;
  @media (max-width: $size_767) {
    width: 100%;
  }
}

.amount-wrapper {
  margin-top: 26px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.step-input-amount {
  margin-top: 13px;
}

.button-apply {
  margin-top: 19px;
  margin-bottom: 138px;
  width: 100%;
  @media (max-width: $size_767) {
    margin-bottom: 0px;
  }
}
</style>
