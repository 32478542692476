<template>
  <div class="block_content" style="padding: 0px; overflow-x: auto">
    <table class="history-table">
      <tr>
        <th>дата</th>
        <th>тип</th>
        <th>приход</th>
        <th>расход</th>
        <th>номер договора</th>
        <th>комментарий</th>
        <th>наименование/фио контрагента</th>
        <th>тип инвестиции</th>
      </tr>
      <tr
        v-for="(item, index) in props.items.filter((i) => i !== 'unknown')"
        :key="index"
      >
        <td>
          <span style="white-space: nowrap">
            {{
              formatDate(item.createdAt, 'dd.MM.yyyy HH:mm', 'Europe/Moscow')
            }}
          </span>
        </td>
        <td style="min-width: 130px">
          <span>{{ item.title }}</span>
        </td>
        <td style="white-space: nowrap">
          <span v-show="getIncome(item)">
            {{ formatCurrency(getIncome(item), 2) }} ₽
          </span>
        </td>
        <td style="white-space: nowrap">
          <span v-show="getOutcome(item)" style="white-space: nowrap">
            {{ formatCurrency(getOutcome(item), 2) }} ₽
          </span>
        </td>
        <td>
          <span>{{ item.projectNumber }}</span>
        </td>
        <td>
          <span>{{ item.description }}</span>
          <div v-if="item.title === 'Расход по цессии' && showLinkByDate(item)">
            <div v-if="item.isReceiptPrint">
              <a @click.prevent="getReceiptIdAsync(item)">Скачать чек</a>
            </div>
            <div v-else>Чек еще не сформирован</div>
          </div>
        </td>
        <td>
          <template v-if="item.borrower">
            <span>{{ item.borrower }}</span>
          </template>
        </td>
        <td>
          <template
            v-if="thereIsNoInvestmentTypeInTheTransaction(item.isManual)"
            >-</template
          >
          <template v-else>
            <template v-if="item.isManual">Ручная</template>
            <template v-else>Авто</template>
          </template>
        </td>
      </tr>
    </table>
    <p
      v-if="!props.loadingItems && props.items.length === 0"
      style="margin: 20px 0; padding: 10px"
    >
      По заданым параметрам ничего не найдено
    </p>
  </div>
</template>
<script setup>
import { isAfter, parseISO } from 'date-fns';
import { formatCurrency, formatDate } from '@/utils/commonUtils/utils';

const props = defineProps(['items', 'loadingItems']);
const emit = defineEmits(['getreceipt']);

const thereIsNoInvestmentTypeInTheTransaction = (value) => {
  return value === undefined;
};
const getIncome = (item) => {
  return item.amount > 0 ? item.amount : 0;
};
const getOutcome = (item) => {
  return item.amount < 0 ? item.amount : 0;
};
const showLinkByDate = (item) => {
  return isAfter(parseISO(item.createdAt), new Date(2020, 7, 28));
};
const getReceiptIdAsync = (item) => {
  emit('getreceipt', item);
};
</script>
<style lang="scss" scoped>
.history-table {
  width: 100%;
  th {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $grey;
    padding: 8px 7px 7px 20px;
    border-bottom: 1px solid #ebedf4;
    background: rgba(232, 236, 240, 0.3);
    text-align: left;
    min-width: 100px;
    height: 40px;
  }
  td {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #313132;
    padding: 17px 17px 13px 20px;
  }
}
</style>
