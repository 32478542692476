import { extend, copyAsJson } from '@/utils/commonUtils/utils';
import storage from '@/utils/localStorage/storage';
import server from '@/server/index';
import { defaultInvestorPrefecences, defaultStore } from './default.js';

export default {
  initialStore() {
    const value = storage.getItem();
    if (value) {
      const valueParsed = JSON.parse(value);
      let stateLoadersValues = {};
      // Когда инициализируем стор, переключаем все лоадеры в состояние true
      try {
        stateLoadersValues = Object.keys(valueParsed.loaders);
      } catch (e) {
        stateLoadersValues = {};
      }
      for (const key of stateLoadersValues) {
        valueParsed.loaders[key] = true;
      }
      // Принудильно скрываем модалку успешной заявки выкупа, если вдруг юзер перезагрузил/закрыл страницу когда модалка была открыта, и закэшировалась в локал сторадже
      if (!valueParsed.modals) {
        valueParsed.modals = {};
      }
      valueParsed.modals.isShowNewSaleInvestmentsModal = false;
      // Очищаем result от кэшированных данных с локал стораджа
      valueParsed.result = {};
      this.state = JSON.parse(JSON.stringify(valueParsed));
    }
  },
  /**
   * Getting balance
   * possible options for companyId
   * - uuid company
   * - 'me'
   * - None (in this option getting all company plus 'me')
   * @companyId {*} companyId
   */
  fetchBalance(data) {
    const onFetchBalancesSuccess = ({ companyId }, obj) => {
      if (!obj || !obj.data) {
        return;
      }
      const result = copyAsJson(this.state.balances) || {};
      result[companyId] = obj.data;
      result[companyId].uploadTime = new Date().getTime();
      this.merge({
        balances: result,
      });
    };

    let companyId = data && data.companyId ? data.companyId : undefined;
    let companies = [];
    if (companyId) {
      companies = [{ id: companyId }];
    } else if (this.state.dictionaries.companies) {
      companies = copyAsJson(this.state.dictionaries.companies);
    }

    Promise.all(
      companies.map((company) => {
        if (company.id !== 'me') {
          return server.getCompanies$IdBalance
            .send(null, { params: { id: company.id } })
            .pipe(onFetchBalancesSuccess.bind(this, { companyId: company.id }))
            .exec();
        }
      }),
    );
  },
  async fetchActiveBalance(data) {
    const onFetchActiveBalanceAsyncSuccess = ({ companyId }, obj) => {
      if (!obj || !obj.data) {
        return;
      }
      if (obj.data) {
        const balances = copyAsJson(this.state.balances);
        const balance = balances[companyId] ? balances[companyId] : {};
        balance.investor = {
          ...balance.investor,
          ...obj.data,
        };
        balances[companyId] = balance;
        this.merge({
          balances: balances,
        });
      }
    };
    let companies = [];
    let companyId = data && data.companyId ? data.companyId : undefined;
    if (companyId) {
      companies.push({
        id: companyId,
      });
    } else {
      companies = this.state.dictionaries.companies;
    }

    Promise.all(
      companies.map((company) => {
        const pipeline = server.getActiveBalance.send(null, {
          params: { id: company.id },
        });
        return pipeline
          .pipe(
            onFetchActiveBalanceAsyncSuccess.bind(this, {
              companyId: company.id,
            }),
          )
          .exec();
      }),
    );
  },
  async fetchResult(data) {
    const onFetchResultSuccess = ({ companyId }, obj) => {
      if (!obj || !obj.data) {
        return;
      }

      let result = {};

      if (obj.data.error) {
        result = obj.data;
      } else {
        const modifiedProfitability = obj.data.profitability.map((el) => {
          const newElement = {
            data: el.data,
            periodLength: el.periodLength,
          };

          for (let key in el.data) {
            if (Object.prototype.hasOwnProperty.call(el.data[key], 'value')) {
              newElement.data[key] = el.data[key].value;
            } else {
              return el;
            }
          }

          return newElement;
        });

        const finalItem = obj.data;
        finalItem.profitability = modifiedProfitability;

        result = finalItem;
      }

      result.uploadTime = new Date().getTime();

      this.state.resultDate = obj.data.date;
      this.state.result[companyId] = result;
    };

    const companyId = data && data.companyId ? data.companyId : undefined;
    let companies = [];
    if (companyId) {
      companies = [{ id: companyId }];
    } else if (this.state.dictionaries.companies) {
      companies = copyAsJson(this.state.dictionaries.companies);
    }

    const isNewResultEnabled = data.isNewResultEnabled;
    let serverURl = server.getProfitabilities;
    if (this.isAdmin || isNewResultEnabled) {
      serverURl = server.getProfitabilitiesV2;
    }
    return Promise.all(
      companies.map((company) => {
        return serverURl
          .send(null, {
            params: { id: company.id },
          })
          .pipe(onFetchResultSuccess.bind(this, { companyId: company.id }))
          .exec();
      }),
    );
  },
  fetchCompanies(data) {
    const onGetCompaniesAsyncSuccess = (obj) => {
      if (!obj || !obj.data) {
        return;
      }
      let companies = copyAsJson(this.state.dictionaries.companies) || [];
      companies = obj.data;

      const modifiedCompanies = companies.map((el) => {
        if (el.autoinvestSettings?.strategy) {
          return el;
        } else {
          return {
            ...el,
            autoinvestSettings: {
              confirmedAt: '',
              settings: {},
              strategy: 'manual',
            },
          };
        }
      });

      this.merge({
        dictionaries: {
          companies: modifiedCompanies,
        },
      });
      if (data && data.callback) {
        data.callback();
      }
    };

    return server.getCompanies
      .send()
      .pipe(onGetCompaniesAsyncSuccess.bind(this))
      .exec();
  },
  merge(obj) {
    extend(this.state, obj);
  },
  set(obj) {
    Object.assign(this.state, obj);
  },
  logout() {
    const savedStoreAttributes = {
      banners: this.state.banners,
      preferences: this.state.preferences,
      session: {
        login: this.state.session.login,
        token: null,
      },
    };
    this.state = {
      ...JSON.parse(JSON.stringify(defaultStore)),
      ...savedStoreAttributes,
    };
  },
  setUserLoading(data) {
    this.state.loaders.isUserLoading = data;
  },
  setPlatformStatFutureLoading(data) {
    this.state.loaders.isPlatformStatFutureLoading = data;
  },
  setPulseProjectStatsLoading(data) {
    this.state.loaders.isPulseProjectStatsLoading = data;
  },
  pushMenuNotifications(data) {
    this.state.menuNotification.list = [];
    this.state.menuNotification.list.push(...data);
  },
  setNotification(data) {
    this.state.notification.message = data.message;
    this.state.notification.title = data.title;
    this.state.notification.timer = data.timer;
    this.state.notification.isError = data.isError;
  },
  pushAlert(data) {
    this.state.alert.message = data.message;
    this.state.alert.title = data.title;
    this.state.alert.action_text = data.action_text;
    if (data.callback) {
      this.state.alert.callback = data.callback;
    } else {
      this.state.alert.callback = null;
    }
  },
  removeAlert() {
    this.state.alert.message = null;
    this.state.alert.title = null;
    this.state.alert.callback = null;
  },
  pushTip(data) {
    this.state.tip.message = data.message;
    this.state.tip.title = data.title;
    this.state.tip.type = data.type;
    if (data.callback) {
      this.state.tip.callback = data.callback;
    } else {
      this.state.tip.callback = null;
    }
  },
  removeTip() {
    this.state.tip.message = null;
    this.state.tip.title = null;
    this.state.tip.callback = null;
    this.state.tip.type = null;
  },
  pushConfirm(data) {
    this.state.confirm.message = data.message;
    this.state.confirm.title = data.title;
    this.state.confirm.action_text = data.action_text;
    this.state.confirm.callback = data.callback;
  },
  removeConfirm() {
    this.state.confirm.message = null;
    this.state.confirm.title = null;
    this.state.confirm.callback = null;
  },
  toggleAutoInvestPreferences() {
    if (!this.state.preferences.invertorPage) {
      this.state.preferences.invertorPage = JSON.parse(
        JSON.stringify(defaultInvestorPrefecences),
      );
    }
    this.state.preferences.invertorPage.isShowAutoinvest =
      !this.state.preferences.invertorPage.isShowAutoinvest;
  },
  toggleShowResultPreferences() {
    if (!this.state.preferences.invertorPage) {
      this.state.preferences.invertorPage = JSON.parse(
        JSON.stringify(defaultInvestorPrefecences),
      );
    }
    this.state.preferences.invertorPage.isShowResult =
      !this.state.preferences.invertorPage.isShowResult;
  },
  setInvestorFilterPreferences(data) {
    this.state.preferences.invertorPage.filters = data;
  },
  setInvestorSortingPrefecences(data) {
    if (!this.state.preferences) {
      this.state.preferences = {};
    }
    if (!this.state.preferences.invertorPage) {
      this.state.preferences.invertorPage = {};
    }
    this.state.preferences.invertorPage.sortingOptions = data;
  },
  setCashboxLimitAmount(value) {
    const { companies } = this.state.dictionaries;
    const { companyId } = this.state.preferences;
    const selectedCompany = companies.find((item) => item.id === companyId);
    selectedCompany.cashboxLimitAmount = value;

    if (
      this.balances &&
      this.balances[companyId] &&
      this.balances[companyId].investor
    ) {
      this.balances[companyId].investor.cashboxLimitAmount = value;
    }
  },
  setUserIsSmsEnabled(value) {
    this.state.user.isSmsEnabled = value;
  },
  setUserIsEmailEnabled(value) {
    this.state.user.isEmailEnabled = value;
  },
  setUserIsTelegramEnabled(value) {
    this.state.user.isTelegramEnabled = value;
  },
  setUserIsOtpEnabled(value) {
    this.state.user.isOtpEnabled = value;
  },
  setUserEmail(value) {
    this.state.user.email = value;
  },
  setUserTelegram(value) {
    this.state.user.telegram = value;
    this.state.user.isShowTgBanner = false;
  },
  setUserOnboardingStatus(value) {
    this.state.user.onboardingStatus = value;
  },
  setUserPostalAddress(value) {
    this.state.user.postalAddress = value;
  },
  setSelectedCompany(value) {
    this.state.preferences.companyId = value;
  },
  setTourBannerCompleted(value) {
    this.state.user.isStartInvestPressed = value;
  },
  setTourCompleted(value) {
    this.state.user.isTourCompleted = value;
  },
  setAdminUnrecognizedAccountType(value) {
    if (!this.state.preferences.admin) {
      this.state.preferences.admin = {};
    }
    if (!this.state.preferences.admin.unrecognized) {
      this.state.preferences.admin.unrecognized = {};
    }
    this.state.preferences.admin.unrecognized.accountTypeId = value.id;
  },
  setErrorRequest(value) {
    this.state.error = value;
  },
};
