<template>
  <div v-if="isUserLoaded" class="dashboard-statuses mt-3">
    <div v-if="isPasswordPending" class="status_panel status_check">
      <div class="status_message">идет проверка данных</div>
    </div>
    <div v-if="isPasswordRejected" class="status_panel status_check">
      <div class="status_message">исправьте ошибки</div>
    </div>
    <div v-if="isPassportEmpty" class="status_panel status_check">
      <div class="status_message">требуется загрузить паспорт</div>
    </div>
    <div
      v-if="isInvestorQualification"
      class="status_panel status_qualification mr-2"
    >
      <i class="status-qualification-icon mr-1" />
      квалифицированный
    </div>

    <DashboardStatusesBonusBtn
      v-if="activeBalance && isStatusBonusActive && !store.state.loaders.isFeaturesLoading"
      :status="activeBalance.status"
      @click="handleBonusModal(true)"
    />

    <DashboardStatusesBonusModal
      v-if="isShowBonusModal"
      :status="activeBalance.status"
      @close="handleBonusModal(false)"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStateStore } from '@/store/stateStore';

import constants from '@/constants';
import DashboardStatusesBonusBtn from '@/components/Dashboard/DashboardTitle/DashboardStatuses/DashboardStatusesBonusBtn.vue';
import DashboardStatusesBonusModal from '@/components/Dashboard/DashboardTitle/DashboardStatuses/DashboardStatusesBonusModal.vue';
import server from "@/server";

const store = useStateStore();

const isShowBonusModal = ref(false);

const isStatusBonusActive = computed(() => {
  return store.state.features.general.statusBonus.enabled;
})
const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});
const activeBalance = computed(() => {
  return store.getActiveBalanceFull;
});
const isPasswordPending = computed(() => {
  return (
    store.state.user.passportProcessingStatus ===
    constants.passportStatus.PENDING
  );
});
const isPasswordRejected = computed(() => {
  return (
    store.state.user.passportProcessingStatus ===
    constants.passportStatus.REJECTED
  );
});
const isPassportEmpty = computed(() => {
  return (
    store.state.user.passportProcessingStatus === constants.passportStatus.EMPTY
  );
});
const isInvestorQualification = computed(() => {
  return (
    selectedCompany.value &&
    selectedCompany.value.typeId === constants.companyTypes.person &&
    selectedCompany.value.qiStatus === constants.qiStatus.APPROVED
  );
});

const handleBonusModal = (value) => {
  isShowBonusModal.value = value;
};
</script>
<style lang="scss" scoped>
.dashboard-statuses {
  display: flex;
  align-items: center;
}
.status_panel {
  box-sizing: border-box;
  border-radius: 6px;
  display: inline-block;
  padding: 3px 10px 2px 10px;
  margin-right: 5px;
  height: 24px;
}
.status_message {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.status_check {
  border: 1px solid #ffc11e;
  color: #ffc11e;
}

.status_qualification {
  border: 1px solid $breakwater;
  color: $breakwater;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.status-qualification-icon {
  position: relative;
  bottom: 1px;
  display: block;
  height: 12px;
  width: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  background: url('@/assets/img/icons/dashboard/startwithtick.svg');
}
</style>
