<template>
  <div class="onboard_wrapper">
    <div class="row">
      <div class="col-md-8 col-sm-8">
        <div class="block_content">
          <DashboardOnboardPerson
            v-if="props.currentStep === 'passport'"
            @update-step="updateStep"
          />
          <DashboardOnboardPending
            v-if="props.currentStep === 'recognize'"
            @update-step="updateStep"
          />
          <DashboardOnboardRules
            v-if="currentStep === 'profile'"
            :profile-data="props.profileData"
            @update-step="updateStep"
          />
          <DashboardOnboardStrategy
            v-if="props.currentStep === 'strategy'"
            @update-step="updateStep"
          />
          <DashboardOnboardRefill
            v-if="props.currentStep === 'balance'"
            @update-step="updateStep"
          />
        </div>
      </div>

      <div class="col-md-4 col-sm-4">
        <WizardDesktop
          :current-step-status="props.currentStep"
          :steps="props.steps"
          @update-step="updateStep"
        />
        <DashboardOnboardBonusBanner
          v-if="isNotAlfaCustomer && isNotFirstIncome"
          class="mt-6"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import DashboardOnboardPerson from '../DashboardOnboardPerson.vue';
import DashboardOnboardPending from '../DashboardOnboardPending.vue';
import DashboardOnboardRules from '../DashboardOnboardRules.vue';
import DashboardOnboardStrategy from '@/components/Dashboard/DashboardOnboard/DashboardOnboardStrategy.vue';
import DashboardOnboardRefill from '../DashboardOnboardRefill.vue';
import DashboardOnboardBonusBanner from '@/components/Dashboard/DashboardOnboard/banners/DashboardOnboardBonusBanner.vue';
import WizardDesktop from '@/components/_generic/stepWizard/WizardDesktop.vue';

import { useStateStore } from '@/store/stateStore';

import { computed } from 'vue';

const emit = defineEmits(['updateStep']);
const props = defineProps({
  currentStep: {
    type: String,
    required: true,
  },
  steps: {
    type: Array,
  },
  profileData: {
    type: Object,
  },
});

const store = useStateStore();

const isNotAlfaCustomer = computed(() => {
  return !store.isAlfaCustomer;
});
const isNotFirstIncome = computed(() => {
  return !store.isFirstIncomeAt;
});

const updateStep = (obj) => {
  emit('updateStep', obj);
};
</script>
<style lang="scss" scoped>
.onboard_wrapper {
  margin-top: 30px;
}
</style>
