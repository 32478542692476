<template>
  <div class="new-investments-sell-item-table-container mt-6">
    <template v-if="isLoading">
      <Loader class="mt-6" />
    </template>

    <template v-if="!isLoading">
      <table class="new-investments-sell-item-table potok-text-body-2 mt-6">
        <tr>
          <th>инвестиция</th>
          <th>окончание</th>
          <th>ОД при создании</th>
          <th>ОД при выкупе</th>
          <th>цена сделки</th>
          <th>статус</th>
        </tr>
        <tr
          v-for="(item, index) in props.items"
          :key="index"
          class="new-investments-sell-item-table-row"
        >
          <!-- инвестиция -->
          <td>
            <div class="sell-item-table-investment">
              <div class="sell-item-table-investment-name">
                <router-link
                  :to="`/loans/${item.investmentProjectId}`"
                  class="sell-item-table-link"
                >
                  {{ item.borrowerCompanyShortName }}
                </router-link>
              </div>
              <div class="sell-item-table-investment-info mt-1">
                инв.
                <router-link
                  v-if="!isSecondMarket"
                  :to="`/investment/${item.investmentId}`"
                  class="sell-item-table-link"
                >
                  {{ item.investmentNumber }}
                </router-link>
                <span v-if="isSecondMarket">
                  {{ item.investmentNumber }}
                </span>
                в заем
                <router-link
                  :to="`/loans/${item.investmentProjectId}`"
                  class="sell-item-table-link"
                >
                  {{ item.projectNumber }}
                </router-link>
              </div>
            </div>
          </td>
          <!-- окончание -->
          <td style="max-width: 70px">
            <div>
              {{
                formatDate(item.createdAt, 'dd.MM.yyyy HH:mm', 'Europe/Moscow')
              }}
            </div>
          </td>
          <!-- ОД на момент создания -->
          <td style="white-space: nowrap">
            <span style="white-space: nowrap">
              {{ formatCurrency(item.investmentBalanceOfMainDebt, 0) }} ₽
            </span>
          </td>
          <!-- ОД на момент выкупа -->
          <td>
            <span v-if="item.investmentBalanceOfMainDebtAtCompleted">
              {{
                formatCurrency(item.investmentBalanceOfMainDebtAtCompleted, 0)
              }}
              ₽
            </span>
            <template v-else>
              <span>Нет данных</span>
            </template>
          </td>
          <!-- Цена сделки -->
          <td>
            <span v-if="item.investmentSoldBy">
              {{ formatCurrency(item.investmentSoldBy, 0) }} ₽
            </span>
            <template v-else>
              <span>Нет данных</span>
            </template>
          </td>
          <!-- Статус -->
          <td>
            <div>
              <NewInvestmentsSellItemBtnStatus
                :status="item.status"
                :comment="item.comment"
              />
            </div>
          </td>
        </tr>
      </table>
    </template>
  </div>

  <PaginationDesktop
    v-if="!isLoading && items.length"
    v-model:current-page="currentPage"
    v-model:selected-page-range="selectedPageRange"
    :total-items-count="itemsCount"
  />
</template>

<script setup>
import { ref, watch } from 'vue';
import { Loader } from 'potok-uikit';
import PaginationDesktop from '@/components/_generic/pagination/paginationDesktop.vue';
import { formatDate, formatCurrency } from '@/utils/commonUtils/libUtils';
import NewInvestmentsSellItemBtnStatus from '@/components/Investments/NewInvestmentsSellItem/NewInvestmentsSellItemBtnStatus.vue';

const props = defineProps([
  'items',
  'isLoading',
  'itemsCount',
  'isSecondMarket',
]);
const emits = defineEmits(['fetchList']);

const selectedPageRange = ref(20);
const currentPage = ref(1);

const fetchList = () => {
  emits('fetchList', {
    selectedPageRange: selectedPageRange.value,
    currentPage: currentPage.value,
  });
};

watch(currentPage, fetchList);
watch(selectedPageRange, fetchList);
</script>

<style lang="scss" scoped>
.new-investments-sell-item-table {
  width: 100%;
  background-color: $white;
  border-radius: 14px;
  th {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $grey;
    padding: 8px 7px 7px 20px;
    background: rgba(232, 236, 240, 0.3);
    text-align: left;
    min-width: 100px;
    height: 40px;
  }
  td {
    color: $deep_dive;
    padding: 17px 17px 13px 20px;
  }
  tr {
    border-bottom: 1px solid $dividers;
    &:first-child {
      border-bottom: none;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.sell-item-table-investment-name {
  text-transform: none;
  color: $deep_dive;
}
.sell-item-table-investment-info {
  color: $grey;
  span {
    color: $deep_dive;
  }
}
.sell-item-table-link {
  color: $deep_dive;
  text-decoration: none;
  &:hover {
    color: $tiffany;
  }
}
</style>
