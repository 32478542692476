<template>
  <div v-if="isShow" class="ui-notification" :class="{ error: props.isError }">
    <span v-if="props.title" class="ui-notification-title">
      {{ props.title }}
    </span>
    <span class="ui-notification-message" v-html="props.message" />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();

const props = defineProps({
  timer: {
    type: Number,
    default: 5000,
  },
  title: {
    type: String,
    required: false,
  },
  message: {
    type: String,
    required: true,
  },
  isError: {
    type: Boolean,
    default: false,
  },
});

const isShow = ref(false);

const closeHandler = () => {
  setTimeout(() => {
    store.setNotification({
      timer: 0,
      message: '',
      title: '',
      isError: false,
    });
    isShow.value = false;
  }, props.timer);
};

watch(
  () => {
    return props.message;
  },
  () => {
    isShow.value = true;
    closeHandler();
  },
);
</script>

<style scoped lang="scss">
.ui-notification {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  border-radius: 8px;
  border-left: 3px solid $tiffany;
  padding: 20px 24px;
  background: #fff;
  z-index: 100;
  box-shadow: 0 16px 64px 0 rgba(183, 203, 228, 0.5);
}
.error {
  border-left: 3px solid $red;
}
.ui-notification-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 26px;
}
.ui-notification-message {
  font-size: 16px;
  line-height: 24px;
  text-transform: initial;
}
</style>
