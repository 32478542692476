<template>
  <div class="container content_wrapper">
    <div class="login_title">
      <div>регистрация</div>
    </div>
    <div class="login_subtitle">
      <div class="login_subtitle_restricted">
        для подтверждения мы отправили код на номер
        <span>{{ decorateFormatPhone(props.rawPhone) }}</span>
      </div>
    </div>
    <div class="login_actions">
      <div class="login_input_wrapper">
        <input
          id="idSMSConfirm"
          v-model="localRawCode"
          class="form-control"
          type="text"
          :readonly="loading"
          placeholder="код из смс"
          @input="onClickPressKeyCode()"
          @keyup.enter="onClickEnterSMSConfirm()"
        />
        <div v-if="errorMessageSMSCode" class="form-error error_message">
          {{ errorMessageSMSCode }}
        </div>
      </div>
      <button
        type="button"
        class="button button-breakwater button-continue"
        :disabled="loading"
        @click="onClickEnterSMSConfirm()"
      >
        зарегистрироваться
      </button>
      <template v-if="leftTime > 0">
        <div class="countdown potok-text-body-1 color-deepdive">
          <div class="countdown_span">
            отправить ещё через
            <span>{{ leftTime }} c</span>
          </div>
        </div>
      </template>
      <template v-if="leftTime === 0">
        <button
          type="button"
          class="button button-text button-repeat"
          :disabled="loading"
          @click="onClickRequestRetryCode()"
        >
          выслать СМС повторно
        </button>
      </template>

      <button
        type="button"
        class="button button-text button-back"
        @click="onClickBack()"
      >
        назад
      </button>
    </div>
    <div class="potok-text-body-3 footer_message">
      нажимая «Продолжить», я соглашаюсь на обработку персональных данных в
      соответствии с
      <a
        href="https://files.potok.digital/Документы/раздел правила платформы/Politika_obrabotka_pers_dannyh_aktualnaja_redakcija.docx"
        target="_blank"
        >политикой</a
      >, ознакомлен с
      <a
        href="https://files.potok.digital/Документы/раздел правила платформы/Правила Инвестиционной платформы Поток-1.pdf"
        target="_blank"
        >Правилами платформы</a
      >, и согласен с использованием
      <a
        href="https://files.potok.digital/Документы/раздел правила платформы/Соглашение участников электронного взаимодействия_Инвестплатформа Поток.pdf "
        target="_blank"
      >
        простой электронной подписи
      </a>
    </div>
  </div>
  <ModalFull
    v-if="errorMessage"
    :title="'внимание'"
    class="error_message"
    @close="onCloseErrorHandler"
  >
    <template #content>
      <div class="modal-text" v-html="errorMessage"></div>
    </template>
    <template #actions>
      <div class="button-center">
        <button
          type="button"
          class="button button-secondary button_ok"
          @click.stop="onCloseErrorHandler"
        >
          {{ 'понятно' }}
        </button>
      </div>
    </template>
  </ModalFull>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import server from '@/server';
import tracker from '@/tracker';
import {
  convertRawPhoneToLogin,
  decorateFormatPhone,
} from '@/utils/phone/formatPhone';
import countdownComposable from '@/composables/countdownComposable';
import { useStateStore } from '@/store/stateStore';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

const props = defineProps(['rawPhone', 'rawPassword', 'rawEmail', 'smsId']);
const emit = defineEmits([
  'changeStep',
  'changeEmail',
  'changeSmsId',
  'changePassword',
]);

const { leftTime, countdownReset, countdown } = countdownComposable();

const localRawCode = ref(null);
const errorMessageSMSCode = ref(null);
const loading = ref(null);
const errorMessage = ref(null);

const store = useStateStore();
const route = useRoute();
const router = useRouter();

const isValidSmsCode = () => {
  if (!localRawCode.value) {
    errorMessageSMSCode.value = 'Обязательное поле';
    return false;
  }
  return true;
};

const onClickPressKeyCode = () => {
  errorMessageSMSCode.value = null;
};

const onClickEnterSMSConfirm = () => {
  if (loading.value || !isValidSmsCode()) {
    return;
  }
  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
    smsCode: localRawCode.value,
    smsId: props.smsId,
  };
  if (route.query.ref) {
    query.refCode = route.query.ref;
  }
  loading.value = true;
  return server.postUsersRegisterConfirm
    .send(query)
    .pipe(
      onConfirmRegisterAsyncSuccess.bind(this, query),
      onConfirmRegisterAsyncError.bind(this),
    )
    .exec();
};
const onConfirmRegisterAsyncSuccess = (query, { data }) => {
  loading.value = false;
  tracker.queue(tracker.commands.SEND, 'Investor_LK_Insert_Code');

  if (data.isSuccessful) {
    tracker.queue(tracker.commands.SEND, 'Investor_LK_Insert_Code_Success');
    tracker.queue(
      tracker.commands.SEND,
      'Adg_Investory_Shag5_Registracija_podtverdil_po_sms',
    );
    countdown.stop();
    store.merge({
      session: {
        login: query.phone,
        token: data.token,
      },
    });
    router.push({
      name: 'main',
    });
  }
};

const onClickRequestRetryCode = () => {
  loading.value = true;
  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
  };
  return server.postUsersRegisterRequestRetry
    .send(query)
    .pipe(
      onRequestRegisterAsyncSuccess.bind(this, query),
      onConfirmRegisterAsyncError.bind(this),
    )
    .exec();
};

const onRequestRegisterAsyncSuccess = (query, { data }) => {
  countdownReset();
  loading.value = false;

  if (data.smsId) {
    emit('changeSmsId', data.smsId);
  }
};

const onClickBack = () => {
  if (props.rawPhone && props.rawPassword && props.rawEmail) {
    emit('changeStep', 'inputEmail');
  } else {
    emit('changeStep', 'inputPhone');
  }
};

const onCloseErrorHandler = () => {
  errorMessage.value = null;
  emit('changeStep', 'inputPhone');
};

const onConfirmRegisterAsyncError = ({ data }) => {
  loading.value = false;
  if (data?.type === 'business_logic_error' && data?.message) {
    errorMessage.value = data.message;
  }
};

onMounted(() => {
  countdownReset();
});
</script>
<style lang="scss" scoped>
.content_wrapper {
  min-height: 500px;
}
.login_actions {
  margin-bottom: 50px;
}
.footer_message {
  padding: 0 10px;
}
</style>
