<template>
  <section class="potok-message">
    <div style="padding: 0">
      <div class="alert content-wrapper" :class="`alert-${props.type}`">
        <i class="alert-icon" :class="`icon_alert_${props.type}`" style="margin-right: 7px"></i>
        <div v-if="props.message" v-html="props.message"></div>
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script setup>
const props = defineProps({
  message: {},
  type: {
    default: 'info',
    validator(value) {
      return ['info', 'warning', 'marketing'].includes(value);
    },
  },
});
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  align-items: center;
  margin: 30px 0px 15px 0px;
  background-color: #10273c0f;
  border-radius: 8px;
  padding: 18px 20px;
  min-height: 60px;
}
.alert-icon {
  @media (max-width: $size_767) {
    display: none;
  }
}
.alert-info {
  border-left: 2px solid #173b57;
}
.alert-marketing {
  border-left: 2px solid #04CCD9;
  background-color: #fff;
}
.alert-warning {
  border-left: 2px solid #FF5754;
  background-color: #F3E4E8;

  a {
    color: #FF5754;
  }
  :deep(a) {
    color: #FF5754;
  }
}

.potok-message {
  margin: 0;
  padding: 0;
  position: relative;
  text-transform: none;
  .alert {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #10273d;
    padding: 12px 20px 12px 17px;

    margin-bottom: 0;
    @media (max-width: $size_767) {
      margin-bottom: 10px;
    }

    @media (max-width: $size_767) {
      .content-block {
        margin: 30px 0 0 0;
      }
      .button {
        padding: 5px 24px;
      }
    }
  }
  :deep(.alert-title) {
    font-size: 17px;
    font-weight: 700;
    color: black;
  }
}
</style>
