<template>
  <div class="new-investments-sell-list-table-container mt-6">
    <div class="new-investments-table-head">
      <div class="new-investments-sell-list-select-status">
        <CSelect v-model="status" :items="statusList" />
      </div>

      <InvestmentsSellListAcceptBtn
        v-if="store.isSecondaryMarketOperator"
        :items-active="props.itemsActiveCount"
        @click="isShowAcceptModal = true"
      />
    </div>

    <template v-if="isLoading">
      <Loader class="mt-6" />
    </template>

    <template v-if="!isLoading && props.items.length > 0">
      <table class="new-investments-sell-list-table potok-text-body-2 mt-6">
        <tr>
          <th>id заявки</th>
          <th>дата создания</th>
          <th>срок действия</th>
          <th>ОД при создании</th>
          <th>лимит</th>
          <th>ОД при выкупе</th>
          <th>дисконт</th>
          <th>сумма выкупа</th>
          <th>статус</th>
        </tr>
        <tr
          v-for="(item, index) in props.items"
          :key="index"
          class="new-investments-sell-list-table-row"
          @click="toSellListItem(item.id)"
        >
          <td>
            <span>{{ item.number }}</span>
          </td>
          <td style="max-width: 70px">
            <div>
              {{
                formatDate(item.createdAt, 'dd.MM.yyyy HH:mm', 'Europe/Moscow')
              }}
            </div>
          </td>
          <td style="max-width: 70px">
            <div>
              {{
                formatDate(
                  item.requestDueAt,
                  'dd.MM.yyyy HH:mm',
                  'Europe/Moscow',
                )
              }}
            </div>
          </td>
          <td style="white-space: nowrap">
            <span style="white-space: nowrap">
              {{ formatCurrency(item.mainDebtAmount, 0) }} ₽
            </span>
          </td>
          <td>
            <template v-if="item.limitAmount">
              <span>{{ formatCurrency(item.limitAmount, 0) }} ₽</span>
            </template>
            <template v-else>
              <span>Нет данных</span>
            </template>
          </td>
          <td>
            <span v-if="item.mainDebtAmountAtCompleted">
              {{ formatCurrency(item.mainDebtAmountAtCompleted, 0) }} ₽
            </span>
            <template v-else>
              <span>Нет данных</span>
            </template>
          </td>
          <td>
            <span>{{ item.discountPercentage }}%</span>
          </td>
          <td>
            <span v-if="item.soldByAmount">
              {{ formatCurrency(item.soldByAmount, 0) }} ₽
            </span>
            <template v-else>
              <span>Нет данных</span>
            </template>
          </td>
          <td>
            <div>
              <NewInvestmentsSellListBtnStatus
                :status="item.status"
                :comment="item.comment"
              />
            </div>
          </td>
        </tr>
      </table>
    </template>
    <template v-if="!isLoading && props.items.length === 0">
      <div class="block_content">
        <PotokImg
          :src="imageConstants.menuBoard"
          class="mb-6"
          height="60"
          width="60"
        />
        <div class="potok-text-body-1-bold color-grey">
          у вас нет заявок с таким статусом
        </div>
      </div>
    </template>
  </div>

  <PaginationDesktop
    v-if="!isLoading && items.length"
    v-model:current-page="currentPage"
    v-model:selected-page-range="selectedPageRange"
    :total-items-count="itemsCount"
  />
  <InvestmentsSellListAcceptModal
    v-if="isShowAcceptModal"
    @close="isShowAcceptModal = false"
  />
</template>

<script setup>
import { ref, watch } from 'vue';
import { Loader } from 'potok-uikit';
import CSelect from '@/components/_generic/cselect/CSelect.vue';
import PaginationDesktop from '@/components/_generic/pagination/paginationDesktop.vue';
import { formatDate, formatCurrency } from '@/utils/commonUtils/libUtils';
import NewInvestmentsSellListBtnStatus from '@/components/Investments/NewInvestmentsSellList/NewInvestmentsSellListBtnStatus.vue';
import { useRouter } from 'vue-router';
import imageConstants from '@/constants/imageConstants';
import InvestmentsSellListAcceptBtn from '@/components/Investments/NewInvestmentsSellList/InvestmentsSellListAcceptBtn.vue';
import { useStateStore } from '@/store/stateStore';
import InvestmentsSellListAcceptModal from '@/components/Investments/NewInvestmentsSellList/InvestmentsSellListAcceptModal.vue';

const props = defineProps([
  'items',
  'isLoading',
  'itemsCount',
  'itemsActiveCount',
]);
const emits = defineEmits(['fetchList']);

const router = useRouter();
const store = useStateStore();

const selectedPageRange = ref(20);
const currentPage = ref(1);

const isShowAcceptModal = ref(false);

const status = ref('all');
const statusList = [
  {
    value: 'all',
    title: 'все заявки',
  },
  {
    value: 'confirmed',
    title: 'Активна',
  },
  {
    value: 'approved',
    title: 'Обрабатывается',
  },
  {
    value: 'canceled',
    title: 'Отменена',
  },
  {
    value: 'rejected',
    title: 'Отклонена',
  },
  {
    value: 'completed',
    title: 'Реализована',
  },
];

const fetchList = () => {
  emits('fetchList', {
    status: status.value,
    selectedPageRange: selectedPageRange.value,
    currentPage: currentPage.value,
  });
};

const toSellListItem = (id) => {
  router.push({
    name: 'sellListItem',
    params: { id },
  });
};

watch(status, fetchList);
watch(currentPage, fetchList);
watch(selectedPageRange, fetchList);
</script>

<style lang="scss" scoped>
.block_content {
  min-height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.new-investments-sell-list-select-status {
  width: 260px;
}
.new-investments-table-head {
  display: flex;
  justify-content: space-between;
}
.new-investments-sell-list-table {
  width: 100%;
  background-color: $white;
  border-radius: 14px;
  th {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $grey;
    padding: 8px 7px 7px 20px;
    background: rgba(232, 236, 240, 0.3);
    text-align: left;
    min-width: 95px;
    height: 40px;
  }
  td {
    color: $deep_dive;
    padding: 17px 17px 13px 20px;
  }
  tr {
    border-bottom: 1px solid rgb(242, 244, 248);
    &:first-child {
      border-bottom: none;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .new-investments-sell-list-table-row {
    cursor: pointer;
    &:hover {
      border-bottom: 1px solid rgb(242, 244, 248);
      background-color: rgb(242, 244, 248);
    }
  }
}
</style>
