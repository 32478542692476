<template>
  <div
    v-on-clickaway="onClickClose"
    class="select"
    :class="{
      'select-form-disabled': props.disabled,
    }"
  >
    <div class="select-form-wrapper">
      <button
        class="select-form-button select-form-button-left"
        @click="onClickPrevSelect"
      >
        <div class="icon-arrow-left"></div>
      </button>
      <div class="select-form" @click="onClickCSelect">
        <div class="select-selected-content">
          <div
            v-if="props.selectedIcon"
            class="icon-selected"
            :class="props.selectedIcon"
          ></div>
          <div>{{ selectedValueTitle }}</div>
        </div>
      </div>
      <button
        class="select-form-button select-form-button-right"
        @click="onClickNextSelect"
      >
        <div class="icon_arrow_long_right icon-arrow-right"></div>
      </button>
    </div>
    <div v-if="isShowSelectList" class="select-list">
      <div
        v-for="(item, index) in props.items"
        :key="index"
        class="select-list-item"
        :class="{
          'select-list-item-active':
            item.periodLength === props.modelValue.periodLength,
        }"
        @click="onClickSelectedValue(item.value)"
      >
        <div class="item" :title="item.title">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { directive as vOnClickaway } from 'vue3-click-away';
import { format } from 'date-fns';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  calendarMode: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Object,
    required: true,
  },
  selectedIcon: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['update:modelValue', 'openCalendar']);

const isShowSelectList = ref(false);
const selectedValue = ref(props.modelValue);

const selectedValueTitle = computed(() => {
  if (selectedValue.value.periodLength === 'custom') {
    return `${format(selectedValue.value.range[0], 'dd.MM.yyyy')} — ${format(selectedValue.value.range[1], 'dd.MM.yyyy')}`;
  }
  const localValue = props.items.find(
    (i) => i.periodLength === selectedValue.value.periodLength,
  );
  if (!localValue) {
    return;
  }
  return localValue.title;
});

const onClickCSelect = () => {
  if (props.disabled) {
    return;
  }
  if (props.calendarMode) {
    emit('openCalendar');
    return;
  }
  isShowSelectList.value = !isShowSelectList.value;
};

const onClickSelectedValue = (value) => {
  if (props.disabled) {
    return;
  }

  selectedValue.value = props.items.find((i) => i.value === value).value;
  emit('update:modelValue', selectedValue.value);
  isShowSelectList.value = false;
};

const onClickNextSelect = () => {
  let index = props.items.findIndex(
    (i) => i.periodLength === selectedValue.value.periodLength,
  );
  if (index > -1) {
    index = index + 1;
    if (index > props.items.length - 1) {
      index = 0;
    }
    selectedValue.value = props.items[index];
    emit('update:modelValue', selectedValue.value);
  }
};

const onClickPrevSelect = () => {
  let index = props.items.findIndex(
    (i) => i.periodLength === selectedValue.value.periodLength,
  );
  if (index > -1) {
    index = index - 1;
    if (index < 0) {
      index = props.items.length - 1;
    }
    selectedValue.value = props.items[index];
    emit('update:modelValue', selectedValue.value);
  }
};

watch(
  () => props.modelValue,
  () => {
    selectedValue.value = props.modelValue;
  },
);
const onClickClose = () => {
  isShowSelectList.value = false;
};
</script>
<style lang="scss" scoped>
.select {
  position: relative;
}
.select-form-wrapper {
  display: flex;
  align-items: center;
}
.select-form-button {
  background-color: $background;
  position: relative;
  cursor: pointer;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  @media (max-width: $size_767) {
    background-color: white;
  }
}
.select-form-button-left {
  border-radius: 8px 0px 0 8px;
  margin-right: 2px;
}
.select-form-button-right {
  border-radius: 0px 8px 8px 0px;
  margin-left: 2px;
}

.select-form {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background;
  position: relative;
  cursor: pointer;
  height: 34px;
  min-width: 150px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 22px;
  color: $deep_dive;
  @media (max-width: $size_767) {
    justify-content: center;
    width: 100%;
    background-color: white;
  }
}

.select-selected-content {
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.icon-selected {
  margin-right: 5px;
}
.select-form-disabled {
  opacity: 0.5;
}
.icon-arrow-left {
  background-image: url(@/assets/img/icons/arrow-left.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}
.icon-arrow-right {
  background-image: url(@/assets/img/icons/arrow-left.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}
.select-list {
  position: absolute;
  top: 40px;
  left: 0;
  min-height: 30px;
  min-width: 150px;
  background-color: white;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  border-radius: 12px;
  z-index: 100;
  padding: 6px;
}
.select-list-item {
  padding: 8px 6px;
  cursor: pointer;
  &:hover {
    background-color: $background;
    border-radius: 8px;
  }
}
</style>
