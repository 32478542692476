<template>
  <div class="dashboard_block_content" data-tg-id="4">
    <div
      style="
        font-weight: 500;
        font-size: 72px;
        line-height: 72px;
        color: #ffffff;
      "
    >
      {{ props.projectStats.fundsRaisingTotalCount }}
    </div>
    <p
      class="potok-text-body-1"
      style="color: #ffffff; margin-top: 15px; margin-bottom: 13px"
    >
      займов на сборе прямо сейчас
    </p>
    <router-link
      v-if="isUserRegistrated"
      class="router_link"
      :to="{
        name: 'loans',
        query: { selectAll: true },
      }"
    >
      <i class="icon_arrow_circle"></i>
    </router-link>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStateStore } from '@/store/stateStore';

const props = defineProps(['projectStats']);
const store = useStateStore();

const isUserRegistrated = computed(() => {
  return store.isUserRegistrated;
});
</script>
<style lang="scss" scoped>
.dashboard_block_content {
  padding: 30px 20px 20px 20px !important;
  position: relative;
  background-color: $breakwater !important;
  height: 100%;
  background-image: url('@img/icons/dashboard/back.png') !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position-x: right !important;
}
.icon_arrow_circle {
  position: absolute;
  left: 20px;
  bottom: 30px;
  background-image: url('@img/icons/dashboard/arrow_circle.svg');
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  &:hover {
    background-image: url('@img/icons/dashboard/arrow_circle_hover.svg');
  }
}
</style>
