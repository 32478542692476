<template>
  <div class="step_profile">
    <div class="step_title">присоединение к правилам</div>
    <template v-if="!store.isAlfaCustomer">
      <p
        class="step_description step_description__width_restricton"
        v-html="descText"
      />
    </template>
    <template v-if="store.isAlfaCustomer">
      <p class="step_description step_description__width_restricton">
        чтобы начать инвестировать, присоединитесь к
        <a
          href="https://files.potok.digital/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%20%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D1%8B/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%98%D0%BD%D0%B2%D0%B5%D1%81%D1%82%D0%B8%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%BE%D0%B9%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D1%8B%20%D0%9F%D0%BE%D1%82%D0%BE%D0%BA.pdf"
          target="_blank"
          class="link"
        >
          правилам платформы
        </a>
        , нажав на кнопку «продолжить»
      </p>
    </template>
    <form v-if="isShowForm" @submit.prevent="">
      <div>
        <div class="row row_item">
          <div class="col-md-4 col-sm-4 col-xs-12 item_block">
            <FormInputSuggestion
              v-model="form.surname"
              title="фамилия"
              :validate="v$.form.surname"
              :suggestion-field="suggestionField.surname"
              :disabled="isDisableField"
              @input="inputValue"
            >
            </FormInputSuggestion>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 item_block">
            <FormInputSuggestion
              v-model="form.name"
              title="имя"
              :validate="v$.form.name"
              :suggestion-field="suggestionField.name"
              :disabled="isDisableField"
              @input="inputValue"
            >
            </FormInputSuggestion>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 item_block">
            <FormInputSuggestion
              v-model="form.secondName"
              title="отчество"
              :validate="v$.form.secondName"
              :suggestion-field="suggestionField.secondName"
              :disabled="isDisableField"
              @input="inputValue"
            >
            </FormInputSuggestion>
          </div>
        </div>
        <div class="row row_item">
          <div class="col-md-4 col-sm-4 col-xs-12 item_block">
            <PotokInputSmall
              v-model="form.birthday"
              placeholder="дата рождения"
              :validate="v$.form.birthday"
              :dataMaska="localMasks.birthday"
              :disabled="isDisableField"
              @input="inputValue"
            >
              <template #inner-icon>
                <button
                  class="birthday-form-icon"
                  @click="isShowBirthdayDatepicker = true"
                />
              </template>
            </PotokInputSmall>

            <div
              v-if="isShowBirthdayDatepicker"
              v-on-clickaway="onClickaway"
              class="onboard-rules-datepicker-container"
            >
              <Datepicker
                v-model="form.birthday"
                inline
                auto-apply
                model-type="format"
                format="dd.MM.yyyy"
                text-input
                locale="ru"
                :start-date="birthdayDatepickerStartDate()"
                :format-locale="ru"
                :enable-time-picker="false"
                :disabled="isDisableField"
                @update:model-value="datepickerHandle"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-4"></div>
          <div class="col-md-4 col-sm-4"></div>
        </div>
        <div class="row row_item">
          <div class="col-md-4 col-sm-4 col-xs-12 item_block">
            <FormInput
              v-model="form.passportSeries"
              title="паспорт серия"
              :validate="v$.form.passportSeries"
              :vmaska="localMasks.passportSeries"
              :disabled="isDisableField"
              @input="inputValue"
            />
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 item_block">
            <FormInput
              v-model="form.passportNumber"
              title="номер паспорта"
              :validate="v$.form.passportNumber"
              :vmaska="localMasks.passportNumber"
              :disabled="isDisableField"
              @input="inputValue"
            />
          </div>
          <div class="col-md-4 col-sm-4"></div>
        </div>
        <div class="row row_item">
          <div class="col-md-4 col-sm-4 col-xs-12 item_block">
            <FormInput
              v-model="form.inn"
              title="инн"
              :validate="v$.form.inn"
              :vmaska="localMasks.inn"
              :disabled="isDisableFieldInn"
              @input="inputValue"
            >
              <template #label>
                <div class="inn_tips_wrapper">
                  <VDropdown placement="bottom-start">
                    <button class="info-icon">
                      <div class="icon_question_grey"></div>
                    </button>
                    <template #popper>
                      <div class="popup">
                        <div v-close-popper class="popup-close"></div>
                        <div class="popup-text">
                          Узнать свой ИНН можно по паспортным данным и дате
                          рождения на сайте
                          <a
                            href="https://service.nalog.ru/inn.do"
                            target="_blank"
                          >
                            nalog.ru </a
                          >. Обратите внимание, что ИНН может быть присвоен на
                          реквизиты вашего старого паспорта.
                        </div>
                      </div>
                    </template>
                  </VDropdown>
                </div>
              </template>
              <template #inner-icon>
                <VDropdown
                  :placement="isTablet ? 'top' : 'right'"
                  :triggers="['click']"
                  class="help-dropdown"
                >
                  <PotokImg
                    height="14"
                    width="14"
                    :src="imageConstants.attention"
                    class="mr-3"
                  />
                  <template #popper>
                    <div class="help-dropdown-text potok-text-body-2">
                      <div>
                        <p class="mb-0">
                          введите инн физического лица, состоит из 12 цифр
                        </p>
                        <p class="inn-dropdown-example mb-0">
                          пример: 482561319976
                        </p>
                      </div>
                    </div>
                  </template>
                </VDropdown>
              </template>
            </FormInput>
          </div>
          <div class="col-md-4 col-sm-4"></div>
          <div class="col-md-4 col-sm-4"></div>
        </div>
      </div>
    </form>
    <div class="form-error">{{ messageError }}</div>
    <div>
      <div class="devider devider__mb"></div>
      <div class="action_wrapper">
        <button
          class="button button-primary button-continue"
          type="button"
          :disabled="loading"
          @click="onClickContinue"
        >
          Продолжить
        </button>
        <PotokButton
          v-if="!store.isAlfaCustomer"
          text="Назад"
          theme="tertiary"
          size="large"
          :disabled="loading"
          @click="onClickBack"
        />
      </div>

      <DashboardOnboardRulesAlfa v-if="store.isAlfaCustomer" />
      <DashboardOnboardRulesDefault v-if="!store.isAlfaCustomer" />
    </div>

    <ModalConfirmSMS
      v-if="isShowModalConfirm"
      :loading="loading"
      :confirm-function="onClickProfileConfirm"
      :request="putOnboardProfile"
      :error-message="confirmErrorMessage"
      :confirm-message="confirmMessage"
      title="подписание документа"
      @close="isShowModalConfirm = false"
    />
  </div>
</template>
<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import ru from 'date-fns/locale/ru';
import { directive as vOnClickaway } from 'vue3-click-away';
import { ref, computed, onMounted } from 'vue';
import { parse, parseISO, format } from 'date-fns';

import server from '@/server';
import useVuelidate from '@vuelidate/core';
import { checkINN } from 'ru-validation-codes';
import validators from '@/validators';
import ModalConfirmSMS from '@/components/_generic/modals/ModalConfirmSMS.vue';
import tracker from '@/tracker';
import FormInput from '@/components/_generic/forms/FormInput.vue';
import FormInputSuggestion from '@/components/_generic/forms/FormInputSuggestion.vue';
import { PotokInputSmall } from 'potok-uikit';

import { useStateStore } from '@/store/stateStore';
import masks from '@/utils/masks';
import DashboardOnboardRulesAlfa from '@/components/Dashboard/DashboardOnboard/DashboardOnboardRules/DashboardOnboardRulesAlfa';
import DashboardOnboardRulesDefault from '@/components/Dashboard/DashboardOnboard/DashboardOnboardRules/DashboardOnboardRulesDefault';
import { useRoute } from 'vue-router';
import PotokImg from '@/components/_generic/PotokImg.vue';
import imageConstants from '@/constants/imageConstants';
import detectDevice from '@/composables/detectDeviceComposable';
import { PotokButton } from 'potok-uikit';

const props = defineProps(['profileData']);
const emit = defineEmits(['updateStep']);

const store = useStateStore();
const route = useRoute();

const { isTablet } = detectDevice();

const loading = ref(false);
const descText = ref('');
const form = ref({
  name: null,
  surname: null,
  secondName: null,
  birthday: null,
  passportSeries: null,
  passportNumber: null,
  inn: null,
});
const localMasks = ref({
  phone: masks.phone(),
  inn: masks.digits(12),
  birthday: masks.date(),
  passportSeries: masks.digits(4),
  passportNumber: masks.digits(6),
});
const suggestionField = ref({
  surname: {
    code: 'SURNAME',
    dadataFieldName: 'surname',
    formField: 'surname',
    type: 'NAME',
  },
  name: {
    code: 'NAME',
    dadataFieldName: 'name',
    formField: 'name',
    type: 'NAME',
  },
  secondName: {
    code: 'PATRONYMIC',
    dadataFieldName: 'patronymic',
    formField: 'secondName',
    type: 'NAME',
  },
});

const requiredFields = [
  { title: 'фамилия', value: 'surname' },
  { title: 'имя', value: 'name' },
  { title: 'дата рождения', value: 'birthday' },
  { title: 'паспорт серия', value: 'passportSeries' },
  { title: 'номер паспорта', value: 'passportNumber' },
  { title: 'инн', value: 'inn' },
];

const isShowModalConfirm = ref(false);
const isShowBirthdayDatepicker = ref(false);
const confirmErrorMessage = ref(null);
const confirmMessage = ref('присоединение к правилам платформы');
const messageError = ref(null);

const rules = {
  form: {
    surname: {
      required: validators.required,
      russianAlpha: validators.russianAlpha,
    },
    name: {
      required: validators.required,
      russianAlpha: validators.russianAlpha,
    },
    secondName: {
      russianAlpha: validators.russianAlpha,
    },
    inn: {
      required: validators.required,
      exactLength: validators.exactLength(12),
      numeric: validators.numeric,
      inn: (v) => checkINN(v),
    },
    birthday: {
      required: validators.required,
      exactLength: validators.exactLength(10),
      date: validators.date,
      dateAgeBetweenYears: validators.dateAgeBetweenYears(18, 120),
    },
    passportSeries: {
      required: validators.required,
      exactLength: validators.exactLength(4),
      numeric: validators.numeric,
    },
    passportNumber: {
      required: validators.required,
      exactLength: validators.exactLength(6),
      numeric: validators.numeric,
    },
  },
};

let v$ = useVuelidate(rules, { form: form.value });

const isShowForm = computed(() => {
  if (!store.isAlfaCustomer) {
    return true;
  }
  if (!isValidInitData.value) {
    return true;
  }
  return null;
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});

const isDisableField = computed(() => {
  return !!store.isAlfaCustomer || store.isGosuslugiCustomer;
});

const isDisableFieldInn = computed(() => {
  return (
    (!!props.profileData.inn && !route.query.publicService) ||
    store.isGosuslugiCustomer
  );
});

/**
 * Проверяем наличие данных в пришедших с бека полей
 * если обязательное поле пустое то ошибка
 */
const isValidInitData = computed(() => {
  for (let field in props.profileData) {
    if (
      requiredFields.find((i) => i.value === field) &&
      !props.profileData[field]
    ) {
      return false;
    }
  }
  return true;
});

const checkValidContinue = () => {
  for (const field in requiredFields) {
    if (!form.value[requiredFields[field].value]) {
      messageError.value = `незаполнено обязательное поле: ${requiredFields[field].title}`;
      return false;
    }
    if (requiredFields[field].value === 'inn' && form.value.inn.length !== 12) {
      messageError.value = 'ИНН должен состоять из 12 цифр';
      return false;
    }
    if (
      requiredFields[field].value === 'inn' &&
      v$.value.form.inn.inn.$invalid
    ) {
      messageError.value = 'некорректный ИНН';
      return false;
    }
    if (v$.value.form[requiredFields[field].value].$invalid) {
      messageError.value = `ошибка в поле ${requiredFields[field].title}`;
      return false;
    }
  }
  return true;
};

const birthdayDatepickerStartDate = () => {
  const startDateYear = new Date().getFullYear() - 18;
  const startDate = new Date().setFullYear(startDateYear);
  return new Date(startDate);
};

const datepickerHandle = () => {
  isShowBirthdayDatepicker.value = false;
};
const onClickaway = () => {
  isShowBirthdayDatepicker.value = false;
};
const initForm = () => {
  if (props.profileData) {
    form.value.name = props.profileData.name;
    form.value.surname = props.profileData.surname;
    form.value.secondName = props.profileData.secondName;
    if (props.profileData.birthDateAt) {
      form.value.birthday = format(
        parseISO(props.profileData.birthDateAt),
        'dd.MM.yyyy',
      );
    }
    form.value.passportSeries = props.profileData.passportSeries;
    form.value.passportNumber = props.profileData.passportNumber;
    form.value.inn = props.profileData.inn;
  }
};

const onClickContinue = () => {
  if (!checkValidContinue()) {
    return;
  }
  putOnboardProfile();
};
const inputValue = () => {
  messageError.value = null;
};

const getUsersMeAsync = () => {
  return server.getUsersMe
    .send()
    .pipe(onGetUsersMeAsyncSuccess, () => {
      store.setUserLoading(false);
    })
    .exec();
};
const onGetUsersMeAsyncSuccess = ({ data: user }) => {
  store.merge({ user });
  store.setUserLoading(false);
};

const onClickBack = () => {
  loading.value = true;
  return server.putUserOnboardPrevious
    .send({})
    .pipe(onFetchOnboardPreviousSuccess, () => {
      loading.value = false;
    })
    .exec();
};
const onFetchOnboardPreviousSuccess = (obj) => {
  loading.value = false;
  emit('updateStep', {
    step: obj.data.onboardingStatus,
  });
};
const putOnboardProfile = () => {
  const query = {
    surname: form.value.surname,
    name: form.value.name,
    passportSeries: form.value.passportSeries,
    passportNumber: form.value.passportNumber,
    birthDateAt: format(
      parse(form.value.birthday, 'dd.MM.yyyy', new Date()),
      'yyyy-MM-dd',
    ),
    inn: form.value.inn,
  };
  if (form.value.secondName) {
    query.secondName = form.value.secondName;
  }
  loading.value = true;
  return server.postUserOnboardProfile
    .send(query)
    .pipe(onFetchOnboardProfileSuccess, () => {
      loading.value = false;
    })
    .exec();
};
const onFetchOnboardProfileSuccess = (obj) => {
  loading.value = false;
  if (obj.data && obj.data.isSuccessful) {
    tracker.queue(
      tracker.commands.SEND,
      'Adg_Investory_Shag7_Sbor_dannyh_prisoedinilsja_k_pravilam',
    );
    store.fetchCompanies({
      callback: () => {
        store.setSelectedCompany(obj.data.data.companyId);
        isShowModalConfirm.value = true;
      },
    });
  }
};
const onClickProfileConfirm = ({ code }) => {
  confirmErrorMessage.value = null;
  const query = {
    code: code,
    companyId: selectedCompany.value.id,
  };
  loading.value = true;
  return server.putUserOnboardProfile
    .send(query, {})
    .pipe(onPutProfileConfirmSuccess, onPutProfileConfirmError)
    .exec();
};
const onPutProfileConfirmSuccess = (obj) => {
  tracker.queue(
    tracker.commands.SEND,
    'Investor_LK_Onboard_Step_2_Confirm_SMS_Rules',
  );

  loading.value = false;
  if (obj.data.isSuccessful) {
    tracker.queue(
      tracker.commands.SEND,
      'Adg_Investory_Shag8_Sbor_dannyh_podpisal_dokument',
    );
    emit('updateStep', {
      step: obj.data.onboardingStatus,
    });
  } else {
    if (obj.data.error) {
      confirmErrorMessage.value = obj.data.error;
    } else {
      confirmErrorMessage.value =
        'Неизвестная ошибка. Обратитесь в тех. поддержку';
    }
  }
};
const onPutProfileConfirmError = () => {
  loading.value = false;
};

const getTranslations = () => {
  loading.value = true;
  const query = {
    source: 'onboarding',
  };
  return server.getTranslations
    .send(query)
    .pipe(onFetchGetTranslationsSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onFetchGetTranslationsSuccess = (obj) => {
  descText.value = obj.data.onboarding.identityVerification;
  loading.value = false;
};

getUsersMeAsync();

onMounted(() => {
  getTranslations();
  initForm();
});
</script>
<style lang="scss" scoped>
.onboard-rules-datepicker-container {
  padding: 15px;
  position: absolute;
  z-index: 2;
  background: #fff;
  box-shadow: 0 2px 22px 0 rgba(13, 39, 65, 0.12);
}
:deep(.dp__calendar) {
  width: 100%;
}
:deep(.dp__today) {
  border: 0;
  color: $tiffany;
}
.step_description {
  text-transform: none;
  color: $deep_dive !important;
}
.step_profile .step_description__tips_restriction {
  margin-top: 16px;
  max-width: 800px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.action_wrapper {
  display: flex;
  @media (max-width: $size_767) {
    flex-direction: column;
  }
}
.button-continue {
  margin-right: 20px;
  @media (max-width: $size_767) {
    margin-right: 8px;
    margin-bottom: 10px;
    padding: 9px 15px 11px 15px;
  }
}
.button-back {
  @media (max-width: $size_767) {
    padding: 9px 15px 11px 15px;
  }
}
.step_profile .devider__mb {
  margin-top: 25px;
  margin-bottom: 30px;
}
.row_item {
  padding-top: 24px;
  @media (max-width: $size_767) {
    padding-top: 0px;
  }
}
.item_block {
  @media (max-width: $size_767) {
    padding-top: 20px;
  }
}
.inn_tips_wrapper {
  margin-left: 5px;
  width: 18px;
  margin-top: -4px;
}
.birthday-form-icon {
  background-image: url('../../../assets/img/icons/calendar-grey.svg');
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 12px;
  background-color: transparent;
  border: 0;
}
.inn-dropdown-example {
  color: $grey;
}
</style>
