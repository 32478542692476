<template>
  <div class="balance_item">
    <i class="icon_myinvestment_refresh"></i>
    <div class="balance_title">Ожидаемый доход</div>
    <div class="balance_value">
      <template v-if="isLoading">
        <Loader style="margin-top: 10px" />
      </template>
      <template v-if="!isLoading">
        <div style="display: inline-block" @click.prevent="">
          <VDropdown placement="bottom-start">
            <MoneyValue
              :value="expectedIncome"
              :rounding="false"
              color="#FFC11E"
              comma-color="#FFC11E"
            />
            <template #popper>
              <div class="popup">
                <div v-close-popper class="popup-close"></div>
                <div class="popup-text">
                  Размер ожидаемого процентного дохода может измениться на сумму
                  пени (+), дефолтов (-) и в связи с досрочным погашением (-).
                  Учитываются поступления по займам в статусе “текущий“. Доход
                  рассчитывается на основании фактического срока погашения
                  займов.
                </div>
              </div>
            </template>
          </VDropdown>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import mixins from '@/mixins';
import { Loader } from 'potok-uikit';
import MoneyValue from '@/components/_generic/MoneyValue.vue';
import { formatCurrency } from '@/utils/commonUtils/libUtils';

import { useStateStore } from '@/store/stateStore';
import { VClosePopper } from 'v-tooltip';

export default {
  directives: {
    ClosePopover: VClosePopper,
  },
  components: {
    MoneyValue,
    Loader,
  },
  mixins: [mixins.common],
  data() {
    return {
      isLoading: false,
      expectedIncome: 0,
    };
  },
  computed: {
    selectedCompanyId() {
      return useStateStore().selectedCompanyId;
    },
  },
  watch: {
    selectedCompanyId() {
      if (this.selectedCompanyId) {
        this.getCompanyExpectedIncomeAsync();
      }
    },
  },
  mounted() {
    if (this.selectedCompanyId) {
      this.getCompanyExpectedIncomeAsync();
    }
  },
  methods: {
    formatCurrency,
    getCompanyExpectedIncomeAsync() {
      const query = {
        companyIds: this.selectedCompanyId,
      };
      this.isLoading = true;
      return this.server.getUsersCompanyExpectedIncome
        .send(query)
        .pipe(this.onGetCompanyExpectedIncomeAsyncSuccess, () => {
          this.isLoading = false;
        })
        .exec();
    },
    onGetCompanyExpectedIncomeAsyncSuccess({ data }) {
      this.isLoading = false;
      this.expectedIncome = data.sum || 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.balance_value {
  color: #ffc11e;
}
</style>
