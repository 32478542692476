<template>
  <div class="clean-profit block_content">
    <div class="item-header">чистая доходность</div>
    <div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-red"><i class="icon-profit-red"></i></div>
        <div>
          <div class="item-name">
            {{ props.data?.data?.withheldTax?.description }}
          </div>
          <div class="item-value">
            {{ formatCurrency(props.data?.data?.withheldTax?.value, 0) }} ₽
          </div>
        </div>
      </div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-grey"><i class="icon-chart"></i></div>
        <div>
          <div class="item-name">итого после ндфл</div>
          <div class="item-value">
            {{
              formatCurrency(
                props.data?.data?.interestWithPenalty?.value -
                  props.data?.data?.withheldTax?.value,
                0,
              )
            }}
            ₽
          </div>
        </div>
      </div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-grey"><i class="icon-bag"></i></div>
        <div>
          <div class="item-name">
            {{ props.data?.data?.portfolioProfitabilityNet?.description }}
          </div>
          <div class="item-value">
            {{
              formatCurrency(
                props.data?.data?.portfolioProfitabilityNet?.value,
                0,
              )
            }}
            % годовых
          </div>
        </div>
      </div>
      <div class="item-row">
        <div class="tag-icon tag-icon-grey"><i class="icon-cart"></i></div>
        <div>
          <div class="item-name">
            {{ props.data?.data?.assetsProfitabilityNet?.description }}
          </div>
          <div class="item-value">
            {{
              formatCurrency(props.data?.data?.assetsProfitabilityNet?.value, 0)
            }}
            % годовых
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { formatCurrency } from '@/utils/commonUtils/utils';

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});
</script>
<style lang="scss" scoped>
.clean-profit {
  @media (max-width: $size_767) {
    min-height: 460px;
  }
}

.icon-profit-red {
  background-image: url('@img/icons/analytics/assets/discount-circle-red.svg');
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}
.icon-chart {
  background-image: url('@img/icons/analytics/assets/chart.svg');
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}
</style>
