<template>
  <div class="tableitems__content container horizontal_scroll">
    <component
      :is="tableComponent"
      :items="props.items"
      :columns="columns"
      :sort-column="sortColumn"
      :sort-column-direction="sortColumnDirection"
      :total-columns="totalColumns"
      @select-sort-column="selectSortColumn"
    />
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';

import GeneralTableDesktop from './GeneralTableDesktop.vue';
import GeneralTableMobile from './GeneralTableMobile.vue';

const props = defineProps([
  'items',
  'columns',
  'sortColumn',
  'sortColumnDirection',
  'totalColumns',
]);
const emit = defineEmits(['selectSortColumn']);

const windowWidth = ref(null);
const windowHeight = ref(null);

const tableComponent = computed(() => {
  if (windowWidth.value <= 767) {
    return GeneralTableMobile;
  }
  return GeneralTableDesktop;
});

onMounted(() => {
  getDimensions();
  window.addEventListener('resize', getDimensions);
});

const getDimensions = () => {
  windowWidth.value = document.documentElement.clientWidth;
  windowHeight.value = document.documentElement.clientHeight;
};
const selectSortColumn = (data) => {
  emit('selectSortColumn', data);
};
</script>
<style lang="scss" scoped>
.tableitems__content {
  width: 100%;
  overflow-x: auto;
  padding: 0;
}
.horizontal_scroll {
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #04ccd9;
  }
  &::-webkit-scrollbar-track {
    background: #dddddd;
  }
}
</style>
