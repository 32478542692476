<template>
  <div class="range_content">
    <div class="range_content_wrapper">
      <div class="range_content_select">
        <div class="range_content_select_date">
          <input
            v-model="dateSince"
            class="form-control date_input date_input_since"
            placeholder="Дата начала"
          />
          <input
            v-model="dateTo"
            class="form-control date_input"
            placeholder="Дата конца"
          />
        </div>
        <div class="range_content_select_calendar">
          <Datepicker
            v-model="selectedPeriod"
            range
            inline
            auto-apply
            locale="ru"
            format="dd.MM.yyyy"
            :format-locale="ru"
            :enable-time-picker="false"
            :month-change-on-scroll="false"
            @update:model-value="changeDate"
          ></Datepicker>
        </div>
      </div>
      <div class="range_content_select_period">
        <div
          v-for="(period, index) in props.periods"
          :key="index"
          class="range_content_select_period__item"
          :class="{
            range_content_select_period__selected: isSelectedPeriod(period),
          }"
          @click="onClickSelectPeriodItem(period)"
        >
          <div class="range_content_select_period__title">
            {{ period.title }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-error">
      {{ errorMessage }}
    </div>
    <div class="range_content_actions">
      <button
        type="button"
        class="button button-secondary-outline button_datepicker button_datepicker_accept"
        @click="onClickAcceptPeriod"
      >
        применить
      </button>
      <button
        type="button"
        class="button button-grey-outline button_datepicker"
        @click="onClickResetPeriod"
      >
        сбросить
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import ru from 'date-fns/locale/ru';
import { format, parse, isSameDay } from 'date-fns';

import Datepicker from '@vuepic/vue-datepicker';
import './vue-datepicker.scss';

const props = defineProps({
  initDateSince: Object,
  initDateTo: Object,
  periods: Array,
});
const emits = defineEmits(['change', 'close']);

const selectedPeriod = ref([new Date(), new Date()]);
const customPeriodChosen = ref(null);

const errorMessage = ref(null);

const dateSince = computed({
  get() {
    if (selectedPeriod.value && selectedPeriod.value.length > 0) {
      return format(selectedPeriod.value[0], 'dd.MM.yyyy');
    }
    return null;
  },
  set(val) {
    if (val.length != 10) {
      return;
    }
    const date = parse(val, 'dd.MM.yyyy', new Date());
    if (date) {
      selectedPeriod.value[0] = date;
    }
  },
});
const dateTo = computed({
  get() {
    if (selectedPeriod.value && selectedPeriod.value.length > 1) {
      return format(selectedPeriod.value[1], 'dd.MM.yyyy');
    }
    return null;
  },
  set(val) {
    if (val.length != 10) {
      return;
    }
    const date = parse(val, 'dd.MM.yyyy', new Date());
    if (date) {
      selectedPeriod.value[1] = date;
    }
  },
});

const onClickAcceptPeriod = () => {
  errorMessage.value = null;
  if (selectedPeriod.value && selectedPeriod.value.length === 2) {
    emits('close');

    const data = {
      dateSince: selectedPeriod.value[0],
      dateTo: selectedPeriod.value[1],
    };
    // Если выбран период из списка периодов - то в эмите отправляем объект с этим периодом дополнительно
    if (customPeriodChosen.value) {
      data.periodItem = customPeriodChosen.value;
    }

    emits('change', data);
  } else {
    errorMessage.value = 'не выбран период';
  }
};
const onClickResetPeriod = () => {
  errorMessage.value = null;
  const alltime = props.periods[props.periods.length - 1];
  selectedPeriod.value[0] = alltime.range[0];
  selectedPeriod.value[1] = alltime.range[1];
  customPeriodChosen.value = alltime;
  const data = {
    dateSince: alltime.range[0],
    dateTo: alltime.range[1],
  };
  data.periodItem = alltime;
  emits('change', data);
  emits('close');
};

const changeDate = () => {
  errorMessage.value = null;
};
const isSelectedPeriod = (period) => {
  if (
    isSameDay(selectedPeriod.value[0], period.range[0]) &&
    isSameDay(selectedPeriod.value[1], period.range[1])
  ) {
    return true;
  }
  return false;
};
const onClickSelectPeriodItem = (period) => {
  errorMessage.value = null;
  customPeriodChosen.value = period;
  selectedPeriod.value[0] = period.range[0];
  selectedPeriod.value[1] = period.range[1];
};

onMounted(() => {
  if (props.initDateSince) {
    customPeriodChosen.value = null;
    selectedPeriod.value[0] = props.initDateSince;
  }
  if (props.initDateTo) {
    customPeriodChosen.value = null;
    selectedPeriod.value[1] = props.initDateTo;
  }
});
</script>
<style lang="scss" scoped>
.range_content_select_main_container {
  display: flex;
  @media (max-width: $size_767) {
    flex-direction: column;
  }
}
.range_content_select_date_container {
  padding-right: 20px;
}
.range_content_select_period_container {
  padding-left: 20px;
  border-left: 1px solid $dividers;
  @media (max-width: $size_767) {
    padding-left: 0;
    padding-top: 12px;
    border-left: none;
    border-top: 1px solid $dividers;
    margin-bottom: 10px;
  }
}
.range_content_select_period {
  width: 100%;
  @media (max-width: $size_767) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.range_wrapper {
  position: relative;
}

.range__arrow_down {
  position: absolute;
  right: 15px;
  top: 40%;
  width: 11px;
  height: 8px;
  background: url('../../../assets/img/angle-down.svg') no-repeat;
  background-size: contain;
}

.range__arrow_up {
  position: absolute;
  right: 19px;
  top: 40%;
  width: 11px;
  height: 8px;
  background: url('../../../assets/img/angle-down.svg') no-repeat;
  background-size: contain;
  transform: rotate(180deg);
}

.range_content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  border-radius: 12px;
  top: 54px;
  z-index: 10;

  @media (max-width: $size_767) {
    flex-direction: column;
    position: fixed;
    left: 0;
    width: 320px;
    right: 0;
    margin: auto;
  }
}

.range_content_wrapper {
  display: flex;
  @media (max-width: $size_767) {
    flex-wrap: wrap;
  }
}
.range_content__placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey;
}
.range_content_select {
  padding-right: 20px;
}
.range_content_select_date {
  display: flex;
  justify-content: space-between;
}

.date_input {
  max-width: 149px;
  border-radius: 6px;
  background: $background !important;
}

.date_input_since {
  margin-right: 10px;
}

.range_content_select_calendar {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.range_content_actions {
  border-top: 1px solid $dividers;
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
}

.button_datepicker {
  margin-bottom: 10px;
  max-width: 122px;
}

.button_datepicker_accept {
  margin-right: 10px;
}
.range_content_select_period {
  min-width: 170px;
  padding-left: 20px;
  border-left: 1px solid $dividers;
  @media (max-width: $size_767) {
    min-width: initial;
    display: flex;
    flex-wrap: wrap;
    border-left: none;
    border-top: 1px solid $dividers;
    padding: 12px 0;
  }
}

.range_content_select_period__item {
  width: 150px;
  background: $background;
  border-radius: 6px;
  padding: 19px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:first-child {
    margin-top: 0px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
  @media (max-width: $size_767) {
    max-width: 123px;
    max-height: 44px;
    margin: 5px;
    &:first-child {
      margin-top: 5px;
    }
    &:last-child {
      margin-bottom: 5px;
    }
  }
}
.range_content_select_period__selected {
  background: #e6fafb;
  color: $tiffany;
  .range_content_select_period__title {
    color: $tiffany;
  }
}
.range_content_select_period__title {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: $deep_dive;
}
</style>
