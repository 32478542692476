<template>
  <div class="dashboard_block_content" style="height: 100%">
    <div class="potok-text-h2" style="margin-top: 10px">скоро</div>
    <template v-if="!props.futureStats">
      <Loader />
    </template>
    <template v-if="props.futureStats">
      <div class="row_funds potok-text-body-1 first_block_content">
        <span style="color: #818e99">проходят оценку</span>
        <span style="color: #10273d">
          {{
            props.futureStats?.loanCountUnderEvaluation +
            ' ' +
            pluralize(props.futureStats?.loanCountUnderEvaluation, [
              'займ',
              'займа',
              'займов',
            ])
          }}
        </span>
      </div>
      <div
        class="content_devider"
        style="
          opacity: 0.8;
          border-top: 1px solid #e8ecf0;
          margin-bottom: 13px;
          margin-top: 12px;
        "
      ></div>
      <div class="row_funds potok-text-body-1" style="color: #5c5c5c">
        <span style="color: #818e99">на сумму</span>
        <span style="color: #10273d">
          {{
            formatCurrency(props.futureStats?.loanAmountUnderEvaluation, 0)
          }}&nbsp;₽
        </span>
      </div>
    </template>
  </div>
</template>
<script setup>
import { formatCurrency, pluralize } from '@/utils/commonUtils/utils';
import { Loader } from 'potok-uikit';

const props = defineProps(['futureStats']);
</script>
<style lang="scss" scoped>
.first_block_content {
  margin-top: 72px;
  @media (max-width: $size_767) {
    margin-top: 36px;
  }
}

.dashboard_block_content {
  background-color: white;
}
.content_devider {
  margin: 26px 0;
  @media (max-width: $size_767) {
    margin: 10px 0;
  }
}

.row_funds {
  display: flex;
  justify-content: space-between;
  @media (max-width: $size_767) {
    flex-direction: column;
    span {
      margin: 3px 0;
    }
  }
}
</style>
