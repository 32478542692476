<template>
  <div
    v-if="textsByLoanType[loanType]"
    :class="classesByLoanType[loanType]"
    class="project__tag label_loan_type"
    @click.prevent
  >
    <VDropdown>
      <button class="info-icon-hint">
        <span class="project__tag-icon">{{ textsByLoanType[loanType] }}</span>
      </button>
      <template #popper>
        <div class="popup">
          <div v-close-popper class="popup-close"></div>
          <div class="popup-text">
            {{ textsHintByLoanType[props.loanType] }}
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script setup>
import { reactive } from 'vue';
import { VClosePopper as vClosePopper } from 'v-tooltip';

const factoringText =
  'Предоставление займов факторинговым компаниям для финансирования поставок товаров в федеральные ритейловые сети';
const b2bText = 'Заем на пополнение оборотных средств';

const props = defineProps({
  loanType: {
    type: String,
    required: true,
  },
});

const textsByLoanType = reactive({
  tender: 'госзаказ',
  tender_no_purpose: 'госзаказ',
  factoring: 'факторинг',
  hidden_factoring: 'факторинг',
  main: 'развитие',
  akbars: 'развитие',
  b2b: 'развитие',
});
const classesByLoanType = reactive({
  tender: 'tender',
  tender_no_purpose: 'tender',
  factoring: 'factoring',
  hidden_factoring: 'factoring',
  main: 'business',
  akbars: 'business',
  b2b: 'business',
});
const textsHintByLoanType = reactive({
  tender: 'Предоставление займов исполнителям госконтрактов',
  factoring: factoringText,
  hidden_factoring: factoringText,
  main: b2bText,
  akbars: b2bText,
  b2b: b2bText,
});
</script>
<style lang="scss" scoped>
.label_loan_type {
  display: flex;
  align-items: center;
}
.project__tag {
  &:first-child {
    margin-left: 0;
  }
}
</style>
