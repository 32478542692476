import { VClosePopper } from 'v-tooltip';
import constants from '@/constants';
import server from '@/server';

export default {
  directives: {
    ClosePopover: VClosePopper,
  },
  beforeCreate() {
    this.constants = constants;
  },
  data() {
    return {
      constants,
      server,
    };
  },
};
