<template>
  <div>
    <div class="step_title">Найдите свое ЮЛ</div>
    <p class="step_description">
      Воспользуйтесь поиском по названию, ИНН или ФИО генерального директора
    </p>
    <div>
      <InvestorAddStep_2FindCompany
        v-if="isUserLoaded"
        v-model:formCompany="formCompany"
        v-model:isFindCompany="isFindCompany"
        v-model:isFindCompanyFailure="isFindCompanyFailure"
        v-model:isInvalidSelectedCompanyType="isInvalidSelectedCompanyType"
        :expected-company-type="'LEGAL'"
      />
    </div>
    <div>
      <div class="devider"></div>
      <button
        class="button button-secondary"
        type="button"
        :disabled="isDisabledContinue"
        @click="onClickContinue"
      >
        Добавить эту компанию
      </button>

      <accept-rules-company />
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed } from 'vue';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';
import { copyAsJson } from '@/utils/commonUtils/utils';
import InvestorAddStep_2FindCompany from '@/components/InvestorAdd/InvestorAddStep_2/InvestorAddStep_2FindCompany.vue';
import AcceptRulesCompany from '@/components/InvestorAdd/InvestorAddStep_2/InvestorAddStep_2_2/AcceptRulesCompany.vue';

const store = useStateStore();
const emits = defineEmits(['nextStep']);

const loading = ref(false);
const formCompany = reactive({
  shortName: '',
  fullName: '',
  formalAddress: '',
  inn: '',
  ogrn: '',
  kpp: '',
  directorFio: '',
  directorPosition: '',
  opf: '',
  registerDateAt: null,
  isRealCompany: false,
});
const isFindCompany = ref(false);
const isFindCompanyFailure = ref(false);
const isInvalidSelectedCompanyType = ref(false);

const user = computed(() => {
  return store.state.user;
});
const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const isDisabledContinue = computed(() => {
  return (
    !isFindCompany.value ||
    isFindCompanyFailure.value ||
    isInvalidSelectedCompanyType.value ||
    loading.value
  );
});

const onClickContinue = () => {
  addThisCompany();
};
const addThisCompany = () => {
  const query = Object.assign(
    {
      birthDateAt: user.value.birthDateAt,
    },
    copyAsJson(formCompany),
  );
  loading.value = true;
  return server.postCompanies
    .send(query)
    .pipe(
      onAddInfoCompaniesAsyncSuccess.bind(this),
      onAddInfoCompaniesAsyncError.bind(this),
    )
    .exec();
};
const onAddInfoCompaniesAsyncSuccess = (obj) => {
  loading.value = false;
  let data = obj.data || {};
  if (data.isSuccessful) {
    emits('nextStep', {
      nextStepId: '3_2',
    });
  }
};
const onAddInfoCompaniesAsyncError = () => {
  loading.value = false;
};
</script>
<style lang="scss" scoped>
#search {
  outline: none;
  height: 36px;
  padding: 0 14px;
  border: 1px solid #dedee1;
  box-shadow: none;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: none;
  font-weight: 400;
}
</style>
