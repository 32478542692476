<template>
  <section class="page">
    <div class="investment__header page_title">
      <span style="margin-right: 5px">Инвестиция</span>
      <span
        v-if="data.item"
        class="header__subtitle"
        style="margin-right: 5px"
        >{{ data.item.number }}</span
      >
      <InvestmentStatus v-if="data.item" :item="data.item"></InvestmentStatus>
    </div>

    <template v-if="loadingInvestment">
      <div class="investment_card__panel" style="border-radius: 6px">
        <Loader style="margin: 20px" />
      </div>
    </template>
    <template v-if="!loadingInvestment && data.item">
      <div class="investment_card__panels">
        <div class="investment_card__panel">
          <FormFieldTitle :devider="false" :title="'Заем'" />
          <div class="row">
            <div class="col-md-6">
              <FormField :devider="false" :name="'Статус'">
                <template #value>
                  <div
                    :class="
                      getStatusClass(
                        data.item.project,
                        data.item.project.statusId,
                      )
                    "
                    class="project__tag"
                  >
                    <span class="project__tag-icon"
                      >{{
                        getStatusTitle(
                          data.item.project,
                          data.item.project.statusId,
                        )
                      }}
                    </span>
                  </div>
                </template>
              </FormField>
              <FormField :name="'Номер'">
                <template #value>
                  <router-link
                    :to="{
                      name: 'loan',
                      params: {
                        id: data.item.project.id,
                      },
                    }"
                    target="_blank"
                    >{{ data.item.project.number }}</router-link
                  >
                </template>
              </FormField>
              <FormField
                :name="'Заемщик'"
                :value="data.item.project.companyShortName"
              />
              <FormField
                :name="'Сумма осн. долга'"
                :value="`${formatCurrency(data.item.project.amount, 0)} ₽`"
              />
            </div>
            <div class="col-md-6">
              <FormField
                :name="'Ставка'"
                :value="`${formatCurrency(data.item.project.rate * 100, 2)} %`"
              />
              <FormField :name="'Срок'">
                <template #value>
                  {{
                    data.item.project.term +
                    ' ' +
                    pluralize(data.item.project.term, ['день', 'дня', 'дней'])
                  }}
                </template>
              </FormField>
              <FormField :name="'График выплат'">
                <template #value>
                  {{ paymentFreq(data.item.project.paymentFrequency) }}
                  <p v-if="!isRisingFunds">
                    {{ formatDate(data.item.project.fundedAt) }} —
                    {{ formatDate(data.item.project.scheduledRefundDate) }}
                  </p>
                </template>
              </FormField>
            </div>
          </div>
        </div>
        <div class="investment_card__panel">
          <FormFieldTitle :devider="false" :title="'Информация'" />
          <div class="row">
            <div class="col-md-6">
              <FormField
                :devider="false"
                :name="'Дата инвестирования'"
                :value="formatDate(data.item.createdAt)"
              />
              <FormField :name="'Метод инвестирования'" :value="formatMarket" />
              <FormField :name="'Статус инвестиции'">
                <template #value>
                  <InvestmentStatus
                    v-if="data.item"
                    :item="data.item"
                  ></InvestmentStatus>
                </template>
              </FormField>
              <FormField
                v-if="isShowPayoffMethod"
                :name="'Метод погашения'"
                :value="formatPayoffMethod"
              />
              <FormField
                v-if="isStatusRefuned"
                :name="'Дата погашения'"
                :value="dateRefuned"
              />
            </div>
            <div class="col-md-6">
              <FormField
                :name="'Сумма инвестиции'"
                :value="`${formatCurrency(data.item.amount, 0)} ₽`"
              />
              <FormField
                :name="'Прогнозируемый доход'"
                :value="`${formatCurrency(data.item.expectingIncome, 0)} ₽`"
              />
              <FormField
                :name="'Фактический доход'"
                :value="`${formatCurrency(data.item.factIncome, 0)} ₽`"
              />
              <FormField :name="'Тип инвестиции'" :value="getTypeInvestment" />
              <FormField
                v-if="data.item.saleDeals && data.item.saleDeals.length > 0"
                :name="'Ставка при продаже'"
                :value="`${formatCurrency(
                  data.item.saleDeals[0].purchasedInvestmentRate,
                  2,
                )} %`"
              />
              <FormField
                v-if="
                  data.item.purchaseDeals && data.item.purchaseDeals.length > 0
                "
                :name="'Ставка на остаток ОД'"
                :value="`${formatCurrency(
                  data.item.purchaseDeals[0].purchasedInvestmentRate,
                  2,
                )} %`"
              />
            </div>
          </div>
        </div>
        <div v-if="!isReserved">
          <InvestmentTableDesktop v-if="!isMobile" :item="data.item" />
          <InvestmentTableMobile v-if="isMobile" :item="data.item" />
        </div>
        <div class="investment_card__panel">
          <FormFieldTitle :devider="false" :title="'Документы по инвестиции'" />
          <div class="row item_row">
            <div class="col-md-12" style="padding: 0">
              <div
                v-for="(doc, index) in data.item.documents"
                :key="index"
                class="document"
              >
                <a
                  href="#"
                  class="doc-name document_link"
                  @click.prevent="downloadSignedDocumentsAsync(doc)"
                >
                  <i class="icon_arrow_download" style="margin-right: 7px"></i>
                  {{ formatDocumentTitle(doc) }}
                </a>
                <div class="document_date">
                  {{ formatDate(doc.createdAt) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <button
      v-if="isShowCancelInvestment"
      :disabled="loadingCancelInvestment"
      type="button"
      class="button button-secondary-outline button_cancel_investment"
      @click="cancelInvestment()"
    >
      Отменить резерв
    </button>
    <ModalConfirmSMS
      v-if="isShowModalConfirm"
      :loading="loadingCancelInvestment"
      :confirm-function="onClickCanelConfirm"
      :error-message="confirmErrorMessage"
      :confirm-message="confirmMessage"
      @close="isShowModalConfirm = false"
    />
  </section>
</template>
<script setup>
import { ref, reactive, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import tracker from '@/tracker';
import detectDevice from '@/composables/detectDeviceComposable';
import documentUtils from '@/composables/documentsComposable';

import server from '@/server';
import { Loader } from 'potok-uikit';
import FormField from '@/components/_generic/forms/FormField.vue';
import FormFieldTitle from '@/components/_generic/forms/FormFieldTitle.vue';
import ModalConfirmSMS from '@/components/_generic/modals/ModalConfirmSMS.vue';

import InvestmentStatus from '../components/Investment/InvestmentStatus.vue';
import InvestmentTableDesktop from '../components/Investment/InvestmentTableDesktop.vue';
import InvestmentTableMobile from '../components/Investment/InvestmentTableMobile.vue';
import { useStateStore } from '@/store/stateStore';
import constants from '@/constants';
import {
  formatDate,
  formatCurrency,
  pluralize,
} from '@/utils/commonUtils/utils';

const store = useStateStore();
const route = useRoute();
const router = useRouter();
const { isMobile } = detectDevice();
const { formatDocumentTitle, downloadSignedDocumentsAsync } = documentUtils();

const loadingInvestment = ref(false);
const loadingCancelInvestment = ref(false);

const isShowModalConfirm = ref(false);
const confirmErrorMessage = ref(null);
const confirmMessage = ref(null);
const data = reactive({
  item: null,
});

const formatMarket = computed(() => {
  if (data.item.market === 'primary') {
    return 'первичное размещение';
  }
  if (data.item.market === 'secondary') {
    return 'вторичный рынок';
  }
  return '';
});
const formatPayoffMethod = computed(() => {
  if (!data.item) {
    return '';
  }
  if (data.item.saleDeals && data.item.saleDeals.length > 0) {
    return 'с обратным выкупом';
  }
  if (data.item.payoffMethod === 'sold') {
    return 'продана';
  }
  if (data.item.payoffMethod === 'finalEarlyPayoff') {
    return 'досрочное погашение';
  }
  if (data.item.payoffMethod === 'refundAfterDefault') {
    return 'возврат после дефолта';
  }
  if (data.item.payoffMethod === 'scheduledRefund') {
    return 'плановое погашение';
  }
  return '';
});
const getTypeInvestment = computed(() => {
  if (
    data.item.purchaseDeals &&
    data.item.purchaseDeals.length > 0 &&
    data.item.purchaseDeals[0].type ===
      constants.investmentPurchaseDealTypes.withFutureBuyback
  ) {
    return 'с обратным выкупом';
  }
  if (data.item.isManual) {
    return 'ручная';
  }
  return 'авто';
});
const isReserved = computed(() => {
  return data.item && data.item.status === 'reserved';
});
const isRisingFunds = computed(() => {
  if (!data.item) {
    return false;
  }
  if (
    data.item.project &&
    data.item.project.statusId === constants.PROJECT__STATUS__RISING_FUNDS
  ) {
    return true;
  }
  return false;
});
const isStatusRefuned = computed(() => {
  if (!data.item) {
    return false;
  }
  if (data.item.status === 'refunded') {
    return true;
  }
  return false;
});
const isShowPayoffMethod = computed(() => {
  if (!data.item) {
    return false;
  }
  if (isStatusRefuned.value) {
    return true;
  }
  if (!data.item.payoffMethod) {
    return false;
  }
  return false;
});
const isShowCancelInvestment = computed(() => {
  return data.item && data.item.status === 'reserved' && !data.item.isCancelled;
});
const dateRefuned = computed(() => {
  if (!data.item) {
    return null;
  }
  if (
    data.item.saleDeals &&
    data.item.saleDeals.length == 1 &&
    data.item.saleDeals[0].type == 'bought_back' &&
    data.item.saleDeals[0].createdAt
  ) {
    return formatDate(data.item.saleDeals[0].createdAt);
  }
  return formatDate(data.item.project.refundedAt);
});

const getInvestmentAsync = () => {
  loadingInvestment.value = true;
  const investmentId = route.params.id;
  server.getInvestment
    .send(null, {
      params: { id: investmentId },
    })
    .pipe(onGetInvestmentAsyncSuccess.bind(this), () => {
      loadingInvestment.value = false;
    })
    .exec();
};
const onGetInvestmentAsyncSuccess = (obj) => {
  loadingInvestment.value = false;
  data.item = obj ? obj.data : {};
};
const paymentFreq = (loanPaymentFreq) => {
  return loanPaymentFreq
    ? 'каждые ' +
        loanPaymentFreq +
        ' ' +
        pluralize(loanPaymentFreq, ['день', 'дня', 'дней'])
    : '';
};
const getStatusClass = (project, statusId) => {
  if (project.isRestructured) {
    return 'project-restructured';
  }

  if (
    statusId === constants.projectStatus.cession1st ||
    statusId === constants.projectStatus.cession2nd ||
    statusId === constants.projectStatus.cessionFixed
  ) {
    return constants.projectStatusClass[statusId];
  }

  return constants.projectStatusClass[statusId];
};
const getStatusTitle = (project, statusId) => {
  if (
    statusId === constants.projectStatus.cession1st ||
    statusId === constants.projectStatus.cession2nd ||
    statusId === constants.projectStatus.cessionFixed
  ) {
    if (project.isRestructured) {
      return 'реструктуризация';
    }

    return 'дефолт';
  }
  return constants.projectStatusTitle[statusId];
};
const cancelInvestment = () => {
  tracker.queue(tracker.commands.SEND, 'Investor_LK_Cansel');
  postProjectCancelInvestmentSignAsync();
};
const postProjectCancelInvestmentSignAsync = () => {
  const query = {
    investmentId: data.item.id,
  };
  loadingCancelInvestment.value = true;
  return server.postProjectCancelInvestmentSign
    .send(query)
    .pipe(
      onPostProjectCancelInvestmentSignAsyncSuccess,
      onPostProjectCancelInvestmentSignAsyncError,
    )
    .exec();
};
const onPostProjectCancelInvestmentSignAsyncSuccess = (obj) => {
  loadingCancelInvestment.value = false;
  const result = obj.data || {};
  if (result.isSuccessful) {
    confirmMessage.value = `
        Отзыв Акцепта
        Инвестиционного предложения ${data.item.number}
        `;
    confirmMessage.value += `<br />Сумма ${formatCurrency(data.item.amount)} ₽`;
    isShowModalConfirm.value = true;
  }
};
const onPostProjectCancelInvestmentSignAsyncError = () => {
  loadingCancelInvestment.value = false;
};
const onClickCanelConfirm = ({ code }) => {
  const query = {
    investmentId: data.item.id,
    code: code,
  };
  loadingCancelInvestment.value = true;
  return server.postProjectCancelInvestmentConfirm
    .send(query)
    .pipe(
      onPostProjectCancelInvestmentConfirmAsyncSuccess.bind(this),
      onPostProjectCancelInvestmentConfirmAsyncError.bind(this),
    )
    .exec();
};
const onPostProjectCancelInvestmentConfirmAsyncSuccess = (obj) => {
  loadingCancelInvestment.value = false;
  isShowModalConfirm.value = false;
  const result = obj.data || {};
  if (result.isSuccessful) {
    store.fetchBalance();
    router.push({
      name: 'loans',
    });
  }
};
const onPostProjectCancelInvestmentConfirmAsyncError = () => {
  loadingCancelInvestment.value = false;
};

onMounted(() => {
  getInvestmentAsync();
});
</script>
<style lang="scss" scoped>
.investment__header {
  margin: 0px 0 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.header__subtitle {
  color: #7d7d7e;
}
.investment_card__panels {
  .investment_card__panel:first-child {
    border-radius: 6px 6px 0 0;
    @media (max-width: $size_767) {
      border-radius: 6px;
    }
  }
  .investment_card__panel:last-child {
    border-radius: 0 0 6px 6px;
    @media (max-width: $size_767) {
      border-radius: 6px;
    }
  }
}
.investment_card__panel {
  background: #ffffff;
  box-shadow: 0px 1px 4px #e5e9f2;
  padding: 0 20px 20px 20px;
  @media (max-width: $size_767) {
    margin-bottom: 19px;
    border-radius: 6px;
  }
}
.investment_card__devider {
  border-bottom: 1px solid #ededf4;
}
.item_row {
  margin: 0;
}
.document {
  padding: 0;
}
.button_cancel_investment {
  margin-top: 20px;
  @media (max-width: $size_767) {
    width: 100%;
  }
}
</style>
