<template>
  <div v-on-clickaway="onClickaway" class="cselect" @click="onClickCSelect">
    <div
      class="form-control cselect__select"
      :class="{
        cselect__unactive: !isShowSelectList,
        cselect__active: isShowSelectList,
        cselect__disabled: props.disabled,
      }"
    >
      <div
        :class="{
          cselect__arrow_down: !isShowSelectList,
          cselect__arrow_up: isShowSelectList,
        }"
      ></div>
      <div class="cselect__select_content">
        {{ selectedValueTitle }}
      </div>
    </div>
    <div v-if="isShowSelectList" class="cselect__list">
      <div
        v-for="(item, index) in props.items"
        :key="index"
        class="cselect__list__item"
        :class="{
          cselect__list__item__active: item.value === props.modelValue,
        }"
        @click="onClickSelectedValue(item.value)"
      >
        <div class="item__left" :title="item.title">{{ item.title }}</div>
        <div class="item__right">
          <i v-if="item.value === props.modelValue" class="icon_menu_galka"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { directive as vOnClickaway } from 'vue3-click-away';
import { ref, computed } from 'vue';

const props = defineProps(['items', 'modelValue', 'disabled']);
const emit = defineEmits(['update:modelValue']);
const isShowSelectList = ref(false);

const selectedValue = computed({
  get() {
    return props.modelValue;
  },
  set(localValue) {
    emit('update:modelValue', localValue);
  },
});
const selectedValueTitle = computed(() => {
  const localValue = props.items.find((i) => i.value === selectedValue.value);
  if (!localValue) {
    return;
  }
  return localValue.title;
});

const onClickaway = () => {
  isShowSelectList.value = false;
};
const onClickCSelect = () => {
  if (!props.disabled) {
    isShowSelectList.value = !isShowSelectList.value;
  }
};
const onClickSelectedValue = (localValue) => {
  emit('update:modelValue', localValue);
};
</script>
<style lang="scss" scoped>
.cselect {
  font-size: 14px;
  line-height: 24px;
  position: relative;
  width: 100%;
  .cselect__select {
    display: flex;
    align-items: center;
    border: 1px solid #dedee1;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    height: 44px;
    background-color: transparent;
  }
  .cselect__unactive {
    color: #999999;
    background: transparent;
  }

  .cselect__active {
    border: 1px solid #173b57;
  }

  .cselect__disabled {
    opacity: 0.6;
  }

  .cselect__select_content {
    margin-right: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $deep_dive;
    overflow: hidden;
    white-space: nowrap;
  }
  .cselect__arrow_down {
    position: absolute;
    right: 15px;
    top: 40%;
    width: 11px;
    height: 8px;
    background: url('../../../assets/img/angle-down.svg') no-repeat;
    background-size: contain;
  }

  .cselect__arrow_up {
    position: absolute;
    right: 19px;
    top: 40%;
    width: 11px;
    height: 8px;
    background: url('../../../assets/img/angle-down.svg') no-repeat;
    background-size: contain;
    transform: rotate(180deg);
  }

  .cselect__list {
    position: absolute;
    top: 50px;
    left: 0;
    min-height: 30px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
    border-radius: 12px;
    z-index: 100;
    .cselect__list__item {
      cursor: pointer;
      padding: 10px 6px;
      border-radius: 8px;
      margin: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 44px;
      font-weight: 400;
      font-size: 16px;
      color: $deep_dive;
      &:hover {
        background-color: $background;
      }
    }
    .cselect__list__item__active {
      color: $primary;
    }
  }
}
.item__left {
  white-space: nowrap;
  margin-right: 8px;
  margin-left: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item__right {
  display: flex;
  align-items: center;
}
.icon_menu_galka {
  vertical-align: initial;
}
</style>
