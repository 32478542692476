export default {
  postUsersSubscription: {
    url: `/api/users/subscription`,
    method: 'POST',
    body: {
      email: '',
      hash: '',
    },
  },
  getUsersReferralProgram: {
    url: `/api/users/referral-program`,
    method: 'GET',
  },
  postUsersCheckPhone: {
    url: `/api/users/check-phone`,
    method: 'POST',
    body: {
      phone: null,
    },
  },
  postUsersLogin: {
    url: `/api/users/login`,
    method: 'POST',
    body: {
      login: null,
      password: null,
    },
  },
  postUsersLoginOtp: {
    url: `/api/users/otp`,
    method: 'POST',
    body: {
      login: null,
      password: null,
      otp: null,
    },
  },
  postUsersRegisterRequest: {
    url: `/api/users/register/request`,
    method: 'POST',
    body: {
      phone: null,
    },
  },
  postUsersRegisterRequestRetry: {
    url: `/api/users/register/retry`,
    method: 'POST',
    body: {
      phone: null,
    },
  },
  postUsersRegisterConfirm: {
    url: `/api/users/register/confirm`,
    method: 'POST',
    body: {
      phone: null,
      smsCode: null,
    },
  },
  postUsersRecoverPassRequest: {
    url: `/api/users/recover-pass/request`,
    method: 'POST',
    body: {
      phone: null,
    },
  },
  postUsersRecoverPassConfirm: {
    url: `/api/users/recover-pass/confirm`,
    method: 'POST',
    body: {
      phone: null,
      smsCode: null,
    },
  },
  postUsersLogout: {
    url: `/api/users/logout`,
    method: 'POST',
  },
  postUsersRecoverPassSave: {
    url: `/api/users/recover-pass/save`,
    method: 'POST',
    body: {
      phone: null,
      newPassword: null,
    },
  },
  putUsersMe: {
    url: `/api/users/me`,
    method: 'PUT',
    body: {
      surname: null,
      name: null,
      secondName: null,
      cellPhone: null,
      inn: null,
    },
  },
  putUsersMeProfile: {
    url: `/api/users/me/profile`,
    method: 'PUT',
  },
  putUsersMePassport: {
    url: `/api/users/me/passports`,
    method: 'PUT',
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data',
    },
  },
  getUsersMe: {
    url: `/api/users/me`,
    method: 'GET',
  },
  getUsersCessionContracts: {
    url: `/api/users/cession-contracts`,
    method: 'GET',
  },
  getUsersCompanyExpectedIncome: {
    url: `/api/users/companies/expected-income`,
    method: 'GET',
  },
  getUsersCompanyDefaultIncome: {
    url: `/api/users/companies/default`,
    method: 'GET',
  },
  getUsersInvestments: {
    url: `/api/users/investments`,
    method: 'GET',
  },
  getUserOnboard: {
    url: `/api/users/onboarding`,
    method: 'GET',
  },
  putUserOnboardPassport: {
    url: `/api/users/onboarding/passport`,
    method: 'PUT',
  },
  postUserOnboardProfile: {
    url: `/api/users/onboarding/profile`,
    method: 'POST',
  },
  postUserOnboardTourComplete: {
    url: `/api/users/onboarding/tour/complete`,
    method: 'POST',
  },
  putUserOnboardProfile: {
    url: `/api/users/onboarding/profile`,
    method: 'PUT',
  },
  putUserOnboardBalance: {
    url: `/api/users/onboarding/balance`,
    method: 'PUT',
  },
  putUserOnboardStrategy: {
    url: `/api/users/onboarding/strategy`,
    method: 'POST',
  },
  putUserOnboardStrategyConfirm: {
    url: `/api/users/onboarding/strategy/:settingsId`,
    method: 'PUT',
  },
  putUserOnboardPrevious: {
    url: `/api/users/onboarding/previous`,
    method: 'PUT',
  },
  getUserTourChecklist: {
    url: `/api/users/onboarding/start-investing-checklist`,
    method: 'GET',
  },
  postUserInvestingChecklistComplete: {
    url: `/api/users/onboarding/start-investing-checklist/complete`,
    method: 'POST',
  },
  postMoneyTransfer: {
    url: `/api/money-transfers`,
    method: 'POST',
  },
  getMagicLink: {
    url: `/api/users/potok/magic-link`,
    method: 'GET',
  },
  getSupport: {
    url: '/api/users/support',
    method: 'GET',
  },
  postSpbQRCode: {
    url: '/api/sbp-qr-codes',
    method: 'POST',
  },
  getSpbQRCodeStatus: {
    url: '/api/sbp-qr-codes/:id/notification/status',
    method: 'GET',
  },
  getEsiaAuthLink: {
    url: '/api/users/esia-auth-link',
    method: 'GET',
  },
  postEsiaAuth: {
    url: '/api/esia/auth',
    method: 'POST',
  },
  getTranslations: {
    url: '/api/translations',
    method: 'GET',
  },
  postAccessByTokenChange: {
    url: '/api/users/alfa-auth/access-by-token/change',
    method: 'POST',
  },
  getPaymentMethods: {
    url: '/api/payment-methods',
    method: 'GET',
  },
};
