<template>
  <div class="investment_status">
    <div class="investment_status__value" :class="`status_${statusClass}`">
      {{ statusTitle }}
    </div>
  </div>
</template>
<script setup>
import constants from '@/constants';
import { computed } from 'vue';

const props = defineProps(['item']);
const statusTitle = computed(() => {
  if (props.item.isCancelled) {
    return 'Отменена';
  }
  if (props.item.project.isRestructured) {
    return 'Реструктурирован';
  }
  return constants.investmentStatusTitle[props.item.status];
});
const statusClass = computed(() => {
  if (props.item.status == 'cession') {
    if (props.item.project.isRestructured) {
      return 'cession';
    }
    return 'default';
  }
  return props.item.status;
});
</script>
<style lang="scss" scoped>
.investment_status {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 0px 6px 0 0px;
  .investment_status__value {
    display: inline-block;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    padding: 4px 8px;
    line-height: 12px;
    margin: 0 2px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    @media (max-width: $size_767) {
      margin: 0;
    }
  }
  .status_reserved {
    color: #4b64c4;
    background-color: #edf0f9;
  }
  .status_active {
    color: #04ccd9;
    background-color: #e7f8f7;
  }
  .status_refunded {
    color: #4b64c4;
    background-color: #edf0f9;

  }
  .status_cession {
    color: #ffc11e;
    background-color: #fff9e6;
  }
  .status_default {
    color: #EE3D48;
    background-color: rgba(238, 61, 72, 0.1);
  }
}
</style>
