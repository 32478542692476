<template>
  <div
    v-on-clickaway="onClickAway"
    class="settings-menu-select"
    @click="handleSelectClick"
  >
    <div class="selected-item">
      <div class="selected-item-content mr-2">
        <PotokImg
          :src="imageConstants.settings[props.selectedPage.icon]"
          class="mr-2"
        />
        <span class="potok-text-body-1">{{ props.selectedPage.title }}</span>
      </div>
      <PotokImg
        height="8"
        width="8"
        :src="imageConstants.arrowDown"
        :class="{
          'selected-item-arrow-up': isShowMenu,
        }"
      />
    </div>
    <SettingsMenu
      v-if="isShowMenu"
      :items="props.items"
      class="settings-menu"
      @change="changePage"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { directive as vOnClickaway } from 'vue3-click-away';
import SettingsMenu from '@/components/Settings/SettingsMenu.vue';
import imageConstants from '@/constants/imageConstants';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  selectedPage: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['change']);

const isShowMenu = ref(false);

const onClickAway = () => {
  isShowMenu.value = false;
};
const handleSelectClick = () => {
  isShowMenu.value = !isShowMenu.value;
};
const changePage = (item) => {
  emit('change', item);
};
</script>
<style lang="scss" scoped>
.settings-menu-select {
  color: $deep_dive;
  cursor: pointer;
  position: relative;
}
.settings-menu {
  position: absolute;
  top: 38px;
  left: 0;
  box-shadow: 0 2px 22px 0 #0d27411f;
  z-index: 10;
}
.selected-item {
  display: flex;
  align-items: center;
}
.selected-item-content {
  display: flex;
  align-items: center;
}
.selected-item-arrow-up {
  transform: rotate(180deg);
}
</style>
