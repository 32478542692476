<template>
  <div class="new-investments-sell-item-panel">
    <!-- discount -->
    <div class="new-investments-sell-item-discount panel-item-container">
      <div
        class="new-investments-sell-item-discount-title sell-item-panel-title potok-text-body-2"
      >
        дисконт
      </div>
      <div
        class="new-investments-sell-item-discount-value sell-item-panel-value potok-text-body-1-bold mt-2"
      >
        {{ discountPercentage }}
      </div>
    </div>
    <!-- limit -->
    <div class="new-investments-sell-item-limit panel-item-container">
      <div
        class="new-investments-sell-item-limit-title sell-item-panel-title potok-text-body-2"
      >
        лимит
      </div>
      <div
        class="new-investments-sell-item-limit-value sell-item-panel-value potok-text-body-1-bold mt-2"
      >
        {{ limit }}
      </div>
    </div>
    <!-- requestDueAt -->
    <div class="new-investments-sell-item-due-at panel-item-container">
      <div
        class="new-investments-sell-item-due-at-title sell-item-panel-title potok-text-body-2"
      >
        срок действия
      </div>
      <div
        class="new-investments-sell-item-due-at-value sell-item-panel-value potok-text-body-1-bold mt-2"
      >
        {{ dueAt }}
      </div>
    </div>
    <!-- status (only desktop) -->
    <div
      v-if="!isTablet"
      class="new-investments-sell-item-status panel-item-container"
    >
      <div
        class="new-investments-sell-item-status-title sell-item-panel-title potok-text-body-2"
      >
        статус
      </div>
      <div class="new-investments-sell-item-status-value mt-2">
        <NewInvestmentsSellListBtnStatus
          :status="props.item.status"
          :comment="props.item.comment"
        />
      </div>
    </div>

    <NewInvestmentsSellItemPanelActions
      v-if="props.item.status === 'confirmed'"
      :item="props.item"
      :is-second-market="props.isSecondMarket"
      :is-loading-operation="props.isLoadingOperation"
      @handle-operation="handleOperation"
    />

    <!-- download offer (only mobile) -->
    <div
      v-if="isTablet && props.item.documentId"
      class="new-investments-sell-item-offer panel-item-container"
    >
      <div
        class="new-investments-sell-item-offer-content potok-text-body-1"
        @click="downloadOffer"
      >
        <i class="icon-grey-download" />
        оферта на продажу
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import {
  downloadFile,
  formatCurrency,
  formatDate,
  parseFilename,
} from '@/utils/commonUtils/libUtils';
import NewInvestmentsSellListBtnStatus from '@/components/Investments/NewInvestmentsSellList/NewInvestmentsSellListBtnStatus.vue';
import detectDevice from '@/composables/detectDeviceComposable';
import server from '@/server';
import NewInvestmentsSellItemPanelActions from '@/components/Investments/NewInvestmentsSellItem/NewInvestmentsSellItemPanel/NewInvestmentsSellItemPanelActions.vue';

const { isTablet } = detectDevice();

const props = defineProps(['isSecondMarket', 'isLoadingOperation', 'item']);
const emits = defineEmits(['handleOperation']);

const discountPercentage = computed(() => {
  return `${formatCurrency(props.item.discountPercentage, 1)} %`;
});
const limit = computed(() => {
  return `${formatCurrency(props.item.limitAmount, 0)} ₽`;
});
const dueAt = computed(() => {
  return formatDate(
    props.item.requestDueAt,
    'dd.MM.yyyy HH:mm',
    'Europe/Moscow',
  );
});

const handleOperation = (obj) => {
  emits('handleOperation', obj);
};

const downloadOffer = () => {
  return server.getDocument
    .send(null, {
      params: { id: props.item.documentId },
    })
    .pipe(onFetchDownloadOfferSuccess, () => {})
    .exec();
};
const onFetchDownloadOfferSuccess = ({ data, response }) => {
  if (response.status === 200) {
    const filename = parseFilename(response);
    downloadFile(filename, data);
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/typography.scss';

.new-investments-sell-item-panel {
  display: flex;
  align-items: center;
  border-radius: 14px;
  padding: 30px;
  background-color: $white;
  @media (max-width: $size_991) {
    padding: 20px 14px 20px 14px;
    flex-direction: column;
    align-items: normal;
  }
}
.panel-item-container {
  margin-left: 50px;
  &:first-child {
    margin-left: 0;
  }
  @media (max-width: $size_991) {
    margin-left: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid $dividers;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
.sell-item-panel-value {
  @media (max-width: $size_991) {
    @include potok-text-h2;
  }
}
.sell-item-panel-title {
  color: $grey;
}
.new-investments-sell-item-offer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey;
}
</style>
