<template>
  <ModalFull :title="modalTitle" @close="close()">
    <template #content>
      <ul class="block-tabs">
        <li @click="selectedTab = 'investors'">
          <a :class="{ 'tab-active': selectedTab === 'investors' }"
            >инвесторы</a
          >
        </li>
        <li @click="selectedTab = 'borrowers'">
          <a :class="{ 'tab-active': selectedTab === 'borrowers' }">заемщики</a>
        </li>
      </ul>

      <div class="header_scroll_menu" style="text-transform: none">
        <template v-if="selectedTab === 'investors'">
          <Loader v-if="isLoading" />
          <div v-else class="text_description">
            {{ investorsDescription }}
          </div>
          <div class="text_title" style="margin-top: 32px">
            ссылка для инвесторов
          </div>
          <div class="link_wrapper">
            <a target="_blank" :href="linkInverstor"> {{ linkInverstor }}</a>
            <div v-if="copiedInverstor" class="copied">👍 скопировано</div>
          </div>
          <button
            type="button"
            class="button button-secondary"
            style="margin-top: 4px"
            @click="onClickCopyInverstor"
          >
            скопировать ссылку
          </button>
        </template>
        <template v-if="selectedTab === 'borrowers'">
          <div class="text_description">
            {{ borrowersDescription }}
          </div>
          <div class="text_title" style="margin-top: 28px">
            ссылка для заемщиков
          </div>
          <div class="link_wrapper">
            <a target="_blank" :href="linkBorrower">{{ linkBorrower }}</a>
            <div v-if="copiedBorrower" class="copied">👍 скопировано</div>
          </div>
          <button
            type="button"
            class="button button-secondary"
            style="margin-top: 5px"
            @click="onClickCopyBorrower"
          >
            скопировать ссылку
          </button>
        </template>
      </div>
    </template>
  </ModalFull>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { useStateStore } from '@/store/stateStore';
import { Loader } from 'potok-uikit';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import server from '@/server';

const URL_BORROWER = 'https://business.potok.digital/';

const store = useStateStore();
const copiedInverstor = ref(false);
const copiedBorrower = ref(false);
const selectedTab = ref('investors');
const isLoading = ref(true);

const investorsDescription = ref('');
const borrowersDescription = ref('');
const modalTitle = ref('');

const selectedCompany = computed(() => {
  return store.selectedCompany;
});

const linkInverstor = computed(() => {
  if (!selectedCompany.value || !selectedCompany.value) {
    return '';
  }
  return selectedCompany.value.referralLink;
});
const linkBorrower = computed(() => {
  if (!selectedCompany.value || !selectedCompany.value) {
    return '';
  }
  return `${URL_BORROWER}?ref=${selectedCompany.value.referralCode}`;
});

const close = () => {
  store.state.modals.isShowModalRefferal = false;
};

const getReferralTextAsync = () => {
  isLoading.value = true;
  return server.getUsersReferralProgram
    .send()
    .pipe(onGetReferralProgramAsyncSuccess, () => {
      isLoading.value = false;
    })
    .exec();
};

const onGetReferralProgramAsyncSuccess = ({ data }) => {
  investorsDescription.value = data.investorsDescription;
  borrowersDescription.value = data.borrowersDescription;
  modalTitle.value = data.investorsTitle;
  isLoading.value = false;
};

getReferralTextAsync();

const onClickCopyInverstor = () => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(linkInverstor.value).then(function () {
      copiedInverstor.value = true;
    });
  }
};
const onClickCopyBorrower = () => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(linkBorrower.value).then(function () {
      copiedBorrower.value = true;
    });
  }
};

watch(copiedInverstor, () => {
  setTimeout(() => {
    copiedInverstor.value = false;
  }, 3000);
});
watch(copiedBorrower, () => {
  setTimeout(() => {
    copiedBorrower.value = false;
  }, 3000);
});
</script>
<style lang="scss" scoped>
:deep(.modal_block_container) {
  max-width: 640px;
}
.text_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111d3d;
}
.text_description {
  color: $grey;
}
</style>
