<template>
  <div>
    <InvestmentsBalanceDesktop
      v-if="!isMobile"
      :stat-amount="statAmount"
      :stat-count="statCount"
      :stat-income="statIncome"
      :promo-income="promoIncome"
      :loading-company-stat="loadingCompanyStat"
      :loading-company-promo-income="loadingCompanyPromoIncome"
    />
    <InvestmentsBalanceMobile
      v-if="isMobile"
      :stat-amount="statAmount"
      :stat-count="statCount"
      :stat-income="statIncome"
      :promo-income="promoIncome"
      :loading-company-stat="loadingCompanyStat"
      :loading-company-promo-income="loadingCompanyPromoIncome"
    />
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from 'vue';

import InvestmentsBalanceDesktop from './InvestmentsBalance/InvestmentsBalanceContainers/InvestmentsBalanceDesktop.vue';
import InvestmentsBalanceMobile from './InvestmentsBalance/InvestmentsBalanceContainers/InvestmentsBalanceMobile.vue';
import detectDevice from '@/composables/detectDeviceComposable.js';
import server from '@/server';

import { useStateStore } from '@/store/stateStore';

const { isMobile } = detectDevice();
const store = useStateStore();

const loadingCompanyStat = ref(false);
const loadingCompanyPromoIncome = ref(false);
const statCount = ref(0);
const statAmount = ref(0);
const statIncome = ref(0);
const promoIncome = ref(0);

const selectedCompanyId = computed(() => {
  return store.selectedCompanyId;
});

watch(selectedCompanyId, () => {
  if (selectedCompanyId.value) {
    getAllData();
  }
});

onMounted(() => {
  if (selectedCompanyId.value) {
    getAllData();
  }
});

const getAllData = () => {
  getCompanyStatAsync();
  getCompanyPromoIncomeAsync();
};
const getCompanyStatAsync = () => {
  if (!selectedCompanyId.value) {
    return;
  }
  loadingCompanyStat.value = true;

  const params = {
    id: selectedCompanyId.value,
  };
  return server.getCompanyStatTotal
    .send(null, { params })
    .pipe(onGetCompanyStatAsyncSuccess, onGetCompanyStatAsyncError)
    .exec();
};
const onGetCompanyStatAsyncSuccess = ({ data }) => {
  loadingCompanyStat.value = false;
  statCount.value = data.count;
  statAmount.value = data.amount;
  statIncome.value = data.income;
};
const onGetCompanyStatAsyncError = () => {
  loadingCompanyStat.value = false;
  statCount.value = 0;
  statAmount.value = 0;
  statIncome.value = 0;
};
const getCompanyPromoIncomeAsync = () => {
  if (!selectedCompanyId.value) {
    return;
  }
  const query = {
    ids: selectedCompanyId.value,
  };
  loadingCompanyPromoIncome.value = true;
  return server.getCompanyPromoIncome
    .send(query)
    .pipe(onGetCompanyPromoIncomeAsyncSuccess)
    .exec();
};
const onGetCompanyPromoIncomeAsyncSuccess = ({ data }) => {
  loadingCompanyPromoIncome.value = false;
  promoIncome.value = data.sum || 0;
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

:deep(.balance_item) {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  overflow: hidden;
}
:deep(.balance_title) {
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  color: $grey;
  margin-top: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
:deep(.balance_value) {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  margin-top: 11px;
  button {
    border: 0;
    background: transparent;
    padding-left: 0;
  }
}
:deep(.balance_tag) {
  border-radius: 6px;
  padding: 4px 4px;
  display: inline-block;
  @media (max-width: $size_991) {
    padding: 4px;
  }
  @media (max-width: $size_767) {
    padding: 4px 12px;
  }
  button {
    border: 0;
    background: transparent;
  }
  .balance_tag_value {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }
}
</style>
