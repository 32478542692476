<template>
  <div class="onboard_wrapper">
    <div class="row">
      <div class="col-md-4">
        <WizardMobile
          :current-step-status="currentStep"
          :steps="props.steps"
          @update-step="updateStep"
        />
      </div>
    </div>
  </div>

  <dashboard-onboard-alfa-banner v-if="isAlfaCustomer" />

  <div class="row">
    <div class="col-md-8">
      <div class="block_content">
        <DashboardOnboardPerson
          v-if="props.currentStep === 'passport'"
          @update-step="updateStep"
        />
        <DashboardOnboardPending
          v-if="props.currentStep === 'recognize'"
          @update-step="updateStep"
        />
        <DashboardOnboardRules
          v-if="props.currentStep === 'profile'"
          :profile-data="props.profileData"
          @update-step="updateStep"
        />
        <DashboardOnboardStrategy
          v-if="props.currentStep === 'strategy'"
          @update-step="updateStep"
        />
        <DashboardOnboardRefill
          v-if="props.currentStep === 'balance'"
          @update-step="updateStep"
        />
      </div>
    </div>
  </div>

  <DashboardOnboardBonusBanner
    v-if="isNotAlfaCustomer && isNotFirstIncome"
    class="mt-5"
  />
</template>
<script setup>
import DashboardOnboardPerson from '@/components/Dashboard/DashboardOnboard/DashboardOnboardPerson.vue';
import DashboardOnboardPending from '@/components/Dashboard/DashboardOnboard/DashboardOnboardPending.vue';
import DashboardOnboardRules from '@/components/Dashboard/DashboardOnboard/DashboardOnboardRules.vue';
import DashboardOnboardStrategy from '@/components/Dashboard/DashboardOnboard/DashboardOnboardStrategy.vue';
import DashboardOnboardRefill from '@/components/Dashboard/DashboardOnboard/DashboardOnboardRefill.vue';
import DashboardOnboardBonusBanner from '@/components/Dashboard/DashboardOnboard/banners/DashboardOnboardBonusBanner.vue';
import WizardMobile from '@/components/_generic/stepWizard/WizardMobile.vue';

import { useStateStore } from '@/store/stateStore';
import DashboardOnboardAlfaBanner from '@/components/Dashboard/DashboardOnboard/banners/DashboardOnboardAlfaBanner.vue';
import { computed } from 'vue';

const emit = defineEmits(['updateStep']);
const props = defineProps({
  currentStep: {
    type: String,
    default: 'passport',
  },
  steps: {
    type: Array,
  },
  profileData: {
    type: Object,
  },
});

const store = useStateStore();
const isAlfaCustomer = computed(() => {
  return store.isAlfaCustomer;
});

const isNotAlfaCustomer = computed(() => {
  return !store.isAlfaCustomer;
});
const isNotFirstIncome = computed(() => {
  return !store.isFirstIncomeAt;
});

const updateStep = (obj) => {
  emit('updateStep', obj);
};
</script>
<style lang="scss" scoped>
.block_content {
  @media (max-width: $size_767) {
    margin-top: 10px;
  }
}
</style>
