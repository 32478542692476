<template>
  <div class="row single-logo-row">
    <router-link :to="{ name: 'main' }" class="header-logo">
      <img src="../../assets/img/logo.svg" alt="logo" />
    </router-link>
  </div>
</template>

<style lang="scss">
.single-logo-row {
  position: absolute;
}
.header-logo {
  float: left;
  margin-top: 15px;
  margin-left: 48px;

  @media (max-width: $size_991) {
    margin-left: 31px;
    margin-top: 12px;
  }
}
</style>
