<template>
  <div class="block-menu-desktop">
    <div class="block-menu">
      <ul class="block-tabs">
        <li v-if="props.projectsCount.funded > 0">
          <router-link
            :class="{
              'tab-active': isActiveStatus(STATUSES.funded),
            }"
            :to="{
              name: 'investments',
              query: { status: 'funded' },
            }"
            @click.prevent="onClickSetActiveStatus(STATUSES.funded)"
          >
            Текущие {{ props.projectsCount.funded }}
          </router-link>
        </li>
        <li v-if="props.projectsCount.restructured > 0">
          <router-link
            :class="{
              'tab-active': isActiveStatus(STATUSES.restructured),
            }"
            :to="{
              name: 'investments',
              query: { status: 'restructured' },
            }"
            @click.prevent="onClickSetActiveStatus(STATUSES.restructured)"
          >
            Реструктурированные {{ props.projectsCount.restructured }}
          </router-link>
        </li>
        <li v-if="props.projectsCount.refunded > 0">
          <router-link
            :class="{
              'tab-active': isActiveStatus(STATUSES.refunded),
            }"
            :to="{
              name: 'investments',
              query: { status: 'refunded' },
            }"
            @click.prevent="onClickSetActiveStatus(STATUSES.refunded)"
          >
            Возвращенные {{ props.projectsCount.refunded }}
          </router-link>
        </li>
        <li v-if="props.projectsCount.softOverdue > 0">
          <router-link
            :class="{
              'tab-active': isActiveStatus(STATUSES.softOverdue),
            }"
            :to="{
              name: 'investments',
              query: { status: 'softOverdue' },
            }"
            @click.prevent="onClickSetActiveStatus(STATUSES.softOverdue)"
          >
            В просрочке {{ props.projectsCount.softOverdue }}
          </router-link>
        </li>
        <li v-if="props.projectsCount.default > 0">
          <router-link
            :class="{
              'tab-active': isActiveStatus(STATUSES.default),
            }"
            :to="{
              name: 'investments',
              query: { status: 'default' },
            }"
            @click.prevent="onClickSetActiveStatus(STATUSES.default)"
          >
            В дефолте {{ props.projectsCount.default }}
          </router-link>
        </li>
        <li v-if="props.projectsCount.sold > 0">
          <router-link
            :class="{
              'tab-active': isActiveStatus(STATUSES.sold),
            }"
            :to="{
              name: 'investments',
              query: { status: 'sold' },
            }"
            @click.prevent="onClickSetActiveStatus(STATUSES.sold)"
          >
            Выкупленные {{ props.projectsCount.sold }}
          </router-link>
        </li>
        <li v-if="store.isPotokHoldingCompany || store.isPotokFinanceBorrower">
          <a
            :class="{
              'tab-active': isActiveStatus(STATUSES.buyback_current),
            }"
            href="#"
            @click.prevent="onClickSetActiveStatus(STATUSES.buyback_current)"
          >
            Buyback — текущие
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { useStateStore } from '@/store/stateStore';
import { STATUSES } from '../../../../constants/Investments/investmentsConstants.js';

const store = useStateStore();
const emit = defineEmits(['upateActiveStatus']);
const props = defineProps(['projectsCount', 'activeStatus']);

const isActiveStatus = (name) => {
  return props.activeStatus === name;
};
const onClickSetActiveStatus = (name) => {
  emit('upateActiveStatus', name);
};
</script>
<style lang="scss" scoped>
.block-menu {
  display: block;
}
.block-menu-desktop {
  .block-menu {
    position: relative;
    margin-bottom: 0;
    overflow-x: hidden;

    @media (max-width: 767px) {
      display: none;
    }
    @media (max-width: 991px) {
      right: 0;
    }
    .block-tabs {
      li {
        display: flex;
        align-items: center;
        margin-right: 40px;
        margin-top: 2px;
        .v-popover {
          margin-top: 4px;
          margin-left: 4px;
        }
        &:last-child {
          margin-right: 20px;
        }
      }
    }
  }
}
.block-menu-desktop {
  .block-menu-input {
    margin: 0px;
    border-radius: 6px;
    color: #999999;
    background-color: $grey-primary;
    border: 1px solid $grey-border;
    padding: 6px 6px 6px 38px;
    height: 36px;
    font-size: 14px;
    line-height: 24px;
    max-width: 300px;
    background-image: url('@img/icons/loupe.svg') !important;
    background-position: left 10px center;
    background-repeat: no-repeat;
    box-shadow: none;
  }
}
</style>
