import { formatCurrency } from '@/utils/commonUtils/libUtils';
import { format, set, setMonth } from 'date-fns';
import ru from 'date-fns/locale/ru';
import detectDevice from '@/composables/detectDeviceComposable';

const { isTablet, isMobile } = detectDevice();

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  // Верстка для контейнера тултипа и сам контейнер
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('graph-tooltip-container');
    tooltipEl.classList.add('potok-text-body-3');

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipGroupColumnHandler = (context, texts) => {
  const { chart, tooltip } = context;
  // Кастомный конфиг, который мы сами создаем в options в компоненте
  const { metaConfig } = chart.config.options;
  // Элемент тултипа
  const tooltipEl = getOrCreateTooltip(chart);

  // Если тултипа нет, скрываем
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Создаем элементы и отрисовываем их
  if (tooltip.body) {
    const titleLines = tooltip.title || [];

    // Хедер
    const tableHeader = document.createElement('div');
    tableHeader.classList.add('graph-tooltip-header');

    titleLines.forEach((title) => {
      const icon = document.createElement('img');
      icon.src = require('@img/icons/calendar.svg');
      icon.alt = 'Icon';
      icon.style.marginRight = '10px';
      icon.width = 14;
      icon.height = 14;

      const headerTextContainer = document.createElement('div');
      let headerText;

      if (metaConfig.tooltipHeaderFormat === 'week') {
        headerText = document.createTextNode(title);
      }

      if (metaConfig.tooltipHeaderFormat === 'month') {
        const columnIndex = tooltip.dataPoints[0].dataIndex;
        const newDay = set(new Date(), {
          year: tooltip.dataPoints[0].dataset.data[columnIndex].year,
          month: 0,
          date: 1,
          hours: 1,
          minutes: 1,
          seconds: 1,
        });
        const dateByMonth = setMonth(
          newDay,
          tooltip.dataPoints[0].dataset.data[columnIndex].month - 1,
        );
        const text = format(dateByMonth, 'LLLL yyyy', { locale: ru });
        headerText = document.createTextNode(text);
      }

      headerTextContainer.classList.add('potok-text-body-3');
      headerTextContainer.classList.add('graph-tooltip-header-text');

      headerTextContainer.appendChild(headerText);
      tableHeader.appendChild(icon);
      tableHeader.appendChild(headerTextContainer);
    });

    // Тело
    const tooltipBody = document.createElement('div');
    tooltipBody.classList.add('graph-tooltip-body');

    const columnIndex = tooltip.dataPoints[0].dataIndex;

    let incomeList = [];
    incomeList = [
      {
        id: 'percentage',
        text: texts?.percentage.name || 'процентный доход',
        color: '#04CCD9',
      },
      {
        id: 'mainDebt',
        text: texts?.mainDebt.name || 'основной долг',
        color: '#173B57',
      },
    ];

    incomeList.forEach((el) => {
      // айтем тела
      const itemContainer = document.createElement('div');
      itemContainer.classList.add('graph-tooltip-item');
      itemContainer.classList.add('mt-2');

      // Кружок в айтеме
      const circle = document.createElement('span');
      circle.classList.add('graph-tooltip-item-circle');
      circle.style.background = el.color;
      circle.style.borderColor = el.color;

      // Лейбл в айтеме
      const bodyLabelContainer = document.createElement('div');
      const labelText = document.createTextNode(el.text);

      // Контейнер для кружка и лейбла
      const bodyItemLeft = document.createElement('div');
      bodyItemLeft.classList.add('graph-tooltip-item-left');
      bodyItemLeft.appendChild(circle);
      bodyItemLeft.appendChild(bodyLabelContainer);

      // value в айтеме
      const bodyValueContainer = document.createElement('div');
      bodyValueContainer.classList.add('graph-tooltip-mobile-value');
      const valueText = document.createTextNode(
        `${formatCurrency(tooltip.dataPoints[0].dataset.data[columnIndex][el.id], 0)} ₽`,
      );

      bodyLabelContainer.appendChild(labelText);
      bodyValueContainer.appendChild(valueText);
      itemContainer.appendChild(bodyItemLeft);
      itemContainer.appendChild(bodyValueContainer);
      tooltipBody.appendChild(itemContainer);
    });

    // Footer
    const tooltipFooter = document.createElement('div');

    if (metaConfig.tooltipFooter) {
      tooltipFooter.classList.add('content_devider');
      tooltipFooter.classList.add('graph-tooltip-footer-container');

      const tooltipFooterContent = document.createElement('div');
      tooltipFooterContent.classList.add('graph-tooltip-footer-content');

      const tooltipFooterTextContainer = document.createElement('div');
      const tooltipFooterText = document.createTextNode(
        metaConfig.tooltipFooterText,
      );

      // Считаем сумму всех айтемов для футера
      const sum =
        tooltip.dataPoints[0].dataset.data[columnIndex].percentage +
        tooltip.dataPoints[0].dataset.data[columnIndex].mainDebt;

      // Создаем элемент для суммы и форматируем его
      const tooltipFooterSumContainer = document.createElement('div');
      tooltipFooterSumContainer.classList.add('graph-tooltip-text-bold');
      tooltipFooterSumContainer.classList.add('graph-tooltip-mobile-value');
      const tooltipFooterSum = document.createTextNode(
        `${formatCurrency(sum, 0)} ₽`,
      );

      tooltipFooterTextContainer.appendChild(tooltipFooterText);
      tooltipFooterSumContainer.appendChild(tooltipFooterSum);
      tooltipFooterContent.appendChild(tooltipFooterTextContainer);
      tooltipFooterContent.appendChild(tooltipFooterSumContainer);
      tooltipFooter.appendChild(tooltipFooterContent);
    }

    const tableRoot = tooltipEl;
    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHeader);
    tableRoot.appendChild(tooltipBody);
    if (metaConfig.tooltipFooter) {
      tableRoot.appendChild(tooltipFooter);
    }
  }

  const { offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  let xPixels = 0;

  // Если мобилка или планшет, то из-за маленького экрана, тултип будет менять свое расположение, что бы не часть не пропадала за экраном
  const columnIndex = tooltip.dataPoints[0].dataIndex;

  if (columnIndex >= chart.data.labels.length / 2) {
    if (isTablet.value || isMobile.value) {
      xPixels = -220;
    } else {
      xPixels = -340;
    }
  }
  if (columnIndex === 0) {
    xPixels = -50;
  }
  xPixels += tooltip.caretX;
  tooltipEl.style.left = `${xPixels}px`;
  tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
};

export default externalTooltipGroupColumnHandler;
