<template>
  <div class="tableitems__wrapper">
    <div v-if="isShowTotalColumns" class="tableitems__item">
      <div class="tableitems__item__totaltitle">
        {{ props.totalColumns.mobileTitle }}
      </div>
      <div class="devider"></div>
      <div
        v-for="(column, column_index) in columnsFilteredTotals"
        :key="column_index"
        class="tableitems__item__element"
      >
        <div
          v-if="column.total_title_mobile"
          class="tableitems__item__element_label"
        >
          {{ column.total_title_mobile }}
        </div>
        <div v-else class="tableitems__item__element_label">
          {{ column.title }}
        </div>
        <div
          class="tableitems__item__element_value"
          :style="getMobileStyle(column)"
          v-html="props.totalColumns[column.slug]"
        ></div>
      </div>
    </div>
    <div
      v-for="(item, index) in props.items"
      :key="index"
      class="tableitems__item"
    >
      <div
        v-for="(column, column_index) in props.columns"
        :key="column_index"
        class="tableitems__item__element"
        :class="{
          tableitems__item__element__full: column.is_mobile_full,
          tabkeitems__item__element__devided: column.is_mobile_devided,
        }"
      >
        <div v-if="column.title_mobile" class="tableitems__item__element_label">
          {{ column.title_mobile }}
        </div>
        <div v-else-if="column.title" class="tableitems__item__element_label">
          {{ column.title }}
        </div>
        <div
          v-if="column.content_mobile"
          class="tableitems__item__element_value"
          v-html="column.content_mobile(item)"
        ></div>
        <div
          v-else
          class="tableitems__item__element_value"
          v-html="column.content_desktop(item)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps(['items', 'columns', 'totalColumns']);

const columnsFilteredTotals = computed(() => {
  if (props.totalColumns) {
    return props.columns.filter((i) => props.totalColumns[i.slug] != undefined);
  }
  return [];
});
const isShowTotalColumns = computed(() => {
  if (props.totalColumns) {
    return Object.keys(props.totalColumns).length > 0;
  }
  return false;
});

const getMobileStyle = (column) => {
  if (
    props.totalColumns &&
    props.totalColumns.mobileStyle &&
    props.totalColumns.mobileStyle[column.slug] != undefined
  ) {
    return props.totalColumns.mobileStyle[column.slug];
  }
};
</script>
<style lang="scss" scoped>
.tableitems__item {
  background-color: white;
  box-shadow: 0px 1px 4px #e5e9f2;
  border-radius: 6px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0px 16px;
  padding-bottom: 10px;

  .tableitems__item__element {
    flex: 1 1 50%;
    border-bottom: 1px solid #f5f6fa;
    padding-top: 12px;
    &:last-child {
      border-bottom: initial;
    }

    .tableitems__item__element_label {
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
      color: $grey-fields;
      margin-bottom: 6px;
    }
    .tableitems__item__element_value {
      padding-bottom: 7px;
      text-transform: none;
    }
  }
  .tabkeitems__item__element__devided {
    display: flex;
    flex: 1 1 100%;
    .tableitems__item__element_label {
      flex: 1;
    }
    .tableitems__item__element_value {
      flex: 1;
    }
  }
}
.tableitems__item__totaltitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  flex: 1 1 100%;
  padding: 16px 0px;
}
.tableitems__item__element__full {
  flex: 2 1 100% !important;
}
.devider {
  width: 100%;
  border-bottom: 1px solid #f5f6fa;
}
</style>
