<template>
  <ModalFull
    :title="currentText?.title"
    :close-click-outside="false"
    @close="emit('close')"
  >
    <template #content>
      <template v-if="loading">
        <Loader />
      </template>
      <template v-if="!loading">
        <div>
          <div v-html="currentText?.description"></div>
        </div>
      </template>
    </template>
    <template v-if="!loading" #actions>
      <PotokButton
        :text="currentText?.confirm"
        :disabled="disabledForm"
        @click="onClickConfrim"
      />
      <PotokButton
        class="btn-cancel"
        :text="currentText?.cancel"
        :disabled="disabledForm"
        theme="tertiary"
        @click="onClickCancel"
      />
    </template>
  </ModalFull>
</template>
<script setup>
import { computed, ref, onMounted } from 'vue';
import { Loader, PotokButton } from 'potok-uikit';
import server from '@/server';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import { useStateStore } from '@/store/stateStore';

const emit = defineEmits(['close', 'change']);
const props = defineProps({
  options: {
    type: Object,
    default: () => {
      return {
        isConnectButton: null,
        selectedStrategy: null,
        isEnhancedDiversification: null,
      };
    },
  },
});

const store = useStateStore();

const disabledForm = ref(false);
const loading = ref(true);
const texts = ref({});

const currentText = computed(() => {
  const selectedStrategy = props.options.selectedStrategy.serverName;
  if (selectedStrategy && texts.value[selectedStrategy]) {
    if (props.options.isEnhancedDiversification) {
      return texts.value[selectedStrategy]?.disableDiversification;
    } else {
      return texts.value[selectedStrategy]?.enableDiversification;
    }
  }
  return null;
});

const initModal = () => {
  loading.value = true;

  const query = {
    companyId: store.selectedCompanyId,
  };

  return server.getFeaturesEnhancedDiversification
    .send(query)
    .pipe(
      (obj) => {
        loading.value = false;
        texts.value = obj.data || {};
      },
      () => {
        loading.value = false;
      },
    )
    .exec();
};

const onClickConfrim = () => {
  emit('change', {
    isClickConfirm: true,
    isConnectButton: props.options.isConnectButton,
    selectedStrategy: props.options.selectedStrategy,
  });
  disabledForm.value = true;
};
const onClickCancel = () => {
  /**
   * если модальное окно было вызвано при нажатие на кнопку изменения
   * диверсификации то просто закрывает окно
   * иначе переходим на подключение новой стратегии с отключенной диверсификацией
   */
  let options = {
    continueConnectStrategy: false,
    isClickCancel: true,
    isConnectButton: props.options.isConnectButton,
  };
  if (props.options.isConnectButton) {
    options.continueConnectStrategy = true;
  }
  if (props.options.selectedStrategy) {
    options.selectedStrategy = props.options.selectedStrategy;
  }

  emit('change', options);
  disabledForm.value = true;
};

onMounted(() => {
  initModal();
});
</script>
<style lang="scss" scoped>
:deep(.modal_block_container) {
  max-width: 420px;
}
:deep(.modal_block_content) {
  text-align: center;
}
:deep(.modal_block_title) {
  justify-content: center;
}
:deep(.modal_block_actions) {
  margin: 20px auto 0;
  display: flex;
  @media (max-width: $size_767) {
    display: block;
    width: 100%;
  }
}
.btn-cancel {
  margin-left: 10px;
  @media (max-width: $size_767) {
    margin-left: initial;
    margin-top: 10px;
  }
}
</style>
