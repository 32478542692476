<template>
  <section class="page alfa-callback">
    <PotokImg loading-icon loading-theme="dark" class="mb-3" />

    данные обрабатываются
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import PotokImg from '@/components/_generic/PotokImg.vue';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';

const route = useRoute();
const router = useRouter();
const store = useStateStore();

const ottTokenQuery = computed(() => {
  return route.query.ott || '';
});

const postAccessByTokenChange = async () => {
  const query = {
    oneTimeToken: ottTokenQuery.value,
  };

  return server.postAccessByTokenChange
    .send(query)
    .pipe(onFetchAccessByTokenChangeSuccess, onFetchAccessByTokenChangeError)
    .exec();
};
const onFetchAccessByTokenChangeSuccess = ({ data }) => {
  store.merge({
    session: {
      token: data.token,
    },
  });
  router.push({
    name: 'main',
  });
};
const onFetchAccessByTokenChangeError = () => {
  window.location.assign('/');
};

const init = () => {
  if (ottTokenQuery.value) {
    postAccessByTokenChange();
  } else {
    onFetchAccessByTokenChangeError();
  }
};
init();
</script>

<style scoped lang="scss">
.alfa-callback {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
