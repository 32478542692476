<template>
  <div>
    <div class="title">Восстановление пароля</div>
    <form class="form" @submit.prevent="">
      <div class="auth-tip">Мы отправим код на номер</div>
      <div class="auth-phone">
        <span>{{ props.rawPhone }}</span>
      </div>
      <template v-if="!hasSmsSent">
        <button
          :disabled="!isValidPhone(props.rawPhone)"
          type="button"
          class="button auth-button"
          style="margin-top: 20px"
          @click="requestRecoveringAsync()"
        >
          Продолжить
        </button>
        <div
          v-show="server.postUsersRecoverPassRequest.isPending"
          class="send-sms"
        >
          <div class="load-icon"></div>
          <span class="send-text">Отправляем код</span>
        </div>
      </template>
      <template v-else>
        <div
          :class="{
            'form-error':
              wasAttemptConfirmationOfSmsCode && !hasSmsCodeConfirmed,
          }"
          class="form-row code-row"
        >
          <input
            id="smsCode"
            v-model="rawSmsCode"
            v-maska="localMasks.smsCode"
            class="form-control"
            :readonly="server.postUsersRecoverPassConfirm.isPending"
            placeholder="Введите проверочный код"
          />
          <div
            v-show="server.postUsersRecoverPassConfirm.isPending"
            class="load"
          >
            <div class="load-icon"></div>
          </div>
        </div>
        <template v-if="hasSmsSent && !wasAttemptConfirmationOfSmsCode">
          <div class="auth-tip">
            На указанный номер телефона было выслано СМС с кодом подтверждения.
          </div>
        </template>
        <template
          v-if="wasAttemptConfirmationOfSmsCode && !hasSmsCodeConfirmed"
        >
          <div class="auth-tip form-error" style="">
            Код неверный. Попробуйте ввести внимательнее
          </div>
        </template>

        <div :class="{ 'form-error': v$.password.$invalid }" class="form-row">
          <input
            id="password"
            v-model="password"
            type="password"
            :readonly="server.postUsersRecoverPassConfirm.isPending"
            placeholder="Новый пароль"
          />
        </div>
        <div
          :class="{ 'form-error': v$.passwordConfirmation.$invalid }"
          class="form-row"
        >
          <input
            id="passwordConfirmation"
            v-model="passwordConfirmation"
            type="password"
            :readonly="server.postUsersRecoverPassConfirm.isPending"
            placeholder="Повторите пароль"
          />
        </div>
        <div class="auth-tip" style="margin-top: 7px">
          Длина пароля должна быть не менее 6 символов. Рекомендуем составлять
          пароль из строчных и заглавных буквы латинского алфавита (A—Z), цифр и
          знаков препинания.
          <br />
          Установить новый пароль вы сможете в личном кабинете.
        </div>

        <button
          :disabled="!isEnableConfirmRecovering"
          type="button"
          class="button auth-button"
          @click="confirmRecoveringAsync()"
        >
          Сохранить пароль
        </button>

        <div class="auth-countdown row row-center">
          <template v-if="leftTime > 0">
            <div class="col-sm-6">
              <div class="countdown-span">Отправить код повторно через</div>
              <div class="countdown-value">
                <span>{{ leftTime }}</span>
              </div>
              <div class="countdown-span">
                секунд<span>{{ pluralize(leftTime, ['у', 'ы', '']) }}</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="col-sm-6">
              <button
                :disabled="!isValidPhone(props.rawPhone)"
                type="button"
                class="button button-link"
                style="color: #999999; font-size: 16px; line-height: 19px"
                @click="requestRecoveringAsync()"
              >
                Отправить код еще раз
              </button>
            </div>
          </template>
        </div>
      </template>
      <div class="auth-bottom">
        <a href="" style="font-size: 16px" @click.prevent="redirectToLogin()"
          >Я вспомнил пароль</a
        >
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import { useRouter } from 'vue-router';

import useVuelidate from '@vuelidate/core';

import server from '@/server';
import { focusDomElementById, pluralize } from '@/utils/commonUtils/utils';
import { convertRawPhoneToLogin } from '@/utils/phone/formatPhone';

import Countdown from '@/utils/classes/Countdown';
import masks from '@/utils/masks';
import validators from '@/validators';
import { useStateStore } from '@/store/stateStore';

const router = useRouter();
const store = useStateStore();

const props = defineProps({
  rawPhone: {
    type: String,
    required: true,
  },
});

const localMasks = computed(() => {
  return {
    phone: masks.phone(),
    smsCode: masks.digits(6),
  };
});
const hasSmsSent = ref(false);
const hasSmsCodeConfirmed = ref(false);
const wasAttemptConfirmationOfSmsCode = ref(false);
const rawSmsCode = ref();
const countdown = new Countdown();
const leftTime = ref(0);
const password = ref();
const passwordConfirmation = ref();

const rulesValidations = {
  password: {
    required: validators.required,
    minLength: validators.minLength(6),
    maxLength: validators.maxLength(32),
    passwordAlpha: validators.passwordAlpha,
  },
  passwordConfirmation() {
    return password.value === passwordConfirmation.value;
  },
  rawSmsCode: {
    required: validators.required,
    exactLength: validators.exactLength(6),
  },
};
const v$ = useVuelidate(rulesValidations, {
  password: password,
  passwordConfirmation: passwordConfirmation,
  rawSmsCode: rawSmsCode,
});

const isEnableConfirmRecovering = computed(() => {
  return !(
    !isValidPhone(props.rawPhone) ||
    v$.value.rawSmsCode.$invalid ||
    v$.value.passwordConfirmation.$invalid ||
    v$.value.password.$invalid
  );
});

onMounted(() => {
  countdown.onTick = (val) => {
    leftTime.value = val;
  };
});

const redirectToLogin = () => {
  router.push({
    name: 'main',
  });
};
const isValidPhone = (phone) => {
  return (
    sanitizePhone(phone).length === 10 || sanitizePhone(phone).length === 11
  );
};
const sanitizePhone = (phone) => {
  return phone.replace(/\D+/g, '');
};
const requestRecoveringAsync = () => {
  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
  };
  return server.postUsersRecoverPassRequest
    .send(query)
    .pipe(onRequestRecoveringAsyncSuccess.bind(this, query))
    .exec();
};
const onRequestRecoveringAsyncSuccess = (query, { data }) => {
  hasSmsSent.value = data.isSuccessful;
  countdown.stop();
  countdown.reset();
  countdown.start();
  nextTick(() => focusDomElementById('smsCode'));
};
const confirmRecoveringAsync = () => {
  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
    smsCode: rawSmsCode.value,
    newPassword: password.value,
  };
  return server.postUsersRecoverPassConfirm
    .send(query)
    .pipe(onConfirmRecoveringAsyncSuccess.bind(this, query))
    .exec();
};
const onConfirmRecoveringAsyncSuccess = (query, { data }) => {
  hasSmsCodeConfirmed.value = data.isSuccessful;
  wasAttemptConfirmationOfSmsCode.value = true;
  if (hasSmsCodeConfirmed.value) {
    countdown.stop();
    store.merge({
      session: {
        login: query.phone,
        token: data.token,
      },
    });
    router.push({
      name: 'main',
    });
  }
};
</script>
<style lang="scss" scoped>
.auth {
  max-width: 500px;
}
.form-row {
  input {
    width: 100%;
  }
}

.auth-tip {
  color: #313132;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.auth-phone {
  color: black;
}

.auth-bottom a {
  &:hover {
    color: #424242;
  }
}

.title {
  color: black;
}
.form {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.form-row {
  label {
    color: black;
  }

  input {
    padding-left: 10px !important;
  }

  // input {
  //   box-shadow: none;
  //   font-size: 20px;
  //   background-color: #fff;
  //   color: black;
  //   &[type="password"] {
  //     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='24' viewBox='0 0 20 24'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M0.25,24 L0.25,8.5959 L3.60957,8.5959 L3.60957,6.76086 C3.60957,3.03214 6.60003,0 10.255,0 C13.9201,0 16.9005,3.04239 16.9005,6.76086 L16.9005,8.5959 L19.75,8.5959 L19.75,24 L0.25,24 Z M15.5698,8.5959 L15.5698,6.76086 C15.5698,3.56482 13.3915,1.5 10.25,1.5 C7.10851,1.5 4.95144,3.56482 4.95144,6.76086 L4.95144,8.5959 L15.5698,8.5959 Z M1.75,22.5 L18.2504,22.5 L18.2601,10 L1.70654,10 L1.75,22.5 Z M9.55029,15.3606 L11.3627,15.3606 L11.3627,17.2045 L9.55029,17.2045 L9.55029,15.3606 Z M7.72768,15.3606 L5.91528,15.3606 L5.91528,17.2045 L7.72768,17.2045 L7.72768,15.3606 Z M13.1851,15.3606 L14.9975,15.3606 L14.9975,17.2045 L13.1851,17.2045 L13.1851,15.3606 Z'/%3E%3C/svg%3E%0A");
  //     background-position: 14px center;
  //     background-repeat: no-repeat;
  //     -webkit-text-fill-color: black;
  //   }

  //   &::placeholder {
  //     opacity: 0.5;
  //     color: black;
  //   }

  //   &::-webkit-input-placeholder {
  //     opacity: 0.5;
  //     color: black;
  //   }

  //   &::-moz-placeholder {
  //     opacity: 0.5;
  //     color: black;
  //   }

  //   &:-moz-placeholder {
  //     opacity: 0.5;
  //     color: black;
  //   }

  //   &:-ms-input-placeholder {
  //     opacity: 0.5;
  //     color: black;
  //   }
  // }
}

.checkbox-row {
  color: black;
}

.countdown-span {
  color: black;
}

.countdown-value {
  color: black;
}
</style>
