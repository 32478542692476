<template>
  <div class="pagination-mobile">
    <div
      v-if="isShowMorePage && !props.isLoading"
      class="pagination-mobile-block"
    >
      <button class="button-page" type="button" @click="onClickNextPage()">
        Показать еще
      </button>
    </div>
    <template v-if="props.isLoading">
      <div class="icon-loading-container mb-2">
        <Loader />
      </div>
    </template>
    <div class="pagination-mobile-block">
      <div>
        {{ currentPageRangeMobile }} из {{ props.totalItemsCount }}
        {{
          pluralize(props.totalItemsCount, [
            'инвестиции',
            'инвестиций',
            'инвестиций',
          ])
        }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { Loader } from 'potok-uikit';
import { pluralize } from '@/utils/commonUtils/libUtils';

const props = defineProps({
  totalItemsCount: {},
  currentPage: {},
  selectedPageRange: {},
  isLoading: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:currentPage', 'update:selectedPageRange']);

const localSelectedPageRange = ref(null);

onMounted(() => {
  localSelectedPageRange.value = props.selectedPageRange;
});

const isShowMorePage = computed(() => {
  if (totalPageCount.value > 1 && props.currentPage < totalPageCount.value) {
    return true;
  }
  return false;
});
const lastPageRangeMobile = computed(() => {
  return props.currentPage * props.selectedPageRange;
});
const currentPageRangeMobile = computed(() => {
  let lastPage = lastPageRangeMobile.value;
  if (props.totalItemsCount < lastPageRangeMobile.value) {
    lastPage = props.totalItemsCount;
  }
  return `1-${lastPage}`;
});
const totalPageCount = computed(() => {
  return Math.ceil(props.totalItemsCount / props.selectedPageRange);
});

const onClickNextPage = () => {
  if (props.currentPage + 1 <= totalPageCount.value) {
    emit('update:currentPage', props.currentPage + 1);
  }
};
</script>
<style lang="scss" scoped>
.pagination-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  color: #7d7d7e;
  font-weight: 400;
  margin-bottom: 20px;
  .pagination-mobile-block {
    width: 100%;
    text-align: center;
  }
  .button-page {
    padding: 10px;
    color: black;
    height: 36px;
    border: none;
    font: inherit;
    cursor: pointer;
    background: #ebedf4;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    justify-content: center;
    margin-bottom: 16px;
  }
}
.icon-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
