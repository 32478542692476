<template>
  <div class="header_menu_mobile">
    <div class="header__nav">
      <button
        v-if="isUserLoaded && isUserRegistrated"
        class="icon_present_button_wrapper"
        @click="onClickReferral"
      >
        <div class="icon_present_button"></div>
      </button>

      <router-link
        :to="{ name: 'notifications' }"
        class="icon_notification_wrapper"
      >
        <i class="icon_notification" />
        <template v-if="notification.counter > 0">
          <div class="notification__counter">{{ notification.counter }}</div>
        </template>
      </router-link>
      <a
        :class="{ 'mob-menu-shown': isMenuShow }"
        href=""
        class="mob_menu_opener"
        @click.prevent="isMenuShow = !isMenuShow"
      >
      </a>
    </div>
    <ModalFull
      v-if="isMenuShow"
      @close="isMenuShow = !isMenuShow"
      @clickaway="onClickaway"
    >
      <template #content>
        <div>
          <div>
            <div v-if="isHasCompaniesRoot" class="title_panel__menus1">
              <div
                class="selected_input"
                :class="{
                  selected_input__active: isShowCompanyList,
                }"
                @click.prevent.stop="toggleCompanyMenu"
              >
                <div style="overflow: hidden; text-overflow: ellipsis">
                  {{ selectedCompany.shortName }}
                </div>
                <div
                  :class="{
                    menuselect__arrow_down_grey: !isShowCompanyList,
                    menuselect__arrow_up_grey: isShowCompanyList,
                  }"
                ></div>
              </div>
            </div>
            <div
              v-if="isHasCompaniesRoot && isShowCompanyList"
              class="companies_list"
            >
              <CompaniesList
                :companies-root="companiesRoot"
                @choose-company-by-id="onClickChooseCompany"
                @add-investor="onClickAddCompany"
              />
            </div>
          </div>

          <div style="margin-top: 16px; position: relative">
            <div
              v-if="selectedCompany"
              class="selected_input"
              @click.prevent.stop="toggleAccountMenu"
            >
              <div class="selected_account_text">
                {{ getAccountName(selectedCompany) }}
              </div>

              <div
                :class="{
                  menuselect__arrow_down_grey: !isShowAccountList,
                  menuselect__arrow_up_grey: isShowAccountList,
                }"
              ></div>
            </div>
            <div v-if="isShowAccountList" class="account_list">
              <AccountsList
                :account-list="accountList"
                @choose-company-by-id="onClickChooseCompany"
                @add-account="onClickAddAccount"
              />
            </div>
          </div>
          <ul class="header__menu">
            <li class="menu__item">
              <router-link
                :to="{ name: 'main' }"
                class="menu__link"
                active-class="menu__link__active"
                @click.prevent.stop="isMenuShow = false"
                >рабочий стол</router-link
              >
            </li>
            <li v-if="isUserRegistrated" class="menu__item">
              <router-link
                :to="{ name: 'loans' }"
                class="menu__link"
                active-class="menu__link__active"
                @click.prevent.stop="isMenuShow = false"
                >новые займы</router-link
              >
            </li>
            <li v-if="isUserRegistrated" class="menu__item">
              <router-link
                :to="{ name: 'investments' }"
                class="menu__link"
                active-class="menu__link__active"
                @click.prevent.stop="isMenuShow = false"
                >мои инвестиции</router-link
              >
            </li>
            <li v-if="isUserLoaded && isUserRegistrated" class="menu__item">
              <router-link
                :to="{ name: 'analytics' }"
                class="menu__link"
                active-class="menu__link__active"
                @click.prevent.stop="isMenuShow = false"
                >аналитика</router-link
              >
            </li>
          </ul>
        </div>
      </template>
      <template #actions>
        <ul class="header__menu">
          <li class="menu__item">
            <router-link
              :to="{ name: 'settings' }"
              class="menu__link"
              @click.prevent.stop="isMenuShow = false"
            >
              <i class="icon_settings_outline" style="margin-right: 9px"></i>
              настройки
            </router-link>
          </li>
          <li class="menu__item">
            <a
              href="#"
              class="menu__link"
              style="color: #ff5754"
              @click.prevent="emit('logout')"
              @click.prevent.stop="isMenuShow = false"
            >
              <i class="icon_logout_red" style="margin-right: 12px"></i>
              выход
            </a>
          </li>
        </ul>
      </template>
    </ModalFull>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';

import headerComposable from '@/composables/headerComposable';
import { useStateStore } from '@/store/stateStore';
import AccountsList from './accounts/AccountsList.vue';
import CompaniesList from './companies/CompaniesList.vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

const emit = defineEmits(['logout', 'chooseCompanyById', 'addAccount']);
const store = useStateStore();

const { isHasCompaniesRoot, addInvestor, getAccountName } = headerComposable();
const isMenuShow = ref(false);
const isShowCompanyList = ref(false);
const isShowAccountList = ref(false);

const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const isUserRegistrated = computed(() => {
  return store.isUserRegistrated;
});
const companiesRoot = computed(() => {
  return store.companiesRoot;
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});
const notification = computed(() => {
  return store.state.menuNotification;
});
const accountList = computed(() => {
  return store.getAccountList;
});

const onClickChooseCompany = (item) => {
  emit('chooseCompanyById', item.id);
  isShowCompanyList.value = false;
  isShowAccountList.value = false;
};

const onClickaway = () => {
  isShowCompanyList.value = false;
  isShowAccountList.value = false;
};

const onClickAddCompany = () => {
  isShowCompanyList.value = false;
  isShowAccountList.value = false;
  isMenuShow.value = false;
  return addInvestor();
};

const onClickAddAccount = () => {
  isShowCompanyList.value = false;
  isShowAccountList.value = false;

  emit('addAccount', {
    callback: () => {
      isMenuShow.value = false;
    },
  });
};

const toggleCompanyMenu = () => {
  isShowCompanyList.value = !isShowCompanyList.value;
  isShowAccountList.value = false;
};

const toggleAccountMenu = () => {
  isShowAccountList.value = !isShowAccountList.value;
  isShowCompanyList.value = false;
};

const onClickReferral = () => {
  store.state.modals.isShowModalRefferal = true;
};
</script>
<style lang="scss" scoped>
.header__nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  float: right;
  @media (min-width: 991px) {
    display: none;
  }
}

.header__title_panel {
  background: #ffffff;
  padding: 30px 16px;
  position: relative;
  .title_panel__login {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #313132;
  }
}

.header__nav__opened {
  position: fixed;
  top: 0px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: none;
  background: #f5f6fa;
  color: #313132;
  text-transform: none;
}
.header__menu {
  float: left;
  margin-top: 9px;
  margin-left: 60px;
  padding-left: 10px;
  display: flex;
  @media (max-width: 991px) {
    float: none;
    margin: 0;
    flex-direction: column;
  }
  .menu__item {
    position: relative;
    display: block;
    margin-right: 0;
    padding: 13px 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: $deep_dive;
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
    }

    .mobile__icon {
      display: none;
      @media (max-width: 991px) {
        display: block;
        position: absolute;
        top: 13px;
        right: 0;
        opacity: 0.5;
        color: #ffffff;
        font-size: 20px;
        pointer-events: none;
      }
    }
  }
  .menu__link {
    color: $deep_dive;
    text-decoration: none;
  }
}
.header__menulist__items {
  left: 0;
  width: 100%;
  padding-bottom: 10px;
}
.header__nav__open {
  display: block !important;
}
.icon_settings {
  display: block;
  width: 19px;
  height: 20px;
  background: url('@img/icons/settings_outline.svg') no-repeat;
  background-size: cover;
  padding: 0;
  margin: 10px 30px 0 0;
  float: right;
}
.icon_notification_wrapper {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f2f4f8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 11px 10px 0 0;
}
.icon_notification {
  display: block;
  width: 17px;
  height: 18px;
  background: url('@img/icons/header/notification.svg') no-repeat;
  background-size: contain;
  border: none;
  padding: 0;
}
.notification__counter {
  position: absolute;
  top: -3px;
  right: -5px;
  color: #ffffff;
  font-size: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  background: #ee3d48;
  animation: breathe 2s infinite;
}
.menu-close {
  background: url('@img/close-grey.svg') center center no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  float: right;
  position: absolute;
  top: 15px;
  right: 15px;
}

.title_panel__menus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 19px;
  padding: 0px;
  @media (max-width: 370px) {
    padding-right: 40px;
  }
}
.selected_input {
  position: relative;
  padding-left: 10px;
  border: 1px solid #dce0e3;
  border-radius: 8px;
  height: 44px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $deep_dive;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-right: 20px;
}

.selected_input__active {
  opacity: 0.6;
}
.selected_account {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #313132;
  height: 36px;
  border: 1px solid #dedee1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #313132;
  cursor: pointer;
}
.selected_account_text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.menuselect__arrow_down_grey {
  position: absolute;
  top: 16px;
  right: 11px;
  width: 8px;
  height: 8px;
  background: url('@img/icons/arrow-down-grey.svg') no-repeat;
  background-size: contain;
}
.menuselect__arrow_up_grey {
  position: absolute;
  top: 16px;
  right: 11px;
  width: 8px;
  height: 8px;
  transform: rotate(180deg);
  background: url('@img/icons/arrow-down-grey.svg') no-repeat;
  background-size: contain;
}

:deep(.companies_list) {
  .header__menulist__item_button {
    height: 32px;
    width: 32px;
  }

  .header__menulist_second_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-overflow: initial !important;
    overflow: initial !important;
  }
}
:deep(.account_list) {
  top: 56px;
  width: 100%;
}
.icon_present_button_wrapper {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f2f4f8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 11px 10px 0 0;
  border: 0;
}
.icon_present_button {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
</style>
