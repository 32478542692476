<template>
  <div class="balances_money">
    <div>
      <div class="content_title">
        <div>
          <VDropdown placement="bottom-start">
            <div class="title-icon content_title_text">денежные средства</div>
            <template #popper>
              <div class="popup">
                <div v-close-popper class="popup-close"></div>
                <div class="popup-text">
                  Сумма денежных средств в статусе: Свободно, Копилка и Резерв.
                </div>
              </div>
            </template>
          </VDropdown>
          <MoneyValue
            :value="getMoney"
            :rounding="false"
            class="potok-text-h2 mt-2"
          />
        </div>

        <div><i class="icon_purse"></i></div>
      </div>

      <div class="content_block_items">
        <div class="item_row" style="margin-top: 10px">
          <div class="item_title potok-text-body-2 color-deepdive">
            <VDropdown placement="bottom-start">
              <div class="title-icon">свободно</div>
              <template #popper>
                <div class="popup">
                  <div v-close-popper class="popup-close"></div>
                  <div class="popup-text">
                    Сумма, доступная для ручного и автоматического
                    инвестирования, переноса в Копилку или вывода.
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
          <div
            class="balance-money-free potok-text-body-1 color-deepdive"
            style="margin-top: 5px"
          >
            <MoneyValue
              :value="store.getFreeBalance"
              :rounding="false"
              class="potok-text-body-1"
            />
            <BonusFromBalance
              v-if="bonusFromBalanceData && isBonusFromBalanceActive"
              :active="
                isBonusFromBalanceActive &&
                isCurrentStrategyIncludedBonus &&
                bonusFromBalanceData.isShowIcon
              "
              :loading="loading"
            />
          </div>
          <div class="content_devider" style="margin-top: 12px"></div>
        </div>
        <div class="item_row">
          <div class="item_title potok-text-body-2 color-deepdive">
            <VDropdown placement="bottom-start">
              <div class="title-icon">копилка</div>
              <template #popper>
                <div class="popup">
                  <div v-close-popper class="popup-close"></div>
                  <div class="popup-text">
                    Сумма, доступная для ручного инвестирования и вывода, но
                    недоступная для АИ. Целевое значение:
                    <MoneyValue
                      :value="store.getCashboxLimitAmount"
                      :rounding="false"
                      class="potok-text-body-1"
                    />
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
          <div class="item_value potok-text-body-1 color-deepdive">
            <button
              class="button button-link"
              :to="{
                name: 'investorCashboxChooseOwnership',
              }"
              style="display: flex; align-items: center"
              @click="onClickCashboxSetting"
            >
              <div style="display: flex; align-items: center; padding: 5px 0px">
                <MoneyValue
                  :value="store.getCashboxRealAmount"
                  :rounding="false"
                  color="#04CCD9"
                  comma-color="#04CCD9"
                  class="potok-text-body-1"
                />
              </div>
            </button>
          </div>
          <div class="content_devider" style="margin-top: 12px"></div>
        </div>
        <div class="item_row">
          <div class="item_title potok-text-body-2 color-deepdive">
            <VDropdown placement="bottom-start">
              <div class="title-icon">резерв</div>
              <template #popper>
                <div class="popup">
                  <div v-close-popper class="popup-close"></div>
                  <div class="popup-text">
                    Сумма, зарезервированная – в займы на сборе или для покупок
                    на вторичном рынке.
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
          <div
            class="item_value potok-text-body-1 color-deepdive"
            style="margin-top: 5px"
          >
            <MoneyValue
              :value="store.getReserved"
              :rounding="false"
              class="potok-text-body-1"
            />
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 10px">
      <button
        type="button"
        class="button button-secondary-outline button-balances"
        :disabled="store.selectedCompany?.investmentApproved === false"
        @click="emit('depositMoney')"
      >
        пополнить
      </button>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import { useStateStore } from '@/store/stateStore';
import { formatCurrency } from '@/utils/commonUtils/utils';
import BonusFromBalance from '@/components/_generic/BonusFromBalance.vue';
import server from '@/server';
import MoneyValue from "@/components/_generic/MoneyValue";

const emit = defineEmits(['showCashboxSetting', 'depositMoney']);
const store = useStateStore();

const loading = ref(false);

const isBonusFromBalanceActive = computed(() => {
  return store.state.features.general.remainingBalanceInterestPromo.enabled;
});

const getMoney = computed(() => {
  return store.getFreeBalance + store.getCashboxRealAmount + store.getReserved;
});
const selectedStrategy = computed(() => {
  return store.selectedCompany?.autoinvestSettings.strategy;
});
const isCurrentStrategyIncludedBonus = computed(() => {
  return bonusFromBalanceData.value?.eligibleStrategies.includes(
    selectedStrategy.value,
  );
});
const bonusFromBalanceData = computed(() => {
  return store.state.features?.bonusFromBalance;
});

const onClickCashboxSetting = () => {
  emit('showCashboxSetting');
};
</script>
<style lang="scss" scoped>
.button-balances {
  position: absolute;
  bottom: 10px;
}
.balances_money {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .balance-money-free {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: $size_767) {
    padding-bottom: 10px;
  }
}
</style>
