<template>
  <section class="page">
    <div class="project">
      <div v-if="loadingProjectId" class="project__content">
        <Loader style="margin: 20px" />
      </div>
      <div v-if="!loadingProjectId && !data.project" class="project__content">
        <p>не удалось получить данные по займу</p>
      </div>
      <div v-if="!loadingProjectId && data.project">
        <div class="project__header">
          <div class="page_title">
            заем
            <span class="header__subtitle">{{ data.project.number }}</span>
          </div>

          <LoanTags :project="data.project" />
        </div>
        <div class="project__content">
          <div class="row" style="display: flex">
            <div class="col-md-6 col-sm-8 col-xs-12">
              <div class="row">
                <div class="col-md-6 col-xs-12 item_name">заемщик</div>
                <div
                  v-if="data.project.borrower"
                  class="col-md-6 col-xs-12 item_value"
                >
                  <span>{{ data.project.borrower.shortName }}</span>
                </div>
              </div>
              <div class="item_devider only_mobile"></div>
              <div class="row" style="margin-top: 5px">
                <LoanProgress :project="data.project" :status="getStatus" />
              </div>
            </div>
            <div
              v-if="isEligibleForInvestment"
              class="col-md-6 col-sm-4 col-xs-12 invest_button_desktop"
            >
              <button
                v-if="!isSelectedCompanyHaveAlreadyInvested"
                type="button"
                class="button button-secondary"
                @click="
                  depositMoney({
                    depositOperationId: constants.depositOperations.investment,
                    projectId: data.project.id,
                    trackerType: data.trackerTypeDependsOnTab[tabComponentName],
                  })
                "
              >
                инвестировать
              </button>
            </div>
          </div>
        </div>

        <div v-if="isEligibleForInvestment" class="row invest_button_mobile">
          <div class="col-xs-12">
            <button
              v-if="!isSelectedCompanyHaveAlreadyInvested"
              type="button"
              class="button button-secondary"
              @click="
                depositMoney({
                  depositOperationId: constants.depositOperations.investment,
                  projectId: data.project.id,
                  trackerType: data.trackerTypeDependsOnTab[tabComponentName],
                })
              "
            >
              инвестировать
            </button>
          </div>
        </div>

        <LoanBanner2kk v-if="isPotokHoldingProject" class="mb-7" />

        <div
          v-if="data.project.investments && data.project.investments.length > 0"
          class="project__content"
        >
          <div class="row mb-12">
            <div class="col-md-12 project__title" style="margin-bottom: 6px">
              ваши инвестиции
            </div>
          </div>
          <div class="row mb-12">
            <div class="col-md-12">
              <GeneralTable
                :items="filterInvestment(data.project.investments)"
                :columns="columnsInvestment"
                class="project__table"
              />
            </div>
            <div
              v-if="
                data.project.investments.length > MAX_INVESTMENT_ITEMS &&
                !isShowAllInvesntment
              "
              class="show_extra_investments"
              @click="isShowAllInvesntment = !isShowAllInvesntment"
            >
              показать еще
              {{ data.project.investments.length - MAX_INVESTMENT_ITEMS }}
              инвестиций
              <i class="fa-angle-up fa fa-angle-down"></i>
            </div>
            <div
              v-if="
                data.project.investments.length > MAX_INVESTMENT_ITEMS &&
                isShowAllInvesntment
              "
              class="show_extra_investments"
              @click="isShowAllInvesntment = !isShowAllInvesntment"
            >
              скрыть последние
              {{ data.project.investments.length - MAX_INVESTMENT_ITEMS }}
              инвестиции
              <i class="fa-angle-up fa fa-angle-up"></i>
            </div>
          </div>
        </div>

        <PotokTabs
          v-if="!isMobile && data.project"
          :items="filteredTabItems"
          @change-tab="changeTab"
        />
        <LoanTabsMobile
          v-if="isMobile && data.project"
          :current-component="tabComponentName"
          :status-id="data.project.statusId"
          @change-tab="changeTab($event, true)"
        />

        <div>
          <component
            :is="selectedTabComponent"
            :project="data.project"
            :status="getStatus"
          >
          </component>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, reactive, watch, onMounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import tracker from '@/tracker';
import server from '@/server';
import storage from '@/utils/localStorage/storage';
import { useStateStore } from '@/store/stateStore';
import constants from '@/constants';

import detectDevice from '@/composables/detectDeviceComposable';
import {
  formatCurrency,
  formatDate,
  getProjectStatus,
} from '@/utils/commonUtils/utils';

import { Loader } from 'potok-uikit';
import GeneralTable from '@/components/_generic/table/GeneralTable.vue';
import { PotokTabs } from 'potok-uikit'

import LoanTags from '../components/Loan/LoanTags.vue';
import LoanProgress from '../components/Loan/LoanProgress.vue';
import LoanParams from './Loan/LoanParams.vue';
import LoanAboutBorrower from './Loan/LoanAboutBorrower.vue';
import LoanHistory from './Loan/LoanHistory.vue';
import LoanOverdue from './Loan/LoanOverdue.vue';
import LoanTabsMobile from '../components/Loan/LoanTabs/LoanTabsMobile.vue';
import LoanTabsDesktop from '../components/Loan/LoanTabs/LoanTabsDesktop.vue';
import LoanBanner2kk from '@/components/Loan/banners/LoanBanner2kk.vue';

const MAX_INVESTMENT_ITEMS = 3;

const columnsInvestment = [
  {
    title: 'инвестиция',
    is_mobile_full: true,
    th_desktop_style: {
      width: '25%',
    },
    content_desktop(item) {
      return `
      <a href="/investment/${item.id}"
        target="_blank" style="color: #04CCD9">${item.number}</a>
      `;
    },
  },
  {
    title: 'дата инвестиции',
    is_mobile_full: true,
    th_desktop_style: {
      width: '25%',
    },
    content_desktop(item) {
      return `
      ${formatDate(item.createdAt)}
      `;
    },
  },
  {
    title: 'сумма инвестирования',
    is_mobile_full: true,
    th_desktop_style: {
      width: '25%',
    },
    content_desktop(item) {
      return `
      <span>${formatCurrency(item.amount, 0)} </span>
      ₽
      `;
    },
  },
  {
    title: 'статус',
    is_mobile_full: true,
    th_desktop_style: {
      width: '25%',
    },
    content_desktop(item) {
      return `
      ${investmentStatus(item.status, item.isRestructured)}
      `;
    },
  },
];

const { isMobile } = detectDevice();
const store = useStateStore();
const router = useRouter();
const route = useRoute();

const loadingProjectId = ref(false);

const tabComponentName = ref('LoanParams');
const tabItems = ref([
  {
    tabComponentName: 'LoanParams',
    text: 'параметры займа',
    isActive: true,
  },
  {
    tabComponentName: 'LoanOverdue',
    text: 'события цессии',
    isActive: false,
  },
  {
    tabComponentName: 'LoanAboutBorrower',
    text: 'о заемщике',
    isActive: false,
  },
  {
    tabComponentName: 'LoanHistory',
    text: 'история в Потоке',
    isActive: false,
  },
])

const filteredTabItems = computed(() => {
  return tabItems.value.filter((el) => {
    if (el.tabComponentName !== 'LoanOverdue') {
      return true;
    } else {
      if (hasStatus.value) {
        return true;
      }
    }

    return false;
  })
})

const hasStatus = computed(() => {
  const ids = [
    constants.projectStatus.cession1st,
    constants.projectStatus.cession2nd
  ]
  return ids.indexOf(data.project.statusId) !== -1;
});

const changeTab = (item, isTabsMobile = false) => {
  if (isTabsMobile) {
    sendBorrowerTrackerInfo(item)
    tabComponentName.value = item;
    return;
  }

  const itemIndex = tabItems.value.findIndex((el) => el.tabComponentName === item.tabComponentName);
  tabItems.value.forEach((el) => {
    el.isActive = false;
  })

  sendBorrowerTrackerInfo(item.tabComponentName)

  tabItems.value[itemIndex].isActive = true;
  tabComponentName.value = item.tabComponentName;
};

const sendBorrowerTrackerInfo = (item) => {
  if (item === 'BorrowerData') {
    tracker.queue(
      tracker.commands.SEND,
      'Investor_LK_My_Account_Borrower_Info',
    );
  }
}

const isShowAllInvesntment = ref(false);

const data = reactive({
  trackerTypeDependsOnTab: {
    LoanParams: 'investFromLoanDescription',
    AboutBorrower: 'investFromAboutBorrower',
    Overdue: 'investFromOverdue',
    History: 'investFromHistory',
  },
  project: {},
});

const tabComponents = {
  LoanParams,
  LoanAboutBorrower,
  LoanHistory,
  LoanOverdue,
};

const selectedTabComponent = computed(() => {
  return tabComponents[tabComponentName.value];
});
const getStatus = computed(() => {
  const projectIsRising = [
    'checking',
    'needPassport',
    'declined',
    'approved',
    'canceledBeforeFundsStart',
    'canceled',
    'risingFunds',
    'risingTimeout',
  ];

  const projectIsRefunded = [
    'funded',
    'overdue',
    'refunded',
    'cession1st',
    'cession2nd',
    'cessionFixed',
    'approvedWaitOption',
    'issuing',
  ];

  if (
    projectIsRising.includes(constants.projectStatusSlug[data.project.statusId])
  ) {
    if (data.project.amount !== data.project.collected) {
      return 'risingFunds';
    }
    if (data.project.amount === data.project.collected) {
      return 'risingFundsFull';
    }
  }
  if (
    projectIsRefunded.includes(
      constants.projectStatusSlug[data.project.statusId],
    )
  ) {
    if (data.project.amount !== data.project.refunded) {
      return 'funded';
    }
    if (data.project.amount === data.project.refunded) {
      return 'fundedFull';
    }
  }
  return 'funded';
});
const selectedCompanyId = computed(() => {
  return store.selectedCompanyId;
});

const isPotokHoldingProject = computed(() => {
  return data.project.isPotokHoldingBorrower;
});

const isEligibleForInvestment = computed(() => {
  let collected = data.project.collected;

  if (data.project.pifReservedAmount && data.project.pifReservedAmount > 0) {
    collected = collected - data.project.pifReservedAmount;
  }
  if (
    store.isUserLoaded &&
    constants.projectStatusSlug[data.project.statusId] === 'risingFunds' &&
    collected !== data.project.amount
  ) {
    return true;
  }
  return false;
});
const isSelectedCompanyHaveAlreadyInvested = computed(() => {
  if (!data.project) {
    return true;
  }
  return data.project.investments.find(
    (item) =>
      item.companyId === selectedCompanyId.value && item.isConfirmed === true,
  );
});
watch(
  () => {
    return route.params.id;
  },
  () => {
    fetchProjectAsync();
  },
);
watch(selectedCompanyId, () => {
  fetchProjectAsync();
});

onMounted(() => {
  fetchProjectAsync();
});

const investmentStatus = (status, isRestructured) => {
  const investmentStatusTitle = constants.investmentStatusTitle;
  let statusTitle;
  if (isRestructured) {
    statusTitle = 'Реструктурирован';
  } else {
    statusTitle = investmentStatusTitle[status];
  }
  let statusClass = status;
  if (status === 'cession' && !isRestructured) {
    statusClass = 'default';
  }

  return `
  <div class="investment_status">
    <div class="investment_status__value status_${statusClass}">
      ${statusTitle}</div>
  </div>
  `;
};

const makeProjectViewed = () => {
  /**
   * Сохраняем в хранилеще состояние просмотренности займа
   */
  if (!data.project || !data.project.id) {
    return;
  }
  let items = window.localStorage.getItem('projects');
  if (items) {
    items = JSON.parse(items);
  } else {
    items = {};
  }
  items[data.project.id] = true;
  storage.setProjects(items);
};
const fetchProjectAsync = () => {
  if (route.name !== 'loan') {
    return;
  }
  if (!route.params.id || !selectedCompanyId.value || loadingProjectId.value) {
    return;
  }
  loadingProjectId.value = true;
  const query = {
    companyId: selectedCompanyId.value,
  };
  const params = {
    id: route.params.id,
  };
  return server.getProjectIdNew
    .send(query, { params })
    .pipe(onFetchProjectAsyncSuccess, () => {
      loadingProjectId.value = false;
    })
    .exec();
};
const onFetchProjectAsyncSuccess = (obj) => {
  loadingProjectId.value = false;

  if (!obj || !obj.data) {
    return;
  }
  const result = obj.data;
  data.project = {
    ...result,
    ...getProjectStatus(result),
  };
  data.project.investments.forEach((i) => {
    i.isRestructured = data.project.isRestructured;
  });
  store.merge({
    buffer: {
      project: {
        borrower: {
          id: result.borrower.id,
        },
      },
    },
  });
  makeProjectViewed();
};
const depositMoney = ({ depositOperationId, projectId, trackerType }) => {
  tracker.queue(tracker.commands.SEND, trackerType);

  store.merge({
    buffer: {
      depositOperationId,
      investorCompanyId: selectedCompanyId.value,
      project: {
        id: projectId,
      },
    },
  });
  router.push({
    name: 'loanInvestment',
    params: {
      id: projectId,
    },
  });
};
const filterInvestment = (items) => {
  if (items.length <= MAX_INVESTMENT_ITEMS) {
    return items;
  }
  if (!isShowAllInvesntment.value) {
    return items.slice(0, MAX_INVESTMENT_ITEMS);
  }
  return items;
};
</script>
<style lang="scss" scoped>
.investment_status {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 0px 6px 0 0px;
  .investment_status__value {
    display: inline-block;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    padding: 4px 8px;
    line-height: 12px;
    margin: 0 2px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    @media (max-width: $size_767) {
      margin: 0;
    }
  }
  .status_reserved {
    color: #4b64c4;
    background-color: #edf0f9;
  }
  .status_active {
    color: #04ccd9;
    background-color: #e7f8f7;
  }
  .status_refunded {
    color: #4b64c4;
    background-color: #edf0f9;
  }
  .status_cession {
    color: #ffc11e;
    background-color: #fff9e6;
  }
  .status_default {
    color: #ee3d48;
    background-color: rgba(238, 61, 72, 0.1);
  }
}
.project {
  position: relative;
  margin-bottom: 40px;
  padding: 0px;
  text-transform: none;
  @media (max-width: $size_767) {
    padding: 0;
    margin: 14px 0 20px 0;
  }

  .project__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .header__subtitle {
      color: #7d7d7e;
      margin-right: 10px;
    }
  }

  :deep(.item_row_title) {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: $deep_dive;
    margin: 20px 0;
    max-width: 860px;
    @media (max-width: $size_767) {
      font-size: 14px;
      margin: 17px 0;
    }
  }

  :deep(.item_row) {
    margin-bottom: 12px;
    @media (max-width: $size_767) {
      margin-bottom: 4px;
    }
  }

  :deep(.item_name) {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $grey;
    padding-right: 0;
    display: flex;
    align-items: center;
    @media (max-width: $size_767) {
      font-size: 14px;
      line-height: 20px;
      color: $grey-fields;
    }
    p {
      margin: 0;
    }
    .v-popper {
      margin-left: 5px;
    }
  }

  :deep(.item_value) {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $deep_dive;
    word-break: break-word;
    @media (max-width: $size_767) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  :deep(.project__content) {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 1px 4px #e5e9f2;
    border-radius: 6px;
    margin: 30px 0;
    padding: 20px 20px 20px 20px;

    .tableitems__item {
      box-shadow: none;
      margin-bottom: 0;
    }

    @media (max-width: $size_767) {
      padding: 16px;
    }

    .project__title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.01em;
      color: $deep_dive;
      @media (max-width: $size_767) {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 7px;
      }
    }

    .investment_status__value {
      @media (max-width: $size_767) {
        background-color: transparent !important;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  :deep(.item_devider) {
    border-bottom: 1px solid #ebedf4;
    margin: 30px 0;
    @media (max-width: $size_767) {
      margin: 17px 0;
    }
  }

  .show_extra_investments {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #04ccd9;
    cursor: pointer;
    margin-left: 20px;
    margin-top: 20px;
  }

  .invest_button_desktop {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: $size_767) {
      display: none;
    }
  }

  .invest_button_mobile {
    margin-bottom: 20px;
    @media (min-width: $size_767) {
      display: none;
    }
    button {
      width: 100%;
    }
  }
}
</style>
