<template>
  <div class="form-input-money">
    <label class="form-input-money-label" :for="`input-${uuid}`">
      <input
        :id="`input-${uuid}`"
        v-model="value"
        v-maska="props.vmaska"
        v-money3="moneyOptions"
        class="form-input-money-input"
        type="text"
        :disabled="props.disabled"
        :placeholder="props.placeholder"
        @input="inputValue"
      />

      <slot name="inner-icon" />
    </label>
  </div>
</template>
<script setup>
import { computed, reactive } from "vue";
import { Money3Directive as vMoney3 } from 'v-money3';

import { uuidv4 } from '@/utils/commonUtils/utils';

const props = defineProps({
  modelValue: [String, Number],
  vmaska: [Object, Function, String],
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: String,
  validate: Object,
  input: Function,
});
const emit = defineEmits(['update:modelValue', 'inputValue']);

const moneyOptions = {
  decimal: '.',
  thousands: ' ',
  prefix: '',
  suffix: ' ₽',
  precision: 0,
  masked: true,
  minimumNumberOfCharacters: 0,
  disableNegative: true,
};
const uuid = uuidv4();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const inputValue = () => {
  props.validate ? props.validate.$touch() : null;
  emit('inputValue');
};
</script>
<style lang="scss" scoped>
label {
  position: relative;
  width: 100%;
}

.form-input-money-input {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $deep_dive;
  display: block;
  width: 100%;
  height: 60px;
  padding: 6px 20px;
  background-color: $background;
  border-radius: 8px;
  border: 0px;
  &:disabled {
    color: $grey;
  }
}

.form-input-money-input[data-mask-raw-value='0'] {
  color: $grey;
}

.form_input__input__error {
  width: 300px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $red;
}
</style>
