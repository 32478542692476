<template>
  <button
    class="bonus-from-balance-btn"
    :disabled="props.loading"
    type="button"
    v-bind="$attrs"
    @click="showBanner"
  >
    <template v-if="!props.loading">
      <PotokImg
        class="mr-1"
        height="12"
        width="12"
        :src="
          props.active
            ? imageConstants.bonusFromBalance
            : imageConstants.bonusFromBalanceGrey
        "
      />
      <span>{{ bonusFromBalance?.promoModal?.percentageIncreaseText }}</span>
    </template>
    <template v-else>
      <PotokImg
        loading-icon
        loading-theme="dark"
        loading-border="2"
        loading-height="15"
        loading-width="15"
      />
    </template>
  </button>
</template>

<script setup>
import { computed } from 'vue';
import imageConstants from '@/constants/imageConstants';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
});

const bonusFromBalance = computed(() => {
  return store.state.features?.bonusFromBalance;
});

const showBanner = () => {
  store.state.modals.isShowBonusFromBalanceModal = true;
  store.state.modals.isActiveBonusFromBalanceModal = props.active;
};
</script>

<style scoped lang="scss">
.bonus-from-balance-btn {
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 2px 10px 2px 8px;
  border-radius: 6px;
  background-color: $background;
  font-weight: 500;
  color: $breakwater;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.01em;
}
</style>
