<template>
  <div
    class="new-investments-sell-list-title"
    :class="{ 'new-investments-sell-list-title-no-data': userHasNoSells }"
  >
    <template v-if="props.isLoading">
      <Loader />
    </template>
    <template v-else>
      <template v-if="userHasNoSells">
        <div class="potok-text-h2">у вас нет заявок на продажу</div>
        <div class="potok-text-body-1 mt-3">
          в данном разделе будут показаны ваши заявки на продажу инвестиций,
          обратите внимание, что одновременно может быть активна или в обработке
          только 1 заявка
        </div>
      </template>

      <template v-if="!userHasNoSells">
        <div
          v-if="props.isSecondMarket"
          class="new-investments-sell-list-title-top potok-text-h2 mb-3"
        >
          {{
            `текущий объем пула для выкупа — ${size}, с учетом лимитов — ${limits}`
          }}
        </div>

        <div class="new-investments-sell-list-title-text potok-text-body-1">
          в данном разделе показаны ваши заявки на продажу инвестиций, обратите
          внимание, что одновременно может быть активна или в обработке только 1
          заявка
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { formatCurrency } from '@/utils/commonUtils/libUtils';
import { Loader } from 'potok-uikit';

const props = defineProps([
  'hasData',
  'isSecondMarket',
  'size',
  'limits',
  'isLoading',
]);

const size = computed(() => {
  return `${formatCurrency(props.size, 0)} ₽`;
});

const limits = computed(() => {
  return `${formatCurrency(props.limits, 0)} ₽`;
});

const userHasNoSells = computed(() => {
  return !props.hasData && !props.isLoading;
});
</script>

<style lang="scss" scoped>
@import '@/scss/typography.scss';

.new-investments-sell-list-title {
  border-radius: 14px;
  padding: 30px;
  background-color: $white;
  @media (max-width: $size_767) {
    padding: 20px 14px 20px 14px;
  }
}
.new-investments-sell-list-title-no-data {
  background-color: rgb(229, 233, 238);
}
.new-investments-sell-list-title-top {
  @media (max-width: $size_991) {
    @include potok-text-body-1-bold;
  }
}
.new-investments-sell-list-title-text {
  @media (max-width: $size_991) {
    @include potok-text-body-2;
    color: $grey;
  }
}
</style>
