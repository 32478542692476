<template>
  <div class="new-request-step-2-info block-container">
    <div class="new-request-step-2-info-title potok-text-h2">
      условия продажи
    </div>

    <div class="new-request-step-2-info-list mb-2">
      <div
        v-for="item in list"
        :key="item.text"
        class="new-request-step-2-info-list-item mt-6 potok-text-body-2"
      >
        <div class="new-request-step-2-info-list-item-text">
          <span>{{ item.text }}</span>
        </div>
        <div class="new-request-step-2-info-list-item-value">
          <span v-if="!props.loading">{{ item.value }}</span>
          <div v-if="props.loading" class="icon-loading-small" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { formatCurrency } from '@/utils/commonUtils/libUtils';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  totalDebtAmount: {
    type: Number,
    default: 0,
  },
  discount: {
    type: Number,
    default: 0,
  },
  limit: {
    type: Number,
    default: 0,
  },
});

const currentDiscount = computed(() => {
  return props.discount + ' %';
});

const currentTotalDebtAmount = computed(() => {
  return `${formatCurrency(props.totalDebtAmount, 0)} ₽`;
});

const currentLimit = computed(() => {
  return props.limit ? `${formatCurrency(props.limit, 0)} ₽` : 'не установлен';
});

const list = ref([
  {
    text: 'текущий дисконт',
    value: currentDiscount,
  },
  {
    text: 'сумма продажи с дисконтом',
    value: currentTotalDebtAmount,
  },
  {
    text: 'лимит',
    value: currentLimit,
  },
]);
</script>

<style scoped lang="scss">
@import '@/scss/typography.scss';

.new-request-step-2-info {
  padding: 30px;
  @media (max-width: $size_991) {
    padding: 14px 20px;
  }
}
.new-request-step-2-info-title {
  @media (max-width: $size_991) {
    @include potok-text-body-1-bold;
    padding-bottom: 10px;
  }
}
.new-request-step-2-info-list-item {
  display: flex;
  justify-content: space-between;
  @media (max-width: $size_991) {
    margin-top: 12px;
  }
}
.new-request-step-2-info-list-item-text {
  color: $grey;
}
</style>
