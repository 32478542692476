<template>
  <div>
    <div class="block_content">
      <ul>
        <li
          v-for="(step, i) in visiblePassedSteps"
          :key="step.id"
          class="prevstep"
          :class="{
            cursor_active: isCurrentStepHasPrevStep(step.id),
          }"
          @click="onClickPrevStep(step.id)"
        >
          <div class="number">
            {{ step.titleId ? step.titleId : i + 1 }}
          </div>

          <span class="description">{{ step.title }}</span>
        </li>

        <li
          v-if="
            currentStep?.visiblePassedStepsIds ? true : currentStep?.visible
          "
          class="active"
        >
          <div
            class="number"
            :class="{
              number_last: !currentStep.nextStepId,
            }"
          >
            <span>{{
              currentStep?.titleId
                ? currentStep.titleId
                : visiblePassedSteps.length + 1
            }}</span>
          </div>
          <span class="description">{{ currentStep?.title }}</span>
        </li>

        <li
          v-for="(step, index) in visibleNextSteps"
          :key="step.value"
          :class="{ active: currentStep === step.value }"
        >
          <div
            class="number"
            :class="{
              success: currentStep !== step.value,
              number_last: !step.nextStepId,
            }"
          >
            <span>{{
              step.titleId ? step.titleId : calculateTitleIdForNextSteps(index)
            }}</span>
          </div>

          <span class="description">{{ step.title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ParentComponent from './WizardParent.vue';

export default {
  extends: ParentComponent,
};
</script>
<style lang="scss" scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 30px 0;
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.active,
.prevstep {
  color: $deep_dive;
  .number {
    color: $deep_dive;
    border: 1px solid $deep_dive;
  }
  .description {
    color: $deep_dive;
  }
}
.number {
  display: block;
  margin-right: 20px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #93a1b0;
  color: #93a1b0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 12px;
  line-height: 22px;
  &::after {
    content: '';
    position: absolute;
    height: 20px;
    width: 1px;
    top: 26px;
    left: 10px;
    border-right: 1px solid #93a1b0;
  }
}
.number_last {
  &::after {
    border: none;
    border-right: none !important;
  }
}

.cursor_active {
  cursor: pointer;
}
.description {
  overflow: hidden;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: $size_991) {
    max-width: 130px;
  }
}
</style>
