<template>
  <div class="assets-payments">
    <div class="assets-head mb-5">
      <h2 class="potok-text-body-1-bold my-0">календарь поступлений</h2>

      <Switcher
        v-model="selectedPeriod"
        class="assets-switcher"
        :items="periods"
        small="true"
        @update:model-value="updatePeriod"
      />
    </div>

    <AnalyticsLoadingContainer
      :has-data="!!items.length"
      :loading="props.loading"
      loading-text="пожалуйста, подождите, загружаем данные"
      help-mobile-title="календарь поступлений"
    >
      <div
        v-if="selectedPeriod === 'day' && !props.loading && dayText"
        class="day-block potok-text-uppercase"
      >
        {{ `${dayText} год` }}
      </div>
      <div style="width: 100%; height: 240px">
        <Bar
          v-if="isShowGraph"
          :data="graphData"
          :options="options"
          style="height: 400px; width: 100%"
        />
      </div>
      <template #beforepopper>
        <div class="total-items">
          <div class="total-item potok-text-uppercase mr-3">
            всего процентный доход —
            <span class="color-deepdive"
              >{{ formatCurrency(props.data?.total?.percentage, 0) }}₽</span
            >
          </div>
          <div class="total-item potok-text-uppercase">
            всего основной долг —
            <span class="color-deepdive"
              >{{ formatCurrency(props.data?.total?.mainDebt, 0) }} ₽</span
            >
          </div>
        </div>
      </template>
      <template #popper>
        <div class="popup help-dropdown-text potok-text-body-2">
          <div class="mb-5">
            <div v-html="props.texts?.hint?.fullDescription" />
          </div>
        </div>
      </template>
    </AnalyticsLoadingContainer>
  </div>
</template>

<script setup>
import { computed, nextTick, ref, watch } from "vue";
import { set, format, add, setWeek } from "date-fns";
import { Bar } from "vue-chartjs";
import { Chart, registerables } from "chart.js";
import Switcher from "@/components/_generic/cselect/Switcher.vue";
import { formatCurrency } from "@/utils/commonUtils/utils";
import externalTooltipGroupColumnHandler from "@/utils/chartjs/getOrCreateTooltipPayments.js";
import AnalyticsLoadingContainer from "./AnalyticsLoadingContainer.vue";
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();

Chart.register(...registerables);

const emit = defineEmits(['updatePeriod']);

const props = defineProps({
  loading: Boolean,
  data: {
    type: Object,
    default: () => {},
  },
  texts: {
    type: Object,
    default: () => {},
  },
});

const periods = ref([
  {
    title: 'По месяцам',
    value: 'month',
  },
  {
    title: 'По неделям',
    value: 'week',
  },
]);

const selectedPeriod = ref('month');
const isShowGraph = ref(true);

const options = ref({
  metaConfig: {
    tooltipFooter: true,
    tooltipFooterText: 'общая сумма',
    tooltipHeaderFormat: 'month',
    defaultLabels: null,
  },
  parsing: {
    yAxisKey: 'indexValue',
    xAxisKey: 'indexValue',
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        usePointStyle: true,
        pointStyle: 'circle',
        color: 'rgba(129, 142, 153, 1)',
        font: {
          size: 14,
        },
      },
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      padding: 0,
    },
    chartAreaBorder: {
      borderColor: 'rgba(232, 236, 240, 1)',
      borderWidth: 1,
    },
  },
  elements: {
    point: {
      pointStyle: 'circle',
      radius: 0,
      borderWidth: 2,
    },
    line: {
      borderWidth: 2,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      stacked: true,
      grid: {
        display: true,
        drawTicks: false,
        color: 'rgba(232, 236, 240, 1)',
        width: 1,
      },
      border: {
        color: 'rgba(243, 245, 247, 1)',
        width: 1,
      },
      ticks: {
        display: false,
        callback(value) {
          return `${formatCurrency(value, 0)} ₽`;
        },
      },
    },
    x: {
      stacked: true,
      grid: {
        display: true,
        drawTicks: false,
        color: 'rgba(243, 245, 247, 1)',
        tickBorderDash: [5, 3],
        width: 1,
      },
      border: {
        dash: [5, 3],
        color: 'rgba(243, 245, 247, 1)',
        width: 1,
      },
      position: 'top',
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        color: 'rgba(129, 142, 153, 0.8)',
        font: {
          size: 8,
          family: "'SuisseIntl'",
          weight: '400',
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
});

const graphData = ref({
  labels: [],
  datasets: [
    {
      backgroundColor: '#04CCD9',
      borderRadius: 4,
      data: [],
      label: 'процентный доход',
      minBarLength: 3,
    },
    {
      backgroundColor: '#173B57',
      borderRadius: 4,
      data: [],
      label: 'основной долг',
      minBarLength: 3,
    },
  ],
});

const items = computed(() => {
  if (!props.data || !props.data.paymentSchedule) {
    return [];
  }
  const result = props.data.paymentSchedule;

  const filteredResult = result.filter((el) => {
    return el.mainDebt >= 1 || el.percentage >= 1;
  });

  return filteredResult;
});

const selectedCompany = computed(() => store.selectedCompany);

const graphLabels = computed(() => {
  return items.value.map((i) => {
    let date;
    if (selectedPeriod.value === 'week') {
      const newDay = set(new Date(), {
        year: i.year,
        month: 0,
        date: 1,
        hours: 1,
        minutes: 1,
        seconds: 1,
      });
      const dateByWeek = add(setWeek(newDay, i.week, { weekStartsOn: 1 }), {
        days: 1,
      });

      date = format(dateByWeek, 'dd.MM.yyyy');
      date += ' - ' + format(add(dateByWeek, { days: 6 }), 'dd.MM.yyyy');
    }
    if (selectedPeriod.value === 'month') {
      const month = `${i.month.toString().length === 1 ? '0' + i.month : i.month}`;
      const year = i.year.toString().split('').slice(2).join('');
      date = `${month}.${year}`;
    }

    return date;
  });
});

const refreshData = () => {
  isShowGraph.value = false;
  graphData.value.labels = graphLabels.value;

  const newDataPercentage = [];
  const newDataMainDebt = [];
  items.value.forEach((el) => {
    newDataPercentage.push({
      ...el,
      indexValue: el.percentage,
    });
    newDataMainDebt.push({
      ...el,
      indexValue: el.mainDebt,
    });
  });

  graphData.value.datasets[0].data = newDataPercentage;
  graphData.value.datasets[0].label = props.texts?.keys?.percentage?.name;

  graphData.value.datasets[1].data = newDataMainDebt;
  graphData.value.datasets[1].label = props.texts?.keys?.mainDebt?.name;

  options.value.metaConfig.tooltipHeaderFormat = selectedPeriod.value;
  options.value.metaConfig.defaultLabels = graphLabels.value;

  options.value.plugins.tooltip.external = (context) => {
    externalTooltipGroupColumnHandler(context, props.texts?.keys);
  };

  nextTick(() => {
    isShowGraph.value = true;
  });
};

const updatePeriod = () => {
  emit('updatePeriod', selectedPeriod.value);
};

watch(
  () => {
    return items.value;
  },
  () => {
    refreshData();
  },
);
watch(
  () => {
    return props.texts;
  },
  () => {
    refreshData();
  },
);
watch(
  () => {
    return selectedCompany.value;
  },
  () => {
    updatePeriod()
  },
);

refreshData();
</script>

<style scoped lang="scss">
.help-dropdown-text {
  max-width: 400px;
}
.assets-payments {
  width: 100%;
}
.assets-switcher {
  @media (max-width: $size_991) {
    margin-top: 16px;
  }
}
.total-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.total-item {
  background-color: $background;
  padding: 4px 8px;
  color: $grey;
  @media (max-width: $size_767) {
    margin-top: 10px;
  }
}
.assets-head {
  display: flex;
  justify-content: space-between;

  @media (max-width: $size_991) {
    flex-direction: column;
  }
}
</style>
